import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'
import AssignedComissionList from '../components/AssignedComissionList'
import { fetchCustomer } from '../actions/customers'

class CustomerDetailsView extends React.Component {
  componentDidMount() {
    this.props.fetchCustomer(this.props.match.params.number)
  }

  getSAPModel(model) {

    switch (model) {
      case "VC": return "VENTAS CONTADO (Punto de Venta)";
      case "CV": return "COMISION VENTA (Solicitud Factura)";
    }

  }

  render() {
    const { customer, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!customer || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-dark">DETALLES DEL </span>
              <span className="text-vw-light">CLIENTE</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 text-right">
            <Link to={`/customers/${customer.number}/edit`}>
              <button className="btn bg-vw-light text-white mr-1">
                <i className="fas fa-edit mr-1"></i>Editar
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Cliente" icon="user">
              <PropertyLabel name="Nombre completo"
                value={customer.Fullname} />
              <PropertyLabel name="Categoria"
                value={customer.Category} />
              <PropertyLabel name="Persona de contacto"
                value={customer.Contact_person} />
              <PropertyLabel name="E-Mail"
                value={customer.Email} />
              <PropertyLabel name="Dirección"
                value={customer.Address} />
              <PropertyLabel name="Teléfono 1"
                value={customer.Phone} />
            </Box>
            <Box title="Modelo de negocio" icon="briefcase">
              <PropertyLabel name="Modelo" ml
                value={customer.BusinessModel} />
              {customer.SpaceRentalAlquiler &&
                <PropertyLabel name="Alquiler Anual" ml
                  value={`${customer.SpaceRentalAlquiler} €`} />
              }
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Extra" icon="user">
              <PropertyLabel name="Teléfono 2"
                value={customer.Phone2} />
              <PropertyLabel name="Fax"
                value={customer.Fax} />
              <PropertyLabel name="Web"
                value={customer.Web} />
              <PropertyLabel name="Idioma"
                value={customer.Language} />
              <PropertyLabel name="País"
                value={customer.Country} />
              <PropertyLabel name="Código postal"
                value={customer.PostalCode} />
              <PropertyLabel name="Ciudad"
                value={customer.City} />
              <PropertyLabel name="Provincia"
                value={customer.Province} />
              <PropertyLabel name="Notas" ml
                value={customer.Notes} />
              <PropertyLabel name="Última edición" ml
                value={customer.Executor} />
            </Box>
            <Box title="SAP" icon="file-alt">
              <PropertyLabel name="Model"
                value={this.getSAPModel(customer.SAP.Model)} />
              <PropertyLabel name="CompanyID"
                value={customer.SAP.CompanyID} />
              <PropertyLabel name="CountryCode"
                value={customer.SAP.CountryCode} />
              <PropertyLabel name="ClientID"
                value={customer.SAP.ClientID} />
              <PropertyLabel name="SiteID"
                value={customer.SAP.SiteID} />
              <PropertyLabel name="TaxCountryCode"
                value={customer.SAP.TaxCountryCode} />
              <PropertyLabel name="TaxationCharacteristicsCode"
                value={customer.SAP.TaxationCharacteristicsCode} />
              <PropertyLabel name="InventoryManagedLocationID"
                value={customer.SAP.InventoryManagedLocationID} />
              <PropertyLabel name="LogisticsAreaID"
                value={customer.SAP.LogisticsAreaID} />
              <PropertyLabel name="TaxRegionCode"
                value={customer.SAP.TaxRegionCode} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Facturación" icon="file-alt">
              <PropertyLabel name="NIF/CIF" ml
                value={customer.Invoice_taxid} />
              <PropertyLabel name="IVA aplicable (%)"
                value={customer.Invoice_tax} />
              <PropertyLabel name="Razón social"
                value={customer.Social_name} />
              <PropertyLabel name="Dirección de facturación" ml
                value={customer.Invoice_address} />
            </Box>
            <Box title="Artículos" icon="shopping-cart">
              <AssignedComissionList items={customer.Items} />
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customer: state.customers.customer,
  loading: state.customers.loading,
  error: state.customers.error
})

const mapDispatchToProps = dispatch => ({
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsView)
