import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { addMachine } from '../actions/machines'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'

class MachineAddForm extends React.Component {
       constructor(props) {
              super(props)

              this.submit = this.submit.bind(this)
       }

       submit(props) {

              props.Executor = this.props.fullname

              return this.props.addMachine(props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       renderLanguages(props) {
              const { fields } = props

              let languageCounter = 0;

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <tbody>
                                                 {fields.map((registerlanguage, index) => (
                                                        <tr key={index}>
                                                               <td>
                                                                      {(languageCounter++) + 1}
                                                               </td>
                                                               <td>
                                                                      <Field name={`${registerlanguage}.Language`}
                                                                             component={renderSelectField}>
                                                                             <option value="">Selecciona un idioma...</option>
                                                                             <option value="es_ES">ESPAÑOL</option>
                                                                             <option value="en_EN">ENGLISH</option>
                                                                             <option value="fr_FR">FRANÇAIS</option>
                                                                             <option value="it_IT">ITALIANO</option>
                                                                             <option value="gr_GR">DEUTSCH</option>
                                                                             <option value="cz_CZ">ČEŠTINA</option>
                                                                             <option value="cat_CAT">CATALÀ</option>
                                                                             <option value="pt_PT">PORTUGUES</option>
                                                                             <option value="sk_SK">SLOVENSKÝ</option>
                                                                             <option value="pl_PL">POLSKI</option>
                                                                             <option value="tr_TR">TÜRKÇE</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            {languageCounter < 4 &&
                                   <div className="text-right">
                                          <button className="btn bg-vw-light text-white" type="button"
                                                 onClick={() => fields.push({})}>
                                                 <i className="fas fa-plus mr-1"></i>
                                                 Añadir
                                          </button>
                                   </div>
                            }
                     </div>
              )
       }

       renderPaymentDevices(props) {
              const { fields } = props

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <thead className="thead-dark">
                                                 <tr>
                                                        <th scope="col">Tipo</th>
                                                        <th scope="col">Dispositivo</th>
                                                        <th scope="col"></th>
                                                 </tr>
                                          </thead>
                                          <tbody>
                                                 {fields.map((device, index) => (

                                                        <tr key={index}>
                                                               <td>
                                                                      <Field name={`${device}.Type`}
                                                                             component={renderSelectField}
                                                                             label="Tipo">
                                                                             <option value="">Selecciona un tipo...</option>
                                                                             <option value="TPV">TPV</option>
                                                                             <option value="CASH">CASH</option>
                                                                             <option value="RFID">RFID</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <Field name={`${device}.Device`}
                                                                             component={renderSelectField}
                                                                             label="Dispositivo">
                                                                             <option value="">Selecciona un dispositivo...</option>
                                                                             <option value="PAYTEF">PAYTEF</option>
                                                                             <option value="SPIRE">SPIRE</option>
                                                                             <option value="U1000">U1000</option>
                                                                             <option value="NV9USB">NV9USB</option>
                                                                             <option value="COINCO">COINCO</option>
                                                                             <option value="HOPPER">HOPPER</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            <div className="text-right">
                                   <button className="btn bg-vw-light text-white" type="button"
                                          onClick={() => fields.push({})}>
                                          <i className="fas fa-plus mr-1"></i>
                                          Añadir
                                   </button>
                            </div>
                     </div>
              )
       }

       renderModules(props) {
              const { fields } = props

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <thead className="thead-dark">
                                                 <tr>
                                                        <th scope="col">Prefijo</th>
                                                        <th scope="col">Número de serie</th>
                                                        <th scope="col">Tipo</th>
                                                        <th scope="col"></th>
                                                 </tr>
                                          </thead>
                                          <tbody>
                                                 {fields.map((module, index) => (

                                                        <tr key={index}>
                                                               <td>
                                                                      <Field
                                                                             name={`${module}.serialPrefix`}
                                                                             component={renderSelectField}>
                                                                             <option value="">Selecciona un prefijo...</option>
                                                                             <option value="VLM06">VLM06</option>
                                                                             <option value="VLM08">VLM08</option>
                                                                             <option value="VLM10">VLM10</option>
                                                                             <option value="VLM12">VLM12</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <Field
                                                                             name={`${module}.serialNumber`}
                                                                             type="text"
                                                                             component={renderInputField}
                                                                      />
                                                               </td>
                                                               <td>
                                                                      <Field
                                                                             name={`${module}.tipo`}
                                                                             component={renderSelectField}>
                                                                             <option value="">Selecciona un tipo...</option>
                                                                             <option value="B">BÁSICO</option>
                                                                             <option value="C">CARGADOR</option>
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            <div className="text-right">
                                   <button className="btn bg-vw-light text-white" type="button"
                                          onClick={() => fields.push({})}>
                                          <i className="fas fa-plus mr-1"></i>
                                          Añadir
                                   </button>
                            </div>
                     </div>
              )
       }

       render() {
              const { error, pristine, submitting, handleSubmit } = this.props

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">MÁQUINA </span>
                                   <span className="text-vw-light">NUEVA</span>
                            </h1>

                            <div className="row">
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Máquina" icon="cubes">
                                                 <Field name="Type"
                                                        component={renderSelectField}
                                                        label="Modelo">
                                                        <option value="">Selecciona un modelo...</option>
                                                        <option value="VACWAYone">VACWAYone</option>
                                                 </Field>
                                                 <Field name="Build_date"
                                                        component={renderInputField}
                                                        type="date"
                                                        label="Fecha de fabricación" />
                                                 <Field name="Reset_no_internet"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Reset sin conectividad" />
                                                 <Field name="Enable_standby"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Habilitar modo stand-by" />
                                                 <Field name="EthernetMode"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Máquina con Ethernet" />
                                                 <Field name="videoAllowed"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Video en start" />
                                                 <Field name="BubblesSoundActive"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Claim burbujas" />
                                                 <Field name="WaterproofSoundActive"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Claim waterproof" />
                                                 <Field name="GeneralSoundActive"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Pasos sonoros" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Interficie" icon="desktop">
                                                 <Field name="Sound_interval"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Intérvalo sonido burbujas" />
                                                 <Field name="LastSale"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Código de venta" />
                                                 <Field name="SleepStart"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Inicio del modo sleep (h)" />
                                                 <Field name="SleepEnd"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Fin del modo sleep (h)" />
                                                 <Field name="PaymentMethods"
                                                        component={renderSelectField}
                                                        label="Métodos de pago">
                                                        <option value="">Selecciona una combinación...</option>
                                                        <option value="0">CASH + TPV</option>
                                                        <option value="1">SOLO CASH</option>
                                                        <option value="2">SOLO TPV</option>
                                                        <option value="3">SOLO RFID</option>
                                                 </Field>
                                                 <Field name="AutoScreenExitTime"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Timeout pantallas" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Otros" icon="comments">
                                                 <Field name="standbyPhrase"
                                                        type="text"
                                                        component={renderTextAreaField}
                                                        label="Frase Standby" />
                                                 <Field name="Contacto"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Teléfono de incidencias" />
                                                 <Field name="Report_email"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="E-mail de reportes" />
                                                 <Field name="Comments"
                                                        type="text"
                                                        component={renderTextAreaField}
                                                        label="Comentarios" />
                                                 <Field name="ChangeInitial"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Cambio inicial (EUR)" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Idiomas" icon="comments">
                                                 <Field name="Language"
                                                        component={renderSelectField}
                                                        label="Idioma principal">
                                                        <option value="">Selecciona un idioma...</option>
                                                        <option value="es_ES">ESPAÑOL</option>
                                                        <option value="en_EN">ENGLISH</option>
                                                        <option value="fr_FR">FRANÇAIS</option>
                                                        <option value="it_IT">ITALIANO</option>
                                                        <option value="gr_GR">DEUTSCH</option>
                                                        <option value="cz_CZ">ČEŠTINA</option>
                                                        <option value="cat_CAT">CATALÀ</option>
                                                        <option value="pt_PT">PORTUGUES</option>
                                                        <option value="sk_SK">SLOVENSKÝ</option>
                                                        <option value="pl_PL">POLSKI</option>
                                                        <option value="tr_TR">TÜRKÇE</option>
                                                 </Field>
                                                 <FieldArray name="SupportedLanguages" component={this.renderLanguages} />
                                          </Box>
                                          <Box title="Passwords" icon="unlock">
                                                 <Field name="PasswordMachine"
                                                        component={renderInputField}
                                                        type="text"
                                                        label="Pin Máquina" />
                                                 <Field name="PasswordCash"
                                                        component={renderInputField}
                                                        type="text"
                                                        label="Pin Cash" />
                                                 <Field name="PasswordMaster"
                                                        component={renderInputField}
                                                        type="text"
                                                        label="Pin Master" />
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">FIRMWARE</span>
                            </h3>

                            <div className="row">
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Robot" icon="cubes">
                                                 <Field name="Robotctl_up_init_speed"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Robotctl_up_init_speed" />
                                                 <Field name="Robotctl_empty_steps"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Robotctl_empty_steps" />
                                                 <Field name="Robotctl_steps_100_covers"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Robotctl_steps_100_covers" />
                                                 <Field name="Robotctl_pickup_movement"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Robotctl_pickup_movement" />
                                                 <Field name="Robotctl_detect_min_pressure"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Robotctl_detect_min_pressure" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Tapa" icon="cubes">
                                                 <Field name="Lidctl_st_maxopen_move"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Lidctl_st_maxopen_move" />
                                                 <Field name="Lidctl_max_move"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Lidctl_max_move" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Sellado" icon="cubes">
                                                 <Field name="Sealctl_st_close_move"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Sealctl_st_close_move" />
                                                 <Field name="Sealctl_seal_intensity"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Sealctl_seal_intensity" />
                                                 <Field name="Sealctl_min_press_kpa"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Sealctl_min_press_kpa" />
                                                 <Field name="Sealctl_pump_init"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Sealctl_pump_init" />
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">AQUAPALACE</span>
                            </h3>

                            <div className="row">
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Máquina" icon="cubes">
                                                 <Field name="Aquapalace.Endpoint"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Endpoint" />
                                                 <Field name="Aquapalace.Username"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Username" />
                                                 <Field name="Aquapalace.Password"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Password" />
                                                 <Field name="Aquapalace.ExternalCashDeskId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="ExternalCashDeskId" />
                                                 <Field name="Aquapalace.ExternalSystemId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="ExternalSystemId" />
                                                 <Field name="Aquapalace.ComputerId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="ComputerId" />
                                                 <Field name="Aquapalace.DepartmentId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="DepartmentId" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Venta" icon="cubes">
                                                 <Field name="Aquapalace.BagType"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="BagType" />
                                                 <Field name="Aquapalace.ItemType"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="ItemType" />
                                                 <Field name="Aquapalace.PackArticleId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="PackArticleId" />
                                                 <Field name="Aquapalace.LayArticleId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="LayArticleId" />
                                                 <Field name="Aquapalace.GoArticleId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="GoArticleId" />
                                                 <Field name="Aquapalace.PaymentType"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="PaymentType" />
                                                 <Field name="Aquapalace.PayTipeId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="PayTipeId" />
                                                 <Field name="Aquapalace.PriceUnit"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="PriceUnit" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Otros" icon="cubes">
                                                 <Field name="Aquapalace.UserId"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="UserId" />
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">LAND OF LEGENDS</span>
                            </h3>

                            <div className="row">
                                   <div className="col-xs-12 col-md-6">
                                          <Box title="Máquina" icon="cubes">
                                                 <Field name="LandOfLegends.Endpoint"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Endpoint" />
                                                 <Field name="LandOfLegends.Bearer"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Bearer" />
                                                 <Field name="LandOfLegends.DEPID"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="DEPID" />
                                                 <Field name="LandOfLegends.PORTALID"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="PORTALID" />
                                                 <Field name="LandOfLegends.WAITERID"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="WAITERID" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-6">
                                          <Box title="Venta" icon="cubes">
                                                 <Field name="LandOfLegends.PRODUCTID_VWPACK"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="PRODUCTID_VWPACK" />
                                                 <Field name="LandOfLegends.PRODUCTID_VWLAY"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="PRODUCTID_VWLAY" />
                                                 <Field name="LandOfLegends.PRODUCTID_VWGO"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="PRODUCTID_VWGO" />
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">LOCKERS</span>
                            </h3>

                            <div className="row">
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Configuración" icon="lock">
                                                 <Field name="AlarmDefault"
                                                        component={renderSelectField}
                                                        label="Alarma de locker abierto">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                                 <Field name="BuzzerDefault"
                                                        component={renderSelectField}
                                                        label="Sonido de teclado activo">
                                                        <option value='true'>SI</option>
                                                        <option value='false'>NO</option>
                                                 </Field>
                                                 <Field name="OpenModeDefault"
                                                        component={renderSelectField}
                                                        label="Cierre de locker manual">
                                                        <option value='false'>NO</option>
                                                        <option value='true'>SI</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Scans" icon="broadcast-tower">
                                                 <Field name="ScanNightAllowed"
                                                        component={renderSelectField}
                                                        label="Night Scan activo">
                                                        <option value='true'>SI</option>
                                                        <option value='false'>NO</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Distribución" icon="cubes">
                                                 <Field name="TaquillaIzq"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Taquillas Izquierda" />
                                                 <Field name="TaquillaDer"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Taquillas Derecha" />
                                                 <Field name="FirstIndex"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="First Index" />
                                                 <Field name="LastIndex"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Last Index" />
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">PAYMENTS</span>
                            </h3>
                            <div className="row">
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Dispositivos">
                                                 <FieldArray name="PaymentDevices" component={this.renderPaymentDevices} />
                                          </Box>
                                   </div>
                            </div>
                            <div className="row">
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="PAYTEF" icon="credit-card">
                                                 <Field name="Tpv_serial"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Número de serie" />
                                                 <Field name="Tpv_tcod"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="TCOD" />
                                                 <Field name="Tpv_max"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Cargo máximo (EUR)" />
                                                 <Field name="TpvCancelTime"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Timeout de cancelación" />
                                          </Box>
                                          <Box title="REDSYS" icon="credit-card">
                                                 <Field name="TpvBusinessCode"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Código de comercio" />
                                                 <Field name="TpvTerminalCode"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Número de Terminal" />
                                                 <Field name="TpvFirmKeyCode"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Clave de Firma" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Token" icon="coins">
                                                 <Field name="TokenActivated"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Token activado" />
                                                 <Field name="Token_value"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Valor del token (EUR)" />
                                                 <Field name="Token_sale_value"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Valor de venta del token (EUR)" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Devoluciones" icon="coins">
                                                 <Field name="LimiteDevolucionDiario"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Máximo de devolución diario (EUR)" />
                                                 <Field name="LimiteDevolucion"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Máximo de devolución de una sola vez (EUR)" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Otros" icon="coins">
                                                 <Field name="CashMax"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Cobro máximo en Cash (EUR)" />
                                                 <Field name="SecurityBox"
                                                        component={renderSelectField}
                                                        label="Caja de seguridad">
                                                        <option value='true'>SI</option>
                                                        <option value='false'>NO</option>
                                                 </Field>
                                                 <Field name="BillerAcceptance"
                                                        component={renderSelectField}
                                                        label="Billetes aceptados">
                                                        <option value='1111'>TODOS 5€ / 10€ / 20€ / 50€</option>
                                                        <option value='0111'>5€ / 10€ / 20€</option>
                                                        <option value='0011'>5€ / 10€</option>
                                                        <option value='0001'>5€</option>
                                                        <option value='0000'>NINGÚNO</option>
                                                 </Field>
                                          </Box>
                                   </div>
                            </div>

                            <h3>
                                   <span className="text-vw-dark">MÓDULOS</span>
                            </h3>

                            <Box title="">
                                   <FieldArray name="Modules" component={this.renderModules} />
                            </Box>

                            <h3>
                                   <span className="text-vw-dark">PROMOTORES</span>
                            </h3>
                            <div className="row">
                                   <div className="col-xs-12 col-md-12">
                                          <Box title="Configuración de promotores" icon="users">
                                                 <Field name="Has_promoter"
                                                        type="checkbox"
                                                        component={renderInputField}
                                                        label="Gestionada por promotor" />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={pristine || submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

MachineAddForm = reduxForm({
       form: 'MachineAddForm',
       keepDirtyOnReinitialize: true,
       enableReinitialize: true,
       updateUnregisteredFields: true,
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/machines`)
       }
})(MachineAddForm)

const mapStateToProps = state => ({
       auth: state.auth,
       role: state.auth.role,
       type: state.auth.type,
       fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
       addMachine: bindActionCreators(addMachine, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineAddForm)
