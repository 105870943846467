import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { bindActionCreators } from 'redux'

import { fetchShifts, downloadShiftsPromoter } from '../actions/shifts'
import { fetchPromoters, updateFilteredPromoters, updateShiftdaysPromoters } from '../actions/promoters'

import { renderInputField, renderSelectField } from '../utils/forms'

class ShiftsFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
    this.downloadShiftSheet = this.downloadShiftSheet.bind(this)
  }

  componentDidMount() {
    const { fetchPromoters } = this.props

    fetchPromoters()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { promoters } = props

    if (!this.state.loaded && promoters) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    this.props.fetchShifts(props.name, '', props.startDate, props.endDate)
    const filteredPromoters = this.props.promoters.filter((promoter) => promoter.fullname === props.name);
    this.props.updateFilteredPromoters(filteredPromoters);

    // Create an array of days between startDate and endDate
    const startDate = new Date(props.startDate);
    const endDate = new Date(props.endDate);
    const daysInRange = [];

    // Loop through each day in the range
    for (let currentDay = startDate; currentDay <= endDate; currentDay.setDate(currentDay.getDate() + 1)) {
      daysInRange.push(currentDay.toISOString().substring(0, 10));
    }

    // Update shiftdaysUsers in the Redux state
    this.props.updateShiftdaysPromoters(daysInRange);
  }

  downloadShiftSheet() {
    const { formValues } = this.props;
    this.props.downloadShiftsPromoter(formValues.name, formValues.startDate, formValues.endDate)
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, promoters } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        <div className="row">
          <div className="col">
            <Field name="name"
              component={renderSelectField}
              label="Promotor/a">
              <option value="">Todes</option>
              {promoters.map(promoter => {
                return (
                  <option value={promoter.fullname} key={promoter._id}>
                    {promoter.fullname}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className="col">
            <Field name="startDate"
              component={renderInputField}
              type="date"
              label="Fecha inicio" />
          </div>
          <div className="col">
            <Field name="endDate"
              component={renderInputField}
              type="date"
              label="Fecha fin" />
          </div>
        </div>
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row justify-content-center">
          <button className="btn bg-vw-dark text-white mr-2"
            type="submit"
            disabled={submitting}>
            <i className="fas fa-sync mr-1"></i>Actualizar
          </button>
          <button className="btn bg-vw-excel text-white ml-2" onClick={() => this.downloadShiftSheet()}>
            <i className="fas fa-file-excel mr-1"></i>Descargar
          </button>
        </div>
      </form>
    )
  }
}

ShiftsFilterForm = reduxForm({
  form: 'ShiftsFilterForm',
  getFormValues: state => getFormValues('ShiftsFilterForm')(state),
})(ShiftsFilterForm)

const mapStateToProps = state => ({
  promoters: state.promoters.promoters,
  initialValues: {
    name: '',
    startDate: new Date().toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10)
  },
  formValues: getFormValues('ShiftsFilterForm')(state),
  filteredPromoters: state.promoters.filteredPromoters,
  shiftdaysPromoters: state.promoters.shiftdaysPromoters
})

const mapDispatchToProps = dispatch => ({
  fetchShifts: bindActionCreators(fetchShifts, dispatch),
  downloadShiftsPromoter: bindActionCreators(downloadShiftsPromoter, dispatch),
  fetchPromoters: bindActionCreators(fetchPromoters, dispatch),
  updateFilteredPromoters: bindActionCreators(updateFilteredPromoters, dispatch),
  updateShiftdaysPromoters: bindActionCreators(updateShiftdaysPromoters, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsFilterForm)

