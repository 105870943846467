import React from 'react'

class MoneyTable extends React.Component {
  render() {
    const { Bills_in_box, Cash_in_box, Cash_in_coiner } = this.props.machine

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <h6>Caja de billetes</h6>
            </tr>
            <tr>
              <th scope="col">Billete</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr key="5EUR">
              <th scope="row">5 €</th>
              <td>{`${Bills_in_box.five}`}</td>
              <td>{`${Bills_in_box.five * 5.00} €`}</td>
            </tr>
            <tr key="BILLS_10EUR">
              <th scope="row">10 €</th>
              <td>{`${Bills_in_box.ten}`}</td>
              <td>{`${Bills_in_box.ten * 10.00} €`}</td>
            </tr>
            <tr key="BILLS_20EUR">
              <th scope="row">20 €</th>
              <td>{`${Bills_in_box.twenty}`}</td>
              <td>{`${Bills_in_box.twenty * 20.00} €`}</td>
            </tr>
            <tr key="BILLS_TOTAL">
              <th scope="row">TOTAL</th>
              <td>{`${Bills_in_box.five + Bills_in_box.ten + Bills_in_box.twenty}`}</td>
              <td>{`${Bills_in_box.five * 5.00 +
                      Bills_in_box.ten * 10.00 +
                      Bills_in_box.twenty * 20.00} €`}</td>
            </tr>
          </tbody>
        </table>
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <h6>Caja de monedas</h6>
            </tr>
            <tr>
              <th scope="col">Moneda</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr key="CASH_0_5EUR">
              <th scope="row">0.50 €</th>
              <td>{`${Cash_in_box.fifty}`}</td>
              <td>{`${Cash_in_box.fifty * 0.50} €`}</td>
            </tr>
            <tr key="CASH_1EUR">
              <th scope="row">1 €</th>
              <td>{`${Cash_in_box.one}`}</td>
              <td>{`${Cash_in_box.one * 1.00} €`}</td>
            </tr>
            <tr key="CASH_2EUR">
              <th scope="row">2 €</th>
              <td>{`${Cash_in_box.two}`}</td>
              <td>{`${Cash_in_box.two * 2.00} €`}</td>
            </tr>
            <tr key="CASH_TOKEN">
              <th scope="row">Token</th>
              <td>{`${Cash_in_box.token}`}</td>
              <td>-</td>
            </tr>
            <tr key="CASH_TOTAL">
              <th scope="row">TOTAL</th>
              <td>{`${Cash_in_box.fifty +
                      Cash_in_box.one +
                      Cash_in_box.two +
                      Cash_in_box.token}`}</td>
              <td>{`${Cash_in_box.fifty * 0.50 +
                      Cash_in_box.one * 1.00 +
                      Cash_in_box.two * 2.00} €`}</td>
            </tr>
          </tbody>
        </table>
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <h6>Tubos de cambio</h6>
            </tr>
            <tr>
              <th scope="col">Moneda</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr key="CASH_0_5EUR">
              <th scope="row">0.50 €</th>
              <td>{`${Cash_in_coiner.fifty}`}</td>
              <td>{`${Cash_in_coiner.fifty * 0.50} €`}</td>
            </tr>
            <tr key="CASH_1EUR">
              <th scope="row">1 €</th>
              <td>{`${Cash_in_coiner.one}`}</td>
              <td>{`${Cash_in_coiner.one * 1.00} €`}</td>
            </tr>
            <tr key="CASH_2EUR">
              <th scope="row">2 €</th>
              <td>{`${Cash_in_coiner.two}`}</td>
              <td>{`${Cash_in_coiner.two * 2.00} €`}</td>
            </tr>
            <tr key="CASH_TOTAL">
              <th scope="row">TOTAL</th>
              <td>{`${Cash_in_coiner.fifty +
                      Cash_in_coiner.one +
                      Cash_in_coiner.two}`}</td>
              <td>{`${Cash_in_coiner.fifty * 0.50 +
                      Cash_in_coiner.one * 1.00 +
                      Cash_in_coiner.two * 2.00} €`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default MoneyTable


