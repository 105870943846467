import {
  UPDATES_REQUEST, UPDATES_SUCCESS, UPDATES_FAILURE,
  UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE,
  UPDATE_ADD_REQUEST, UPDATE_ADD_SUCCESS, UPDATE_ADD_FAILURE,
  UPDATE_UPDATE_REQUEST, UPDATE_UPDATE_SUCCESS, UPDATE_UPDATE_FAILURE,
  UPDATE_RESET
} from '../actions/updates'

const INITIAL_STATE = {
  updates: null, update: null, error: null, loading: false
}

const updatesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case UPDATES_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case UPDATES_SUCCESS:
        return {
          ...state,
          updates: action.response, error: null, loading: false
        }
      case UPDATES_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case UPDATE_SUCCESS:
        return {
          ...state,
          update: action.response, error: null, loading: false
        }
      case UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case UPDATE_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case UPDATE_ADD_SUCCESS:
        return {
          ...state,
          update: action.response, error: null,
          loading: false
        }
      case UPDATE_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case UPDATE_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case UPDATE_UPDATE_SUCCESS:
        return {
          ...state,
          update: action.response, error: null,
          loading: false
        }
      case UPDATE_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case UPDATE_RESET:
        return {
          ...state,
          update: null, error: null, loading: false
        }
      default:
        return state
    }
  }

export default updatesReducer

