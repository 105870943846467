import {
  ITEMS_REQUEST, ITEMS_SUCCESS, ITEMS_FAILURE,
  ITEM_REQUEST, ITEM_SUCCESS, ITEM_FAILURE,
  ITEM_ADD_REQUEST, ITEM_ADD_SUCCESS, ITEM_ADD_FAILURE,
  ITEM_UPDATE_REQUEST, ITEM_UPDATE_SUCCESS, ITEM_UPDATE_FAILURE
} from '../actions/items'

const INITIAL_STATE = {
  items: null, itemsNeedRefresh: false, item: null, error: null,
  loading: false
}

const itemsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ITEMS_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case ITEMS_SUCCESS:
        return {
          ...state,
          items: action.response, error: null, loading: false
        }
      case ITEMS_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case ITEM_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case ITEM_SUCCESS:
        return {
          ...state,
          item: action.response, error: null, loading: false
        }
      case ITEM_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case ITEM_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case ITEM_ADD_SUCCESS:
        return {
          ...state,
          itemsNeedRefresh: true, item: action.response, error: null,
          loading: false
        }
      case ITEM_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case ITEM_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case ITEM_UPDATE_SUCCESS:
        return {
          ...state,
          itemsNeedRefresh: true, item: action.response, error: null,
          loading: false
        }
      case ITEM_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      default:
        return state
    }
  }

export default itemsReducer

