import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import { fetchShifts } from '../actions/shifts'
import { fetchPromoters } from '../actions/promoters'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import ShiftEntryList from '../components/ShiftEntryList'

import ShiftsFilterForm from '../forms/ShiftsFilterForm'

class ShiftsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }

  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10)
    this.props.fetchShifts('', '', today, today)
    this.props.fetchPromoters('', 'ALTA')
  }

  renderShifts() {
    const { shifts, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!Array.isArray(shifts) || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (<ShiftEntryList shifts={shifts} />)
  }

  renderStartHour(name, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!Array.isArray(shifts) || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let initHour = "None";

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return (shift.workerName).replace(/\s/g, '') === name.replace(/\s/g, '') && shiftDay === day;
    });

    filteredShifts.forEach((shift) => {
      if ((shift.workerName).replace(/\s/g, '') === name.replace(/\s/g, '')) {
        const shiftDate = new Date(shift.date);
        initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
      }
    });

    return initHour;
  }

  renderFinishHour(name, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!Array.isArray(shifts) || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let finishHour = "None";
    let done = false;
    let counter = 0;

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return (shift.workerName).replace(/\s/g, '') === name.replace(/\s/g, '') && (day ? shiftDay === day : true);
    });

    filteredShifts.forEach((shift) => {
      if (!done) {
        const shiftDate = new Date(shift.date);
        finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
        done = true;
      }
    });

    filteredShifts.forEach((shift) => {
      counter++;
    });

    if (counter === 1) {
      finishHour = "None";
    }

    return finishHour;
  }

  renderTotalHour(name, rest, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!Array.isArray(shifts) || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let initHour = "None";
    let finishHour = "None";
    let done = false;
    let counter = 0;

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return (shift.workerName).replace(/\s/g, '') === name.replace(/\s/g, '') && (day ? shiftDay === day : true);
    });

    filteredShifts.forEach((shift) => {
      const shiftDate = new Date(shift.date);
      initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
    });

    filteredShifts.forEach((shift) => {
      if (!done) {
        const shiftDate = new Date(shift.date);
        finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });

        done = true;
      }
    });

    filteredShifts.forEach((shift) => {
      counter++;
    });

    if (counter === 1) {
      finishHour = "None";
    }

    let totalHours = "None";

    if (initHour !== "None" && finishHour !== "None") {
      // Parse the time strings into Date objects
      const initTime = new Date(`2000-01-01 ${initHour}`);
      const finishTime = new Date(`2000-01-01 ${finishHour}`);

      // Calculate the time difference in milliseconds
      let timeDiff = finishTime - initTime;

      // Subtract rest time if rest is provided
      if (rest && typeof rest === 'number') {
        timeDiff -= rest * 60 * 1000; // Convert rest time to milliseconds
      }

      // Convert the time difference to hours and minutes
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const totalMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

      // Ensure minutes are always represented by at least two digits
      const minutes = totalMinutes.toString().padStart(2, '0');

      totalHours = `${hours}h${minutes}m`;
    }

    return totalHours;
  }

  renderTotalWorkingHours(name, rest, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!Array.isArray(shifts) || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let initHour = "None";
    let finishHour = "None";
    let done = false;
    let counter = 0;
    let timeDiff = 0;

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return (shift.workerName).replace(/\s/g, '') === name.replace(/\s/g, '') && (day ? shiftDay === day : true);
    });

    filteredShifts.forEach((shift) => {
      const shiftDate = new Date(shift.date);
      initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
    });

    filteredShifts.forEach((shift) => {
      if (!done) {
        const shiftDate = new Date(shift.date);
        finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });

        done = true;
      }
    });

    filteredShifts.forEach((shift) => {
      counter++;
    });

    if (counter === 1) {
      finishHour = "None";
    }

    if (initHour !== "None" && finishHour !== "None") {
      // Parse the time strings into Date objects
      const initTime = new Date(`2000-01-01 ${initHour}`);
      const finishTime = new Date(`2000-01-01 ${finishHour}`);

      // Calculate the time difference in milliseconds
      timeDiff = finishTime - initTime;

      // Subtract rest time if rest is provided
      if (rest && typeof rest === 'number') {
        timeDiff -= rest * 60 * 1000; // Convert rest time to milliseconds
      }
    }

    return timeDiff;
  }

  renderStatus(name, day) {

    let init = this.renderStartHour(name, day);
    let finish = this.renderFinishHour(name, day);

    if (init !== 'None' && finish !== 'None') {
      return (
        <div className="d-inline-block p-2">
          <div className="badge bg-success">{name}</div>
        </div>
      )
    } else if (init !== 'None') {
      return (
        <div className="d-inline-block p-2">
          <div className="badge bg-warning">{name}</div>
        </div>
      )
    } else {
      return (
        <div className="d-inline-block p-2">
          <div className="badge bg-danger">{name}</div>
        </div>
      )
    }

  }

  getUserRest(rest) {

    switch (rest) {
      case 30: return "30 minutos";
      case 60: return "1 hora";
      default: return "sin descanso";
    }
  }

  renderWorkers() {
    const { shifts, promoters, filteredPromoters, shiftdaysPromoters } = this.props

    if (!shifts || !promoters) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    if (shiftdaysPromoters.length === 0) {
      shiftdaysPromoters.push(new Date().toISOString().split('T')[0]);

    }

    let usersToRender = filteredPromoters.length > 0 ? filteredPromoters : promoters;

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col"></th>
              <th scope="col">Trabajador</th>
              <th scope="col">Inicio Jornada</th>
              <th scope="col">Descanso Asignado</th>
              <th scope="col">Fin Jornada</th>
              <th scope="col">Total jornada</th>
            </tr>
          </thead>
          <tbody>
            {shiftdaysPromoters.map(day => (
              <React.Fragment key={day}>
                <tr>
                  <th colSpan="6">{`${day}`}</th>
                </tr>
                {usersToRender.filter(user => user.status === 'ALTA').map(user => (
                  <tr key={user.fullname}>
                    <td><img className="user-icon mr-2" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${user.fullname}.png`} alt="User" /></td>
                    <td>{this.renderStatus(user.fullname, day)}</td>
                    <td>{this.renderStartHour(user.fullname, day)}</td>
                    <td>{this.getUserRest(user.rest)}</td>
                    <td>{this.renderFinishHour(user.fullname, day)}</td>
                    <td>{this.renderTotalHour(user.fullname, user.rest, day)}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  renderTotalWorkers() {
    const { shifts, promoters, filteredPromoters, shiftdaysPromoters } = this.props

    if (!shifts || !promoters) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    if (shiftdaysPromoters.length === 0) {
      shiftdaysPromoters.push(new Date().toISOString().split('T')[0]);
    }

    let usersToRender = filteredPromoters.length > 0 ? filteredPromoters : promoters;

    let expectedWorkingHours = {};
    let totalWorkingHours = {};
    let statusWorkingHours = {};

    usersToRender.filter(user => user.status === 'ALTA').forEach(user => {
      shiftdaysPromoters.forEach(day => {
        if (!totalWorkingHours[user.fullname]) {
          totalWorkingHours[user.fullname] = 0;
          totalWorkingHours[user.fullname] += this.renderTotalWorkingHours(user.fullname, user.rest, day);
        } else {
          totalWorkingHours[user.fullname] += this.renderTotalWorkingHours(user.fullname, user.rest, day);
        }

        if (!expectedWorkingHours[user.fullname]) {
          expectedWorkingHours[user.fullname] = 0;
          expectedWorkingHours[user.fullname] += ((user.workingday * 60 * 60 * 1000) / 5);
        } else {
          expectedWorkingHours[user.fullname] += ((user.workingday * 60 * 60 * 1000) / 5);
        }
      });

      if( totalWorkingHours[user.fullname] < expectedWorkingHours[user.fullname] ) {
        statusWorkingHours[user.fullname] = "bg-danger text-white"
      }else{
        statusWorkingHours[user.fullname] = "bg-success text-white"
      }

      let hours = Math.floor(totalWorkingHours[user.fullname] / (1000 * 60 * 60));
      let totalMinutes = Math.floor((totalWorkingHours[user.fullname] % (1000 * 60 * 60)) / (1000 * 60));

      let minutes = totalMinutes.toString().padStart(2, '0');

      totalWorkingHours[user.fullname] = `${hours}h${minutes}m`;

      hours = Math.floor(expectedWorkingHours[user.fullname] / (1000 * 60 * 60));
      totalMinutes = Math.floor((expectedWorkingHours[user.fullname] % (1000 * 60 * 60)) / (1000 * 60));

      minutes = totalMinutes.toString().padStart(2, '0');

      expectedWorkingHours[user.fullname] = `${hours}h${minutes}m`;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col"></th>
              <th scope="col">Trabajador</th>
              <th scope="col">Jornada Semanal</th>
              <th scope="col">Jornada Esperada</th>
              <th scope="col">Jornada Efectiva</th>
            </tr>
          </thead>
          <tbody>
            {usersToRender.filter(user => user.status === 'ALTA').map(user => (
              <tr key={user.fullname} className={statusWorkingHours[user.fullname]} onClick={e => this.goTo(user.fullname, e)}>
                <td><img className="user-icon mr-2" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${user.fullname}.png`} alt="User" /></td>
                <td>{user.fullname}</td>
                <td>{user.workingday}h</td>
                <td>{expectedWorkingHours[user.fullname]}</td>
                <td>{totalWorkingHours[user.fullname]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const { shifts, promoters } = this.props

    if (!shifts || !promoters) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              JORNADAS PROMOTOR
            </h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 text-right">
            <Link to="/shifts/add">
              <button type="button" className="btn bg-vw-light text-white">
                <i className="fas fa-plus mr-1"></i>Añadir
              </button>
            </Link>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <Box title="Filtro" icon="filter">
              <ShiftsFilterForm />
            </Box>
            <Box title="Jornada" icon="calendar">
              {this.renderWorkers()}
            </Box>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6">
            <Box title="Cómputo Horas">
              {this.renderTotalWorkers()}
            </Box>
            <Box title="Registros">
              {this.renderShifts()}
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  shifts: state.shifts.shifts,
  promoters: state.promoters.promoters,
  form: state.form,
  filteredPromoters: state.promoters.filteredPromoters,
  shiftdaysPromoters: state.promoters.shiftdaysPromoters
})

const mapDispatchToProps = dispatch => ({
  fetchShifts: bindActionCreators(fetchShifts, dispatch),
  fetchPromoters: bindActionCreators(fetchPromoters, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsView)


