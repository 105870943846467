import React from 'react'

class MachinePromoterFeesTable extends React.Component {
  render() {
    const { machine } = this.props

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Locker Basic</th>
              <th scope="col">Locker Basic XL</th>
              <th scope="col">Locker Charger</th>
              <th scope="col">Locker Charger XL</th>
              <th scope="col">Tumbonas</th>
              <th scope="col">Flotadores día</th>
              <th scope="col">Flotadores medio día</th>
              <th scope="col">Fastpass</th>
            </tr>
          </thead>
          <tbody>
            <tr key={machine.Serial}>
              <td>{machine.Promoter_fees['LOCKERBASIC']} €</td>
              <td>{machine.Promoter_fees['LOCKERBASICXL']} €</td>
              <td>{machine.Promoter_fees['LOCKERCHARGER']} €</td>
              <td>{machine.Promoter_fees['LOCKERCHARGERXL']} €</td>
              <td>{machine.Promoter_fees['TUMBONAS']} €</td>
              <td>{machine.Promoter_fees['FLOTADORES']} €</td>
              <td>{machine.Promoter_fees['FLOTADORESHALFDAY']} €</td>
              <td>{machine.Promoter_fees['FASTPASS']} €</td>
              <td>{machine.Promoter_fees['VWPACK']} €</td>
              <td>{machine.Promoter_fees['VWLAY']} €</td>
              <td>{machine.Promoter_fees['VWGO']} €</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default MachinePromoterFeesTable


