import {
  MINIS_REQUEST, MINIS_SUCCESS, MINIS_FAILURE,
  MINI_REQUEST, MINI_SUCCESS, MINI_FAILURE,
  MINI_ADD_REQUEST, MINI_ADD_SUCCESS, MINI_ADD_FAILURE,
  MINI_UPDATE_REQUEST, MINI_UPDATE_SUCCESS, MINI_UPDATE_FAILURE,
  MINIS_RESET
} from '../actions/minis'

const INITIAL_STATE = {
  minis: null, mini: null, error: null, loading: false
}

const MinisReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case MINIS_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MINIS_SUCCESS:
        return {
          ...state,
          minis: action.response, error: null, loading: false
        }
      case MINIS_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MINI_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MINI_SUCCESS:
        return {
          ...state,
          mini: action.response, error: null, loading: false
        }
      case MINI_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MINI_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MINI_ADD_SUCCESS:
        return {
          ...state,
          mini: action.response, error: null, loading: false
        }
      case MINI_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MINI_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MINI_UPDATE_SUCCESS:
        return {
          ...state,
          mini: action.response, error: null, loading: false
        }
      case MINI_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MINIS_RESET:
        return INITIAL_STATE;
      default:
        return state
    }
  }

export default MinisReducer
