import { CALL_API } from '../middleware/api'

export const ARQUEOS_REQUEST = 'ARQUEOS_REQUEST'
export const ARQUEOS_SUCCESS = 'ARQUEOS_SUCCESS'
export const ARQUEOS_FAILURE = 'ARQUEOS_FAILURE'

export function fetchArqueos(Machine, Customer, StartDate, EndDate, Country) {
  let params = ''

  if (Machine !== undefined) {
    params += `&Machine=${Machine}`
  }

  if (Customer !== undefined) {
    params += `&Customer=${Customer}`
  }

  if (Country !== undefined) {
    params += `&Country=${Country}`
  }

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `arqueos?${params}`,
      authenticated: true,
      types: [ARQUEOS_REQUEST, ARQUEOS_SUCCESS, ARQUEOS_FAILURE]
    }
  }
}

export const ARQUEO_REQUEST = 'ARQUEO_REQUEST'
export const ARQUEO_SUCCESS = 'ARQUEO_SUCCESS'
export const ARQUEO_FAILURE = 'ARQUEO_FAILURE'

export function fetchArqueo(Serial) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `arqueos/${Serial}`,
      authenticated: true,
      types: [ARQUEO_REQUEST, ARQUEO_SUCCESS, ARQUEO_FAILURE]
    }
  }
}