import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { history } from '../store'
import { fetchMachines } from '../actions/machines'
import { fetchIncidences } from '../actions/incidences'
import { fetchSales } from '../actions/sales'

import MachineStatusBadge from '../components/MachineStatusBadge'
import MachineMap from '../components/MachineMap'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import PropertyLabel from '../components/PropertyLabel'

import { getMachinePicture, getMachineCoordinates } from '../utils/machines'

import { getMachineStatus, getMachineCirclePicture } from '../utils/machines'

class HomeView extends React.Component {
  constructor(props) {
    super(props)

    this.state = { machine: null }
  }

  componentDidMount() {
    const { fetchMachines, fetchIncidences, fetchSales, role, type, fullname, id } = this.props

    if(role === 'PROMOTER'){
      history.push(`/promoter/${fullname}`)
    }

    const today = new Date().toISOString().substring(0, 10)

    fetchMachines()
    fetchIncidences('', 'OPEN', '', '')
    fetchSales('', '', today, today, '', '', '', '')
  }

  onMachineClick = (index, childProps) => {
    this.setState({ machine: childProps.machine })
  }

  computeSalesTotals() {
    const { sales: { sales } } = this.props

    let total = 0

    let totalsPerItem = new Map([
      ['VWPACK', 0],
      ['VWLAY', 0],
      ['VWGO', 0],
      ['LOCKERBASIC', 0],
      ['LOCKERBASICXL', 0],
      ['LOCKERCHARGER', 0],
      ['LOCKERCHARGERXL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    const itemIndices = new Map([
      ['LOCKERBASIC', 0],
      ['LOCKERBASICXL', 1],
      ['LOCKERCHARGER', 2],
      ['LOCKERCHARGERXL', 3],
      ['TUMBONAS', 4],
      ['FLOTADORES', 5],
      ['FLOTADORESHALFDAY', 6],
      ['FASTPASS', 7],
      ['VWPACK', 8],
      ['VWLAY', 9],
      ['VWGO', 10]
    ])

    let qtyPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['RFID', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    for (let sale of sales) {

      let qtyPerSaleMethod = qtyPerMethod.get(sale.PaymentMethod)

      for (let item of sale.Items) {
        let itemTotal = item.qty * item.price

        total += itemTotal
        totalsPerItem.set(item.item, totalsPerItem.get(item.item) + itemTotal)

        qtyPerSaleMethod[itemIndices.get(item.item)] += item.qty
      }

      qtyPerMethod.set(sale.PaymentMethod, qtyPerSaleMethod)
    }

    return {
      total: total,
      totalsPerItem: totalsPerItem,
      qtyPerMethod: qtyPerMethod
    }
  }

  computeItemsTotals() {
    const { sales: { sales } } = this.props

    let totalQty = 0

    let totalsPerItemQty = new Map([
      ['VWPACK', 0],
      ['VWLAY', 0],
      ['VWGO', 0],
      ['LOCKERBASIC', 0],
      ['LOCKERBASICXL', 0],
      ['LOCKERCHARGER', 0],
      ['LOCKERCHARGERXL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    for (let sale of sales) {
      for (let item of sale.Items) {
        let itemTotal = item.qty

        totalQty += itemTotal
        totalsPerItemQty.set(item.item, totalsPerItemQty.get(item.item) + itemTotal)
      }
    }

    return {
      totalQty: totalQty,
      totalsPerItemQty: totalsPerItemQty
    }
  }

  computeMachineStatuses() {
    const { machines: { machines } } = this.props

    let statuses = new Map([
      ['ONLINE', 0],
      ['LOCKERS LLENOS', 0],
      ['SIN TICKET', 0],
      ['OFFLINE', 0],
      ['SLEEP', 0],
      ['UNKNOWN', 0]
    ])

    for (let machine of machines) {
      let status = getMachineStatus(machine)

      statuses.set(status, statuses.get(status) + 1)
    }

    return statuses
  }

  render() {
    const { machines, incidences, sales, auth } = this.props

    if (machines.error) {
      return (<ErrorMessage message={machines.error.message} />)
    }

    if (incidences.error) {
      return (<ErrorMessage message={incidences.error.message} />)
    }

    if (sales.error) {
      return (<ErrorMessage message={sales.error.message} />)
    }

    if (!machines.machines || machines.loading ||
      !incidences.incidences || incidences.loading ||
      !sales.sales || sales.loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let totals = this.computeSalesTotals()
    let totalsQty = this.computeItemsTotals()
    let MachineStatuses = this.computeMachineStatuses()

    return (
      <div>
        <h1>
          <span className="text-vw-dark">TABLERO DE </span>
          <span className="text-vw-light">INFORMACIÓN</span>
          <hr className="bg-vw-light" />
        </h1>
        <br></br>

        <div class="row">
          {/*<img src="/images/realtime.svg" width="20%" alt="Realtime" />*/}
          {auth.type !== 'LIMITED' &&
            <div className="col col-12 col-lg-3">
              <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
                <h5>IMPORTE</h5>
                <hr className="bg-vw-light" />
                <PropertyLabel name="Pack" value={`${totals.totalsPerItem.get('VWPACK').toFixed(2)} €`} />
                <PropertyLabel name="Lay" value={`${totals.totalsPerItem.get('VWLAY').toFixed(2)} €`} />
                <PropertyLabel name="Go" value={`${totals.totalsPerItem.get('VWGO').toFixed(2)} €`} />
                <PropertyLabel name="Charger" value={`${totals.totalsPerItem.get('LOCKERCHARGER').toFixed(2)} €`} />
                <hr className="bg-vw-light" />
                <PropertyLabel name="TOTAL DIARIO" value={`${totals.total.toFixed(2)} €`} />
              </div>
            </div>
          }
          {auth.type !== 'LIMITED' &&
            <div className="col col-12 col-lg-3">
              <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
                <h5>CANTIDAD</h5>
                <hr className="bg-vw-light" />
                <PropertyLabel name="Pack" value={`${totalsQty.totalsPerItemQty.get('VWPACK')} u`} />
                <PropertyLabel name="Lay" value={`${totalsQty.totalsPerItemQty.get('VWLAY')} u`} />
                <PropertyLabel name="Go" value={`${totalsQty.totalsPerItemQty.get('VWGO')} u`} />
                <PropertyLabel name="Charger" value={`${totalsQty.totalsPerItemQty.get('LOCKERCHARGER')} u`} />
                <hr className="bg-vw-light" />
                <PropertyLabel name="TOTAL DIARIO" value={`${totalsQty.totalQty} u`} />
              </div>
            </div>
          }
          <div className="col col-12 col-lg-3">
            <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
              <h5>ESTADO</h5>
              <hr className="bg-vw-light" />
              <PropertyLabel name="ONLINE"
                value={`${MachineStatuses.get('ONLINE')} / ${machines.machines.length}`} />
              <PropertyLabel name="LOCKERS LLENOS"
                value={`${MachineStatuses.get('LOCKERS LLENOS')} / ${machines.machines.length}`} />
              <PropertyLabel name="SIN TICKET"
                value={`${MachineStatuses.get('SIN TICKET')} / ${machines.machines.length}`} />
              <PropertyLabel name="SLEEP"
                value={`${MachineStatuses.get('SLEEP')} / ${machines.machines.length}`} />
              <hr className="bg-vw-light" />
              <PropertyLabel name="OFFLINE"
                value={`${MachineStatuses.get('OFFLINE')} / ${machines.machines.length}`} />
            </div>
          </div>
          <div className="col col-12 col-lg-3">
            <div className="p-3 mb-3 bg-light-blue rounded text-vw-dark box-shadow">
              <h5>EXPEDICION MANUAL</h5>
              <hr className="bg-vw-light" />
              <PropertyLabel name="Pack" value={`${totals.qtyPerMethod.get('TOKEN')[8]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[8] / totalsQty.totalsPerItemQty.get('VWPACK')) * 100).toFixed(2)} %`} />
              <PropertyLabel name="Lay" value={`${totals.qtyPerMethod.get('TOKEN')[9]} u  - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[9] / totalsQty.totalsPerItemQty.get('VWLAY')) * 100).toFixed(2)} %`} />
              <PropertyLabel name="Go" value={`${totals.qtyPerMethod.get('TOKEN')[10]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[10] / totalsQty.totalsPerItemQty.get('VWGO')) * 100).toFixed(2)} %`} />
              <PropertyLabel name="Charger" value={`${totals.qtyPerMethod.get('TOKEN')[2]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[2] / totalsQty.totalsPerItemQty.get('CHARGER')) * 100).toFixed(2)} %`} />
            </div>
            {auth.type === 'ROOT' || auth.type === 'GOD' &&
              <div className="bg-white rounded text-warning box-shadow">
                <i className="fas fa-exclamation-triangle" style={{ display: 'inline-block', width: '2rem', marginTop: '0.5rem', marginLeft: '2rem', height: '2rem'}}></i>
                <h4 style={{ display: 'inline-block', marginLeft: '1rem' }}><b>{incidences.incidences.length}</b> incidentes</h4>
              </div>
            }
          </div>
        </div>
        <br></br>
        <br></br>
        <h1>
          <span className="text-vw-dark">LOCALIZACIÓN </span>
          <span className="text-vw-light">DE MÁQUINAS</span>
          <hr className="bg-vw-light" />
        </h1>
        <br></br>

        <div className="mb-3 rounded box-shadow" style={{ width: '100%', height: '400px' }}>
          <MachineMap machines={machines.machines}
            onMachineClick={this.onMachineClick} />
        </div>
        <Box title="Detalles de la máquina" icon="info-circle">
          {!this.state.machine ? (
            <p className="mt-2 text-center">
              Selecciona una máquina para ver sus detalles.
            </p>
          ) : (
            <div className="row m-2">
              <div className="col col-sm-3 text-center">
                <img src={getMachinePicture(this.state.machine.Type)} alt="Máquina" width="35%" style={{ marginLeft: '20%' }} />

                <MachineStatusBadge machine={this.state.machine} />
              </div>
              <div className="col col-sm-9">
                <PropertyLabel name="Número de serie"
                  value={this.state.machine.Serial} />
                <PropertyLabel name="Cliente"
                  value={this.state.machine.Customer.Fullname} />
                <Link to={`/machines/${this.state.machine.Serial}`}>
                  <button type="button" className="btn bg-vw-light text-white mt-3">
                    Gestionar &rarr;
                  </button>
                </Link>
              </div>
            </div>
          )}
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machines: state.machines,
  incidences: state.incidences,
  sales: state.sales,
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchIncidences: bindActionCreators(fetchIncidences, dispatch),
  fetchSales: bindActionCreators(fetchSales, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)
