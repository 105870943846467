import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchOnlineSales, fetchOnlineSalesCSV } from '../actions/sales'

import Box from '../components/Box'
import OnlineSaleList from '../components/OnlineSaleList'
import ErrorMessage from '../components/ErrorMessage'
import PropertyLabel from '../components/PropertyLabel'
import SalesPlots from '../components/SalesPlots'

import OnlineSalesFilterForm from '../forms/OnlineSalesFilterForm'

class OnlineSalesView extends React.Component {
  constructor(props) {
    super(props)

    this.handleSalesCSV = this.handleSalesCSV.bind(this)
  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10)
    this.props.fetchOnlineSales('', '', today, today)
  }

  computeSalesTotals() {
    const { sales } = this.props

    const itemIndices = new Map([
      ['Vpack', 0],
      ['Vpack + Vlay', 1],
      ['Vpack + Vgo!', 2],
      ['Vpack + Vlay +Vgo!', 4],
    ])

    let total = 0

    let totalsPerItem = new Map([
      ['Vpack', 0],
      ['Vpack + Vlay', 0],
      ['Vpack + Vgo!', 0],
      ['Vpack + Vlay +Vgo!', 0],
    ])

    for (let sale of sales) {
        let itemTotal = 1 * sale.SwapPrice

        total += sale.SwapPrice
        totalsPerItem.set(sale.Item, totalsPerItem.get(sale.Item) + 1)

    }

    return {
      total: total,
      totalsPerItem: totalsPerItem
    }
  }

  handleSalesCSV() {
    const { fetchOnlineSalesCSV, form } = this.props

    fetchOnlineSalesCSV(form.OnlineSalesFilterForm.values.machine,
                  form.OnlineSalesFilterForm.values.customer,
                  form.OnlineSalesFilterForm.values.StartDate,
                  form.OnlineSalesFilterForm.values.EndDate)
  }

  renderSalesTotals() {
    const { sales, error, loading, auth } = this.props

    if (auth.type === 'LIMITED') {
      return null
    }

    if (error) {
      return (<ErrorMessage message={error.message}/>)
    }

    if (!sales || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let totals = this.computeSalesTotals()

    return (
      <div className="p-3 mb-3 rounded box-shadow bg-vw-darkest text-white text-center">
        <div className="p-3 bg-white rounded text-vw-dark">
          <div className="d-flex justify-content-between">
            <h5>TOTAL:</h5>
            <h5 className="text-vw-light">{`${totals.total} EUR`}</h5>
          </div>
          <hr className="bg-vw-light"/>
          <div className="row">
            <div className="col">
              <h6 className="text-left text-vw-light">Productos</h6>
              <PropertyLabel name="Vpack" value={`${totals.totalsPerItem.get('Vpack')} Unidades`}/>
              <PropertyLabel name="Vpack + Vlay" value={`${totals.totalsPerItem.get('Vpack + Vlay')} Unidades`}/>
              <PropertyLabel name="Vpack + Vgo!" value={`${totals.totalsPerItem.get('Vpack + Vgo!')} Unidades`}/>
              <PropertyLabel name="Vpack + Vlay +Vgo!" value={`${totals.totalsPerItem.get('Vpack + Vlay +Vgo!')} Unidades`}/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSales() {
    const { sales, error, loading, auth } = this.props

    if (error) {
      return (<ErrorMessage message={error.message}/>)
    }

    if (!sales || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>
        <Box title="Listado">
          { auth.type !== 'LIMITED' &&
            <div className="row mb-3">
              <div className="col text-right">
                <button className="btn bg-vw-excel text-white" onClick={this.handleSalesCSV}>
                  <i className="fas fa-file-excel mr-1"></i>Descargar
                </button>
              </div>
            </div>
          }
          <OnlineSaleList auth={auth}/>
        </Box>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              VENTAS ONLINE
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-12 col-lg-6">
            <Box title="Filtro" icon="filter">
              <OnlineSalesFilterForm/>
            </Box>
          </div>
          <div className="col col-12 col-lg-6">
            { this.renderSalesTotals() }
          </div>
        </div>
        { this.renderSales() }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sales: state.sales.sales,
  error: state.sales.error,
  loading: state.sales.loading,
  auth: state.auth,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchOnlineSales: bindActionCreators(fetchOnlineSales, dispatch),
  fetchOnlineSalesCSV: bindActionCreators(fetchOnlineSalesCSV, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OnlineSalesView)


