import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { history } from '../store'

import MachineStatusBadge from './MachineStatusBadge'
import MachineCashBadge from './MachineCashBadge'
import { getMachineCash, getMachineCashColor } from '../utils/machines'
import { addUniversalUpdateSingleParameters, addUniversalUpdateSingleReboot } from '../actions/universalupdates'

class MachineList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      parametersConfirmShown: false,
      rebootConfirmShown: false,
      machineId: null,
      filters: {
        serial: '',
        customer: '',
        status: '',
        biller: '',
      },
    };

    this.updateParameters = this.updateParameters.bind(this)
    this.rebootMachine = this.rebootMachine.bind(this)

    this.parametersConfirmShow = this.parametersConfirmShow.bind(this)
    this.parametersConfirmToggle = this.parametersConfirmToggle.bind(this)
    this.rebootConfirmShow = this.rebootConfirmShow.bind(this)
    this.rebootConfirmToggle = this.rebootConfirmToggle.bind(this)
  }

  goTo(Serial, e) {
    history.push(`/machines/${Serial}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  renderLanguages(languages) {

    let languageImages = []

    languages.forEach(language => {
      // Check if 'Language' property exists and is not undefined
      if (language.Language) {
        const languageCode = language.Language.split('_')[0];
        languageImages.push(
          <div className="col">
            <img className="machinelistlanguage" src={`/languages/${languageCode.toLowerCase()}.png`} alt={language.Language}></img>
          </div>
        );
      }
    });

    return (
      <div className="row">
        {languageImages}
      </div>
    );
  }

  handleParametersClick = (machine, event) => {
    event.stopPropagation();
    if (machine.Status !== 'OPERATIVE') {
      alert("Acción bloqueada! La máquina no está online.");
    } else {
      this.parametersConfirmShow(machine._id);
    }
  }

  updateParameters() {
    const { addUniversalUpdateSingleParameters } = this.props

    addUniversalUpdateSingleParameters(this.state.machineId)
    this.parametersConfirmToggle()
  }

  parametersConfirmShow(id) {
    this.setState({
      parametersConfirmShown: true,
      machineId: id
    })
  }

  parametersConfirmToggle() {
    this.setState({ parametersConfirmShown: !this.state.parametersConfirmShown })
  }

  handleRebootClick = (machine, event) => {
    event.stopPropagation();
    if (machine.Status !== 'OPERATIVE') {
      alert("Acción bloqueada! La máquina no está online.");
    } else {
      this.rebootConfirmShow(machine._id);
    }
  }

  rebootMachine() {
    const { addUniversalUpdateSingleReboot } = this.props

    addUniversalUpdateSingleReboot(this.state.machineId)
    this.rebootConfirmToggle()
  }

  rebootConfirmShow(id) {
    this.setState({
      rebootConfirmShown: true,
      machineId: id
    })
  }

  rebootConfirmToggle() {
    this.setState({ rebootConfirmShown: !this.state.rebootConfirmShown })
  }

  render() {
    const { filters } = this.state;
    const { machines, auth, role } = this.props;

    // Apply the filters to filter the machines array
    const filteredMachines = machines.filter(machine => {
      const { serial, customer, status, biller } = filters;

      // Apply the Serial filter
      if (serial && !machine.Serial.includes(serial)) {
        return false;
      }

      // Apply the Customer filter
      if (
        customer &&
        (!machine.Customer ||
          !machine.Customer.Fullname.toLowerCase().includes(customer.toLowerCase()))
      ) {
        return false;
      }

      // Apply the Status filter
      if (
        status &&
        (!machine.Status ||
          !machine.Status.toLowerCase().includes(status.toLowerCase()))
      ) {
        return false;
      }

      // Apply the Biller filter
      if (
        biller &&
        (!getMachineCash(machine).toLowerCase().includes(biller.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the machine in the filtered list
      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Identificador</th>
              <th scope="col">Cliente</th>
              <th scope="col">Estado</th>
              <th scope="col">Idiomas</th>
              <th scope="col">Passwords</th>
              <th scope="col">Sleep</th>
              <th scope="col">Versiones</th>
              <th scope="col">Billetero</th>
              <th scope="col">Factura</th>
              <th scope="col">Actualizar</th>
              <th scope="col">Reiniciar</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Serial"
                  value={filters.serial}
                  onChange={e => this.handleFilterChange('serial', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Cliente"
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col">
                <select
                  value={filters.status}
                  onChange={e => this.handleFilterChange('status', e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="OPERATIVE">ONLINE</option>
                  <option value="OFFLINE">OFFLINE</option>
                  <option value="SLEEP">SLEEP</option>
                  <option value="NIGHT_SCAN">NIGHTSCAN</option>
                  <option value="MAINTENANCE">MANTENIMIENTO</option>
                  <option value="LOCKERS_FULL">LOCKERS LLENOS</option>
                  <option value="TICKET_EMPTY">SIN TICKET</option>
                </select>
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">
                <select
                  value={filters.biller}
                  onChange={e => this.handleFilterChange('biller', e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="OK">OK</option>
                  <option value="FULL">FULL</option>
                </select>
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredMachines.map(machine => (
              <tr key={machine.Serial}
                onClick={e => this.goTo(machine.Serial, e)}>
                <th scope="row">{machine.Serial}</th>
                <td>
                  {(machine.Customer && machine.Customer.Fullname) || 'None'}
                </td>
                <td>
                  <div className="d-inline-block p-2">
                    <MachineStatusBadge machine={machine} />
                  </div>
                </td>
                <td>
                  {this.renderLanguages(machine.SupportedLanguages)}
                </td>
                <td>
                  Maquina: {machine.PasswordMachine}<br></br>
                  Cash: {machine.PasswordCash}
                </td>
                <td>
                  Start: {machine.SleepStart}<br></br>
                  End: {machine.SleepEnd}
                </td>
                <td>
                  SW: {machine.Sw_version}<br></br>
                  FW: {machine.Fw_version}<br></br>
                  UP: {machine.Up_version}<br></br>
                </td>
                <td>
                  <div className="d-inline-block p-2">
                    <MachineCashBadge machine={machine} />
                  </div>
                </td>
                <td>
                  {machine.Serial}{machine.LastSale}
                </td>
                {(auth.type !== 'LIMITED' && role === 'ADMIN') &&
                  <td>
                    <button
                      className="btn btn-info"
                      onClick={(event) => this.handleParametersClick(machine, event)}
                    >
                      <i className="fas fa-database"></i>
                      <span className="d-none d-sm-inline ml-1">Actualizar</span>
                    </button>
                  </td>
                }
                {(auth.type !== 'LIMITED' && role === 'ADMIN') &&
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(event) => this.handleRebootClick(machine, event)}
                    >
                      <i className="fas fa-plug"></i>
                      <span className="d-none d-sm-inline ml-1">Reiniciar</span>
                    </button>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
        {/* Confirm deletion */}
        <div>
          <Modal isOpen={this.state.parametersConfirmShown}
            toggle={this.parametersConfirmToggle}>
            <ModalHeader toggle={this.parametersConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de actualizar los parámetros?</p>
              <br></br>
              <p>-La máquina se actualizará interrumpiendo los procesos y saldrá un mensaje en pantalla para reiniciar la máquina una vez finalizado el proceso.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.updateParameters}>
                Actualizar
              </Button>
              <Button color="secondary" onClick={this.parametersConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div>
          <Modal isOpen={this.state.rebootConfirmShown}
            toggle={this.rebootConfirmToggle}>
            <ModalHeader toggle={this.rebootConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de reiniciar la máquina?</p>
              <br></br>
              <p>-La máquina se reiniciará interrumpiendo los procesos.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.rebootMachine}>
                Reiniciar
              </Button>
              <Button color="secondary" onClick={this.rebootConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  addUniversalUpdateSingleParameters: bindActionCreators(addUniversalUpdateSingleParameters, dispatch),
  addUniversalUpdateSingleReboot: bindActionCreators(addUniversalUpdateSingleReboot, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineList)