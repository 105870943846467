import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { addMini } from '../actions/minis'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'

class MiniAddForm extends React.Component {
       constructor(props) {
              super(props)

              this.submit = this.submit.bind(this)
       }

       submit(props) {

              props.Executor = this.props.fullname

              return this.props.addMini(props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       render() {
              const { error, pristine, submitting, handleSubmit } = this.props

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">MINI </span>
                                   <span className="text-vw-light">NUEVA</span>
                            </h1>

                            <div className="row">
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Mini" icon="cubes">
                                                 <Field name="Build_date"
                                                        component={renderInputField}
                                                        type="date"
                                                        label="Fecha de fabricación" />
                                                 <Field name="Comments"
                                                        type="text"
                                                        component={renderTextAreaField}
                                                        label="Comentarios" />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

MiniAddForm = reduxForm({
       form: 'MiniAddForm',
       keepDirtyOnReinitialize: true,
       enableReinitialize: true,
       updateUnregisteredFields: true,
       initialValues: {
         Build_date: new Date("2019-05-01T00:00:00.000Z").toISOString().split("T")[0],
       },
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/minis`)
       }
})(MiniAddForm)

const mapStateToProps = state => ({
       auth: state.auth,
       role: state.auth.role,
       type: state.auth.type,
       fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
       addMini: bindActionCreators(addMini, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MiniAddForm)
