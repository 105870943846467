import { CALL_API } from '../middleware/api'

export function addUniversalUpdateSingleParameters(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `universalupdates/parameters/${id}`,
      authenticated: true,
      types: [UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_REQUEST, UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_SUCCESS,
        UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_REQUEST = 'UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_REQUEST'
export const UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_SUCCESS = 'UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_FAILURE = 'UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_FAILURE'

export function addUniversalUpdateSingleReboot(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `universalupdates/reboot/${id}`,
      authenticated: true,
      types: [UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_REQUEST, UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_SUCCESS,
        UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_REQUEST = 'UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_REQUEST'
export const UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_SUCCESS = 'UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_FAILURE = 'UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_FAILURE'
