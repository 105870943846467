import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as dateFormat from 'dateformat'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import SoldItemList from '../components/SoldItemsList'
import ErrorMessage from '../components/ErrorMessage'

import { fetchSale } from '../actions/sales'

class SaleDetailsView extends React.Component {
  componentDidMount() {
    this.props.fetchSale(this.props.match.params.id)
  }

  render() {
    const { sale, error, loading, auth } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!sale || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col">
            <h1>
              <span className="text-vw-dark">DETALLES DE LA </span>
              <span className="text-vw-light">VENTA  </span>
              <span className="text-vw-dark">{sale.Code}</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Box title="Resumen" icon="sliders-h">
              <div className="row">
                <div className="col col-12 col-xs-12 col-md-6">
                  <PropertyLabel name="ID"
                    value={sale._id} />
                  <PropertyLabel name="Venta"
                    value={(new Date(sale.TimeStamp)).toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false })} />
                  <PropertyLabel name="Cliente"
                    value={sale.Customer.Fullname} />
                  <PropertyLabel name="Máquina"
                    value={sale.Machine.Serial} />
                  <PropertyLabel name="Código"
                    value={sale.Code} />
                </div>
                <div className="col col-12 col-xs-12 col-md-4">
                  <PropertyLabel name="Método de pago"
                    value={sale.PaymentMethod} />
                  <PropertyLabel name="Origen"
                    value={sale.Origin} />
                  <PropertyLabel name="Otros detalles" ml
                    value={sale.Details} />
                </div>
              </div>
            </Box>
          </div>
          <div className="col">
            <Box title="Artículos vendidos" icon="shopping-cart">
              <SoldItemList items={sale.Items} currency={sale.Contract.Currency} auth={auth} />
            </Box>
          </div>
        </div>
        <Box title="Importe" icon="dollar">
          <div className="d-flex">
            <h2 className="mr-3">Total:</h2>
            <h2>{`${parseFloat(sale.Amount).toFixed(2)} €`}</h2>
          </div>
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sale: state.sales.sale,
  loading: state.sales.loading,
  error: state.sales.error,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  fetchSale: bindActionCreators(fetchSale, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SaleDetailsView)



