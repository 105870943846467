import {
    PROMOTERSALES_REQUEST, PROMOTERSALES_SUCCESS, PROMOTERSALES_FAILURE
} from '../actions/promotersales'
  
const INITIAL_STATE = {
    stats: null, error: null, loading: false
}

const promoterSalesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case PROMOTERSALES_REQUEST:
        return {
        ...state,
        error: null, loading: true
        }
    case PROMOTERSALES_SUCCESS:
        return {
        ...state,
        stats: action.response, error: null, loading: false
        }
    case PROMOTERSALES_FAILURE:
        return {
        ...state,
        error: action.error, loading: false
        }
    default:
        return state
    }
}

export default promoterSalesReducer