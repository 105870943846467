import {
  SALES_REQUEST, SALES_SUCCESS, SALES_FAILURE,
  SALE_REQUEST, SALE_SUCCESS, SALE_FAILURE,
  SALE_ADD_REQUEST, SALE_ADD_SUCCESS, SALE_ADD_FAILURE,
  SALE_UPDATE_REQUEST, SALE_UPDATE_SUCCESS, SALE_UPDATE_FAILURE,
  SALE_DELETE_REQUEST, SALE_DELETE_SUCCESS, SALE_DELETE_FAILURE,
  SALE_RESET
} from '../actions/sales'

const INITIAL_STATE = {
  sales: null, salesNeedRefresh: false, sale: null, error: null,
  loading: false
}

const salesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SALES_REQUEST:
        return {
          ...state,
          salesNeedRefresh: false, error: null, loading: true
        }
      case SALES_SUCCESS:
        return {
          ...state,
          salesNeedRefresh: false, sales: action.response, error: null, loading: false
        }
      case SALES_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SALE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SALE_SUCCESS:
        return {
          ...state,
          sale: action.response, error: null, loading: false
        }
      case SALE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SALE_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SALE_ADD_SUCCESS:
        return {
          ...state,
          salesNeedRefresh: true, sale: action.response, error: null,
          loading: false
        }
      case SALE_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SALE_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SALE_UPDATE_SUCCESS:
        return {
          ...state,
          salesNeedRefresh: true, sale: action.response, error: null,
          loading: false
        }
      case SALE_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SALE_DELETE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SALE_DELETE_SUCCESS:
        return {
          ...state,
          salesNeedRefresh: true, sale: action.response, error: null,
          loading: false
        }
      case SALE_DELETE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SALE_RESET:
        return {
          ...state,
          sale: null, error: null, loading: false
        }
      default:
        return state
    }
  }

export default salesReducer

