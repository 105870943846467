import {
  REMOTE_ACTIONS_REQUEST, REMOTE_ACTIONS_SUCCESS, REMOTE_ACTIONS_FAILURE,
  REMOTE_ACTION_ADD_REQUEST, REMOTE_ACTION_ADD_SUCCESS, REMOTE_ACTION_ADD_FAILURE,
  REMOTE_ACTIONS_RESET, REMOTE_ACTION_DELETE_REQUEST, REMOTE_ACTION_DELETE_SUCCESS, REMOTE_ACTION_DELETE_FAILURE,
  REMOTE_ACTION_CANCEL_REQUEST, REMOTE_ACTION_CANCEL_SUCCESS, REMOTE_ACTION_CANCEL_FAILURE
} from '../actions/remoteactions'

const INITIAL_STATE = {
  remoteActionsNeedRefresh: false, remoteActions: null, error: null, loading: false
}

const remoteActionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REMOTE_ACTIONS_REQUEST:
      return {
        ...state,
        remoteActionsNeedRefresh: false, loading: true
      }
    case REMOTE_ACTIONS_SUCCESS:
      return {
        ...state,
        remoteActionsNeedRefresh: false, remoteActions: action.response, error: null, loading: false
      }
    case REMOTE_ACTIONS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case REMOTE_ACTION_ADD_REQUEST:
      return {
        ...state,
        loading: true
      }
    case REMOTE_ACTION_ADD_SUCCESS:
      return {
        ...state,
        remoteActionsNeedRefresh: true, remoteAction: action.response, error: null, loading: false
      }
    case REMOTE_ACTION_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case REMOTE_ACTION_DELETE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case REMOTE_ACTION_DELETE_SUCCESS:
      return {
        ...state,
        remoteAction: action.response, error: null, loading: false
      }
    case REMOTE_ACTION_DELETE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case REMOTE_ACTION_CANCEL_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case REMOTE_ACTION_CANCEL_SUCCESS:
      return {
        ...state,
        remoteActionsNeedRefresh: true, remoteAction: action.response, error: null, loading: false
      }
    case REMOTE_ACTION_CANCEL_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case REMOTE_ACTIONS_RESET:
      return INITIAL_STATE;
    default:
      return state
  }
}

export default remoteActionsReducer

