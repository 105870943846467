import React from 'react'
import * as dateFormat from 'dateformat'


class ShiftEntryList extends React.Component {

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Trabajador</th>
              <th scope="col">Fecha/Hora</th>
              <th scope="col">Máquina</th>
            </tr>
          </thead>
          <tbody>
            {this.props.shifts.map(shift => (
              <tr key={shift._id}>
                <td>{shift.workerName}</td>
                <td>{(new Date(shift.date)).toLocaleString('es-ES', { timeZone: 'UTC', hour12: false })}</td>
                <td>{shift.machine.Serial}</td> 
              </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ShiftEntryList

