import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchArqueos } from '../actions/arqueos'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { push } from 'react-router-redux'

class ComissionDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      VWPACKComission: 0.05,
      VWLAYComission: 0.03,
      VWGOComission: 0.15,
    }

  }

  componentDidMount() {
    const { client, year } = this.props.location.state;

    const currentYear = year

    const yearStart = new Date(currentYear, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(currentYear + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    this.props.fetchArqueos('', client, formattedStartDate, formattedEndDate, '');
  }

  calculatePriceWithoutTaxAndComission(priceWithTax, taxRate, comissionRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    let comissionMultiplier = 1;

    comissionRate != 0 ? comissionMultiplier = comissionRate / 100 : comissionMultiplier = 1

    // Calculate price without tax and round to two decimal places
    const priceWithoutTaxAndComission = ((priceWithTax / taxMultiplier) * comissionMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTaxAndComission);
  }

  calculateUnitsCustomerTotals() {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      productTotal: 0,
      VWPACKTotal: 0,
      VWGOTotal: 0,
      VWLAYTotal: 0,
      productMonthTotal: Array(12).fill(0),
      VWPACK: Array(12).fill(0),
      VWGO: Array(12).fill(0),
      VWLAY: Array(12).fill(0),
    };

    let year = new Date();
    let customer = "";

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {

      // Extract month from the timestamp
      const month = new Date(arqueo.TimeStamp).getUTCMonth();

      monthlyTotals.productTotal += (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWPACK, arqueo.Tax, arqueo.ItemsComissions.VWPACK) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWGO, arqueo.Tax, arqueo.ItemsComissions.VWGO) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWLAY, arqueo.Tax, arqueo.ItemsComissions.VWLAY) || 0); monthlyTotals.VWPACKTotal += arqueo.Items.VWPACK || 0;
      monthlyTotals.VWGOTotal += arqueo.Items.VWGO || 0;
      monthlyTotals.VWLAYTotal += arqueo.Items.VWLAY || 0;
      monthlyTotals.productMonthTotal[month] += (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWPACK, arqueo.Tax, arqueo.ItemsComissions.VWPACK) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWGO, arqueo.Tax, arqueo.ItemsComissions.VWGO) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWLAY, arqueo.Tax, arqueo.ItemsComissions.VWLAY) || 0);
      monthlyTotals.VWPACK[month] += arqueo.Items.VWPACK || 0;
      monthlyTotals.VWGO[month] += arqueo.Items.VWGO || 0;
      monthlyTotals.VWLAY[month] += arqueo.Items.VWLAY || 0;

      year = new Date(arqueo.TimeStamp).getFullYear();
      customer = arqueo.Customer.Fullname

    });

    return {
      monthlyTotals,
      year: year,
      customer: customer
    };
  }

  render() {
    const { role } = this.props
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const { arqueos, loading, error } = this.props;
    const { year } = this.props.location.state;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const client = this.calculateUnitsCustomerTotals();

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <h1>
              <span className="text-vw-dark">COMISIONES DE </span>
              <span className="text-vw-light">{client.customer} {year}</span>
            </h1>
          </div>
        </div>
        <div className="row mb-2">
          {months.map((month, index) => (
            <div key={month} className="mb-5 col-xs-12 col-sm-6 col-md-6">
              <h5>{month}</h5>
              <div className="week-fields">
                <div className="table-responsive">
                  <table className="table table-hover table-clickable">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Generado</th>
                        <th scope="col">Comisiones</th>
                        <th scope="col">VWPACK</th>
                        <th scope="col">VWLAY</th>
                        <th scope="col">VWGO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{(client.monthlyTotals.productMonthTotal[index]).toFixed(2)}€</td>
                        <td>{((client.monthlyTotals.VWPACK[index] * this.state.VWPACKComission) + (client.monthlyTotals.VWLAY[index] * this.state.VWLAYComission) + (client.monthlyTotals.VWGO[index] * this.state.VWGOComission)).toFixed(2)}€</td>
                        <td>{(client.monthlyTotals.VWPACK[index] * this.state.VWPACKComission).toFixed(2)}€</td>
                        <td>{(client.monthlyTotals.VWLAY[index] * this.state.VWLAYComission).toFixed(2)}€</td>
                        <td>{(client.monthlyTotals.VWGO[index] * this.state.VWGOComission).toFixed(2)}€</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div >
      </div >
    )
  }
}

const mapStateToProps = state => ({
  arqueos: state.arqueos.arqueos,
  error: state.arqueos.error,
  loading: state.arqueos.loading,
  role: state.auth.role,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ComissionDetailsView)

