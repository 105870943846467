import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchMinis, fetchMiniDownloadCSV  } from '../actions/minis'

import MiniList from '../components/MiniList'
import ErrorMessage from '../components/ErrorMessage'

class MinisView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      
    }

    this.handleMiniDownloadCSV = this.handleMiniDownloadCSV.bind(this)
  }

  componentDidMount() {
    this.props.fetchMinis()
  }

  handleMiniDownloadCSV() {
    const { fetchMiniDownloadCSV } = this.props

    fetchMiniDownloadCSV();
  }

  render() {
    const { minis, error, role } = this.props

    return (
      <div>

        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">PARQUE DE </span>
              <span className="text-vw-light">MINIS</span>
            </h1>
          </div>
          {role === 'ADMIN' &&
            <div className="col-xs-12 col-sm-6 col-md-6 text-right">
              <Link to="/minis/add">
                <button type="button" className="btn bg-vw-light text-white">
                  <i className="fas fa-plus mr-1"></i>Nueva Mini
                </button>
              </Link>
              <button type="button" className="ml-2 btn bg-vw-excel text-white" onClick={this.handleMiniDownloadCSV}>
                <i className="fas fa-file-excel mr-1"></i>Descargar Listado
              </button>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <MiniList minis={minis} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  minis: state.minis.minis,
  error: state.minis.error,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchMinis: bindActionCreators(fetchMinis, dispatch),
  fetchMiniDownloadCSV: bindActionCreators(fetchMiniDownloadCSV, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MinisView)
