import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchSales, fetchSalesCSV } from '../actions/sales'

import Box from '../components/Box'
import SaleList from '../components/SaleList'
import ErrorMessage from '../components/ErrorMessage'
import PropertyLabel from '../components/PropertyLabel'
import SalesPlots from '../components/SalesPlots'

import SalesFilterForm from '../forms/SalesFilterForm'

class SalesView extends React.Component {
  constructor(props) {
    super(props)

    this.handleSalesCSV = this.handleSalesCSV.bind(this)
  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10)
    this.props.fetchSales('', '', today, today, '', '', '', '')
  }

  calculateCashSales() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    let totalSales = 0
    let totalCash = 0
    let percentageCash = 0

    sales.forEach((sale, index) => {

      if (sale.Amount >= 0) {
        if (sale.PaymentMethod == 'CASH') {
          totalCash++;
        }

        totalSales++;
      } else if (sale.Amount < 0) {
        if (sale.PaymentMethod == 'CASH') {
          totalCash--;
        }
        totalSales--;
      }

    });

    

    percentageCash = ((totalCash / totalSales) * 100).toFixed(2)

    return (percentageCash)
  }

  calculatePulseraSales() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    let totalSales = 0
    let totalPulsera = 0
    let percentagePulsera = 0

    sales.forEach((sale, index) => {

      if (sale.Amount >= 0) {
        if (sale.PaymentMethod == 'RFID') {
          totalPulsera++;
        }

        totalSales++;
      } else if (sale.Amount < 0) {
        totalSales--;
      }

    });

    percentagePulsera = ((totalPulsera / totalSales) * 100).toFixed(2)

    return (percentagePulsera)
  }

  calculateTPVSales() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    let totalSales = 0
    let totalTPV = 0
    let percentageTPV = 0

    sales.forEach((sale, index) => {

      if (sale.Amount >= 0) {
        if (sale.PaymentMethod == 'TPV') {
          totalTPV++;
        }

        totalSales++;
      } else if (sale.Amount < 0) {
        totalSales--;
      }

    });

    percentageTPV = ((totalTPV / totalSales) * 100).toFixed(2)

    return (percentageTPV)
  }

  calculateManualSales() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    let totalSales = 0
    let totalManual = 0
    let percentageManual = 0

    sales.forEach((sale, index) => {

      if (sale.Amount >= 0) {
        if (sale.PaymentMethod == 'TOKEN') {
          totalManual++;
        }

        totalSales++;
      } else if (sale.Amount < 0) {
        totalSales--;
      }

    });

    

    percentageManual = ((totalManual / totalSales) * 100).toFixed(2)

    return (percentageManual)
  }

  

  computeSalesTotals() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    const itemIndices = new Map([
      ['LOCKERBASIC', 0],
      ['LOCKERBASICXL', 1],
      ['LOCKERCHARGER', 2],
      ['LOCKERCHARGERXL', 3],
      ['TUMBONAS', 4],
      ['FLOTADORES', 5],
      ['FLOTADORESHALFDAY', 6],
      ['FASTPASS', 7],
      ['VWPACK', 8],
      ['VWLAY', 9],
      ['VWGO', 10]
    ])

    let total = 0

    let totalsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['RFID', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let amount = 0

    let amountsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['RFID', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let qtyPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['RFID', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])


    let totalsPerItem = new Map([
      ['LOCKERBASIC', 0],
      ['LOCKERBASICXL', 0],
      ['LOCKERCHARGER', 0],
      ['LOCKERCHARGERXL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0],
      ['VWPACK', 0],
      ['VWLAY', 0],
      ['VWGO', 0]
    ])

    for (let sale of sales) {
      let totalsPerSaleMethod = totalsPerMethod.get(sale.PaymentMethod)
      let amountsPerSaleMethod = amountsPerMethod.get(sale.PaymentMethod)
      let qtyPerSaleMethod = qtyPerMethod.get(sale.PaymentMethod)

      if (sale.Items) {
        for (let item of sale.Items) {
          let itemTotal = item.qty * item.price
          let itemAmount = item.qty * item.price

          if (item.qty > 1) {
            total += (itemTotal / item.qty)

            totalsPerSaleMethod[itemIndices.get(item.item)] += (itemTotal / item.qty)
            totalsPerItem.set(item.item, totalsPerItem.get(item.item) + (itemTotal / item.qty))

            amount += (itemAmount / item.qty)
            amountsPerSaleMethod[itemIndices.get(item.item)] += (itemAmount / item.qty)

          } else {
            total += itemTotal

            totalsPerSaleMethod[itemIndices.get(item.item)] += itemTotal
            totalsPerItem.set(item.item, totalsPerItem.get(item.item) + itemTotal)

            amount += itemAmount
            amountsPerSaleMethod[itemIndices.get(item.item)] += itemAmount
          }

          qtyPerSaleMethod[itemIndices.get(item.item)] += item.qty

        }
      }

      amountsPerMethod.set(sale.PaymentMethod, amountsPerSaleMethod)
      totalsPerMethod.set(sale.PaymentMethod, totalsPerSaleMethod)
      qtyPerMethod.set(sale.PaymentMethod, qtyPerSaleMethod)
    }

    return {
      total: total,
      totalsPerMethod: totalsPerMethod,
      totalsPerItem: totalsPerItem,
      amount: amount,
      amountsPerMethod: amountsPerMethod,
      qtyPerMethod: qtyPerMethod
    }
  }

  computeItemsTotals() {
    const { sales } = this.props

    let totalQty = 0

    let totalsPerItemQty = new Map([
      ['LOCKERBASIC', 0],
      ['LOCKERBASICXL', 0],
      ['LOCKERCHARGER', 0],
      ['LOCKERCHARGERXL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0],
      ['VWPACK', 0],
      ['VWLAY', 0],
      ['VWGO', 0]
    ])

    for (let sale of sales) {
      for (let item of sale.Items) {
        let itemTotal = item.qty

        totalQty += itemTotal
        totalsPerItemQty.set(item.item, totalsPerItemQty.get(item.item) + itemTotal)
      }
    }

    return {
      totalQty: totalQty,
      totalsPerItemQty: totalsPerItemQty
    }
  }

  handleSalesCSV() {
    const { fetchSalesCSV, form } = this.props

    fetchSalesCSV(form.SalesFilterForm.values.Machine,
      form.SalesFilterForm.values.Customer,
      form.SalesFilterForm.values.StartDate,
      form.SalesFilterForm.values.EndDate)
  }

  renderSalesTotals() {
    const { sales, error, loading, auth } = this.props

    if (auth.type === 'LIMITED') {
      return null
    }

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!sales || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let totals = this.computeSalesTotals()
    let totalsQty = this.computeItemsTotals()

    return (
      <div className="mb-3 p-3 bg-white box-shadow rounded text-vw-dark text-center">
        <div className="d-flex justify-content-between">
          <h5>TOTAL DE VENTAS:</h5>
          <h5 className="text-vw-light">{`${totals.total.toFixed(2)} EUR`}</h5>
        </div>
        <hr className="bg-vw-light" />
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col"><h6 className="text-white">PRODUCTO</h6></th>
                <th scope="col"><h6 className="text-white">CASH ({this.calculateCashSales()}%)</h6></th>
                <th scope="col"><h6 className="text-white">TPV ({this.calculateTPVSales()}%)</h6></th>
                <th scope="col"><h6 className="text-white">MANUAL ({this.calculateManualSales()}%)</h6></th>
                <th scope="col"><h6 className="text-white">PULSERA ({this.calculatePulseraSales()}%)</h6></th>
                <th scope="col"><h6 className="text-white">TOTAL</h6></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"><h6 className="text-left text-vw-dark">PACK</h6></th>
                <th scope="row">{`${totals.amountsPerMethod.get('CASH')[8].toFixed(2)} € / ${totals.qtyPerMethod.get('CASH')[8]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('TPV')[8].toFixed(2)} € / ${totals.qtyPerMethod.get('TPV')[8]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('TOKEN')[8].toFixed(2)} € / ${totals.qtyPerMethod.get('TOKEN')[8]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('RFID')[8].toFixed(2)} € / ${totals.qtyPerMethod.get('RFID')[8]} u`}</th>
                <th scope="row">{`${totals.totalsPerItem.get('VWPACK').toFixed(2)} € / ${totalsQty.totalsPerItemQty.get('VWPACK')} u`}</th>
              </tr>
              <tr>
                <th scope="row"><h6 className="text-left text-vw-dark">LAY</h6></th>
                <th scope="row">{`${totals.amountsPerMethod.get('CASH')[9].toFixed(2)} € / ${totals.qtyPerMethod.get('CASH')[9]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('TPV')[9].toFixed(2)} € / ${totals.qtyPerMethod.get('TPV')[9]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('TOKEN')[9].toFixed(2)} € / ${totals.qtyPerMethod.get('TOKEN')[9]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('RFID')[9].toFixed(2)} € / ${totals.qtyPerMethod.get('RFID')[9]} u`}</th>
                <th scope="row">{`${totals.totalsPerItem.get('VWLAY').toFixed(2)} € / ${totalsQty.totalsPerItemQty.get('VWLAY')} u`}</th>
              </tr>
              <tr>
                <th scope="row"><h6 className="text-left text-vw-dark">GO</h6></th>
                <th scope="row">{`${totals.amountsPerMethod.get('CASH')[10].toFixed(2)} € / ${totals.qtyPerMethod.get('CASH')[10]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('TPV')[10].toFixed(2)} € / ${totals.qtyPerMethod.get('TPV')[10]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('TOKEN')[10].toFixed(2)} € / ${totals.qtyPerMethod.get('TOKEN')[10]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('RFID')[10].toFixed(2)} € / ${totals.qtyPerMethod.get('RFID')[10]} u`}</th>
                <th scope="row">{`${totals.totalsPerItem.get('VWGO').toFixed(2)} € / ${totalsQty.totalsPerItemQty.get('VWGO')} u`}</th>
              </tr>
              <tr>
                <th scope="row"><h6 className="text-left text-vw-dark">CHARGER</h6></th>
                <th scope="row">{`${totals.amountsPerMethod.get('CASH')[2].toFixed(2)} € / ${totals.qtyPerMethod.get('CASH')[2]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('TPV')[2].toFixed(2)} € / ${totals.qtyPerMethod.get('TPV')[2]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('TOKEN')[2].toFixed(2)} € / ${totals.qtyPerMethod.get('TOKEN')[2]} u`}</th>
                <th scope="row">{`${totals.amountsPerMethod.get('RFID')[2].toFixed(2)} € / ${totals.qtyPerMethod.get('RFID')[2]} u`}</th>
                <th scope="row">{`${totals.totalsPerItem.get('LOCKERCHARGER').toFixed(2)} € / ${totalsQty.totalsPerItemQty.get('LOCKERCHARGER')} u`}</th>
              </tr>
              <tr>
                <th scope="row"><h5 className="text-left text-vw-dark">TOTALES</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${totals.totalsPerMethod.get('CASH').reduce((acc, currVal) => acc + currVal).toFixed(2)} €`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${totals.totalsPerMethod.get('TPV').reduce((acc, currVal) => acc + currVal).toFixed(2)} €`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${totals.totalsPerMethod.get('TOKEN').reduce((acc, currVal) => acc + currVal).toFixed(2)} €`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${totals.totalsPerMethod.get('RFID').reduce((acc, currVal) => acc + currVal).toFixed(2)} €`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${totals.total.toFixed(2)} €`}</h5></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderSalesStats() {
    return (
      <Box title="Estadísticas" icon="chart-bar">
        <SalesPlots sales={this.props.sales} />
      </Box>
    )
  }

  renderSales() {
    const { sales, error, loading, auth } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!sales || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>
        {auth.type !== 'LIMITED' && this.renderSalesStats()}
        <Box title="Listado (100 ventas por página)">
          {auth.type !== 'LIMITED' &&
            <div className="row mb-3">
              <div className="col">
                <button className="btn bg-vw-excel text-white" onClick={this.handleSalesCSV}>
                  <i className="fas fa-file-excel mr-1"></i>Descargar
                </button>
              </div>
            </div>
          }
          <SaleList auth={auth} />
        </Box>
      </div>
    )
  }

  render() {
    const { role, type } = this.props.auth

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              VENTAS
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <SalesFilterForm />
        </Box>
        <div>
          {this.renderSalesTotals()}
        </div>
        {this.renderSales()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sales: state.sales.sales,
  error: state.sales.error,
  loading: state.sales.loading,
  auth: state.auth,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchSales: bindActionCreators(fetchSales, dispatch),
  fetchSalesCSV: bindActionCreators(fetchSalesCSV, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesView)


