import React from 'react'
import { history } from '../store'

import PromoterStatusBadge from './PromoterStatusBadge'

import * as dateFormat from 'dateformat'

class PromoterList extends React.Component {
  goTo(fullname, e) {
    history.push(`/promoters/${fullname}`)
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nombre</th>
              <th scope="col">Foto</th>
              <th scope="col">Código</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            {this.props.promoters.map(promoter => {

              return (
                <tr key={promoter.number}
                  onClick={e => this.goTo(promoter.fullname, e)}>
                  <th scope="row">{promoter.number}</th>
                  <td>{promoter.fullname}</td>
                  <td><img className="user-icon mr-2" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${promoter.fullname}.png`} alt="User" /></td>
                  <td>{promoter.password}</td>
                  <td>
                    <PromoterStatusBadge status={promoter.status} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default PromoterList

