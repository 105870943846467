import React from 'react'

class ShipmentItemList extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col"></th>
              <th scope="col">Locker Basic</th>
              <th scope="col">Locker Basic XL</th>
              <th scope="col">Locker Charger</th>
              <th scope="col">Locker Charger XL</th>
              <th scope="col">Tumbonas</th>
              <th scope="col">Flotadores día</th>
              <th scope="col">Flotadores medio día</th>
              <th scope="col">Fastpass</th>
            </tr>
          </thead>
          <tbody>
            <tr key="quantities">
              <th scope="row">Cantidad</th>
              <td>{this.props.items['LOCKERBASIC']}</td>
              <td>{this.props.items['LOCKERBASICXL']}</td>
              <td>{this.props.items['LOCKERCHARGER']}</td>
              <td>{this.props.items['LOCKERCHARGERXL']}</td>
              <td>{this.props.items['TUMBONAS']}</td>
              <td>{this.props.items['FLOTADORES']}</td>
              <td>{this.props.items['FLOTADORESHALFDAY']}</td>
              <td>{this.props.items['FASTPASS']}</td>
              <td>{this.props.items['VWPACK']}</td>
              <td>{this.props.items['VWLAY']}</td>
              <td>{this.props.items['VWGO']}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default ShipmentItemList


