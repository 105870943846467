import React from 'react'
import { history } from '../store'

class ShipmentList extends React.Component {
  goTo(number, e) {
    history.push(`/logistics/${number}`)
  }

  render() {
    return (
      this.props.shipments ? (
        <div className="table-responsive">
          <table className="table table-hover table-clickable">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Fecha</th>
                <th scope="col">Cliente</th>
              </tr>
            </thead>
            <tbody>
              {this.props.shipments.map(shipment => (
                <tr key={shipment.number}
                  onClick={e => this.goTo(shipment.number, e)}>
                  <th scope="row">{shipment.number}</th>
                  <td>{new Date(shipment.date).toDateString()}</td>
                  <td>{shipment.Customer.Fullname}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center">No hay envíos para mostrar</p>
      )
    )
  }
}

export default ShipmentList

