import React from 'react'
import { history } from '../store'

import MachineStatusBadge from './MachineStatusBadge'

class MachinesCashSummary extends React.Component {
  render() {
    const countries = ["España", "Italia"].map(country => {
      const countryMachines = this.props.machines.filter(x => x.Customer && x.Customer.Country === country)
      let totalBills = 0;
      let totalCoins = 0;
      let totalChange = 0;
      let totalSecurityBox = 0;

      countryMachines.forEach(machine => {
        const { Bills_in_box, Bills_in_security_box, Cash_in_box, Cash_in_coiner } = machine;
        totalBills += (Bills_in_box.five * 5) + (Bills_in_box.ten * 10) + (Bills_in_box.twenty * 20)
        totalCoins += (Cash_in_box.fifty * 0.50) + (Cash_in_box.one * 1) + (Cash_in_box.two * 2)
        totalChange += (Cash_in_coiner.fifty * 0.50) + (Cash_in_coiner.one * 1) + (Cash_in_coiner.two * 2)
        totalSecurityBox += (Bills_in_security_box.five * 5) + (Bills_in_security_box.ten * 10) + (Bills_in_security_box.twenty * 20)
      })
      // const { Bills_in_box, Bills_in_security_box, Cash_in_box, Cash_in_coiner }
      return {
        name: country,
        totalBills,
        totalCoins,
        totalChange,
        totalSecurityBox,
        total: totalBills + totalCoins + totalChange + totalSecurityBox
      }
    })

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">País</th>
              <th scope="col">Billetes</th>
              <th scope="col">Monedas</th>
              <th scope="col">Cambio</th>
              <th scope="col">C. Seguridad</th>
              <th scope="col"><b> Total </b></th>
            </tr>
          </thead>
          <tbody>
            {countries.map(country => (
              <tr key={country.name}>
                <th scope="row">{country.name}</th>
                <td> {country.totalBills} EUR</td>
                <td> {country.totalCoins} EUR</td>
                <td> {country.totalChange} </td>
                <td> {country.totalSecurityBox} EUR</td>
                <td> <b> {country.total} EUR</b> </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default MachinesCashSummary
