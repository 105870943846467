import React from 'react'
import { history } from '../store'

class UserList extends React.Component {
  goTo(fullname, e) {
    history.push(`/users/${fullname}`)
  }

  getUserRest(rest) {
    
    switch (rest) {
      case 30: return "30 minutos";
      case 60: return "1 hora";
      default: return "sin descanso";
    }
  }
  getUserType(type) {
    
    switch (type) {
      case "GOD": return "Administrador";
      case "ROOT": return "Editor";
      case "HHRR": return "Recursos Humanos";
      case "LIMITED": return "Trabajador";
      default: return "Sin rol";
    }
  }

  render() {
    if (!this.props.users) {
      return null
    }

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nombre completo</th>
              <th scope="col">Foto</th>
              <th scope="col">E-Mail</th>
              <th scope="col">Jornada</th>
              <th scope="col">Descanso</th>
              <th scope="col">Tipo</th>
            </tr>
          </thead>
          <tbody>
            {this.props.users.map(user => (
              <tr key={user.fullname}
                onClick={e => this.goTo(user.fullname, e)}>
                <td>{user.fullname}</td>
                <td><img className="user-icon mr-2" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${user.fullname}.png`} alt="User" /></td>
                <td>{user.email}</td>
                <td>{user.workingday} horas</td>
                <td>{this.getUserRest(user.rest)}</td>
                <td>{this.getUserType(user.type)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default UserList

