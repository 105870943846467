import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchUser } from '../actions/users'
import UploadImageToS3 from '../components/UploadImageToS3'

class UserDetailsView extends React.Component {
  componentDidMount() {
    this.props.fetchUser(this.props.match.params.fullname)
  }

  getUserRest(rest) {

    switch (rest) {
      case 30: return "30 minutos";
      case 60: return "1 hora";
      default: return "sin descanso";
    }
  }
  getUserType(type) {

    switch (type) {
      case "GOD": return "Administrador";
      case "ROOT": return "Editor";
      case "HHRR": return "Recursos Humanos";
      case "LIMITED": return "Trabajador";
      default: return "Sin rol";
    }
  }

  render() {
    const { user, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!user || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-dark">DETALLES DEL </span>
              <span className="text-vw-light">TRABAJADOR</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 text-right">
            <Link to={`/users/${user.fullname}/edit`}>
              <button className="btn bg-vw-light text-white mr-1">
                <i className="fas fa-edit mr-1"></i>Editar
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Box title="Foto" icon="user">
              <img className="user-image m-3" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${user.fullname}.png`} alt="User" />
              <UploadImageToS3 user={user.fullname}/>
            </Box>
          </div>
          <div className="col">
            <Box title="Datos personales" icon="user">
              <PropertyLabel name="Nombre"
                value={user.fullname} />
              <PropertyLabel name="Código Fichaje"
                value={user.shiftcode} />
              <PropertyLabel name="Jornada"
                value={user.workingday} />
              <PropertyLabel name="Descanso"
                value={this.getUserRest(user.rest)} />
            </Box>
          </div>
          <div className="col">
            <Box title="Acceso" icon="user">
              <PropertyLabel name="E-Mail"
                value={user.email} />
            </Box>
          </div>
          <div className="col">
            <Box title="Plataforma" icon="user">
              <PropertyLabel name="Tipo"
                value={this.getUserType(user.type)} />
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.users.user,
  loading: state.users.loading,
  error: state.users.error
})

const mapDispatchToProps = dispatch => ({
  fetchUser: bindActionCreators(fetchUser, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsView)
