import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchShiftsAdmin } from '../actions/shifts';
import { fetchUser } from '../actions/users';
import ErrorMessage from '../components/ErrorMessage'
import Box from '../components/Box'
import { QrReader } from 'react-qr-reader';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { postQRAdminShift } from '../actions/shifts'
import SlideToUnlock from '../components/SlideToUnlock'

class UserShiftsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedDay: null,
      legacyMode: true,
      result: 'Escaneando...',
      qrModalOpen: false,
      qrConfirmModalOpen: false,
      qrFullModalOpen: false,
      qrScanActivated: false
    };

    this.qrModalOpenToggle = this.qrModalOpenToggle.bind(this);
    this.qrConfirmModalOpenToggle = this.qrConfirmModalOpenToggle.bind(this);
    this.qrFullModalOpenToggle = this.qrFullModalOpenToggle.bind(this);
  }

  componentDidMount() {
    const { fullname } = this.props
    const startDate = new Date(new Date().getFullYear() - 1, 0, 1).toISOString().substring(0, 10);
    const endDate = new Date().toISOString().substring(0, 10);

    this.props.fetchShiftsAdmin(fullname, startDate, endDate);
    this.props.fetchUser(fullname);

  }

  qrModalOpenToggle() {
    this.setState({ qrModalOpen: !this.state.qrModalOpen })

    if(this.state.qrModalOpen) {
      this.setState({ qrScanActivated: true });
    }else{
      this.setState({ qrScanActivated: false });
    }
  }

  qrConfirmModalOpenToggle() {
    this.setState({ qrConfirmModalOpen: !this.state.qrConfirmModalOpen })
  }

  qrFullModalOpenToggle() {
    this.setState({ qrFullModalOpen: !this.state.qrFullModalOpen })
  }

  renderQRScan() {
    const { fullname, user } = this.props

    return (
      <div>
        <QrReader ref="qrReader1"
          onResult={(result, error) => {
            //this.qrModalOpenToggle();
            if (!!result && this.state.qrScanActivated) {
              this.setState({ result: result?.text, qrModalOpen: false, qrScanActivated: false });
              this.props.postQRAdminShift(result?.text, user.shiftcode, false)
                .then((answer) => {

                  let shiftAnswer = answer.response.shiftAnswer

                  if (shiftAnswer.firstShift) {
                    const startDate = new Date(new Date().getFullYear() - 1, 0, 1).toISOString().substring(0, 10);
                    const endDate = new Date().toISOString().substring(0, 10);

                    this.props.fetchShiftsAdmin(fullname, startDate, endDate);
                    this.props.fetchUser(fullname);
                    this.qrConfirmModalOpenToggle();
                  } else {
                    if (shiftAnswer.allshift) {
                      this.qrFullModalOpenToggle();
                    } else {
                      this.props.postQRAdminShift(result?.text, user.shiftcode, true)
                        .then((answer) => {
                          const startDate = new Date(new Date().getFullYear() - 1, 0, 1).toISOString().substring(0, 10);
                          const endDate = new Date().toISOString().substring(0, 10);

                          this.props.fetchShiftsAdmin(fullname, startDate, endDate);
                          this.props.fetchUser(fullname);
                          this.qrConfirmModalOpenToggle();

                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            }

            if (!!error) {
              console.info(error);
            }
          }}
          style={{ width: '100%' }}
          constraints={{ facingMode: 'environment' }}
        />
      </div>
    )

  }

  renderStartHour(name, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let initHour = "None";

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part
  
      return shift.workerName === name && shiftDay === day;
    });
  
    filteredShifts.forEach((shift) => {
      if (shift.workerName === name) {
        const shiftDate = new Date(shift.date);
        initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });
      }
    });
  
    return initHour;
  }

  renderFinishHour(name, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let finishHour = "None";
    let done = false;
    let counter = 0;

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return shift.workerName === name && (day ? shiftDay === day : true);
    });

    filteredShifts.forEach((shift) => {
      if (!done) {
        const shiftDate = new Date(shift.date);
        finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });
        done = true;
      }
    });

    filteredShifts.forEach((shift) => {
      counter++;
    });

    if (counter === 1) {
      finishHour = "None";
    }

    return finishHour;
  }

  renderTotalHour(name, rest, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let initHour = "None";
    let finishHour = "None";
    let done = false;
    let counter = 0;

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return shift.workerName === name && (day ? shiftDay === day : true);
    });

    filteredShifts.forEach((shift) => {
      const shiftDate = new Date(shift.date);
      initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });
    });

    filteredShifts.forEach((shift) => {
      if (!done) {
        const shiftDate = new Date(shift.date);
        finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });

        done = true;
      }
    });

    filteredShifts.forEach((shift) => {
      counter++;
    });

    if (counter === 1) {
      const shiftDate = new Date();
      finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });
    }

    let totalHours = `0h00m`;

    if (initHour !== "None" && finishHour !== "None") {
      // Parse the time strings into Date objects
      const initTime = new Date(`2000-01-01 ${initHour}`);
      const finishTime = new Date(`2000-01-01 ${finishHour}`);

      // Calculate the time difference in milliseconds
      let timeDiff = finishTime - initTime;

      // Subtract rest time if rest is provided
      if (rest && typeof rest === 'number') {
        timeDiff -= rest * 60 * 1000; // Convert rest time to milliseconds
      }

      // Convert the time difference to hours and minutes
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const totalMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

      // Ensure minutes are always represented by at least two digits
      const minutes = totalMinutes.toString().padStart(2, '0');

      totalHours = `${hours}h${minutes}m`;
    }

    return totalHours;
  }

  getCurrentMonthInfo() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-based index
    const currentYear = currentDate.getFullYear();

    // Get the first day of the next month to determine the last day of the current month
    const firstDayOfNextMonth = new Date(currentYear, currentMonth + 1, 1);
    const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth - 1);

    const numberOfDaysInMonth = lastDayOfCurrentMonth.getDate();

    // Determine the first day of the current week
    const firstDayOfCurrentWeek = new Date(currentDate);
    firstDayOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

    return {
      currentMonth: currentMonth + 1, // Adding 1 to convert to 1-based index
      currentYear: currentYear,
      numberOfDaysInMonth: numberOfDaysInMonth,
      firstDayOfCurrentWeek: firstDayOfCurrentWeek.getDate() // Returns the day of the month (1-31)
    };
  }

  renderTotalMonthHour(name, rest) {
    const { shifts, error, loading } = this.props;
    const { currentMonth, currentYear, numberOfDaysInMonth } = this.getCurrentMonthInfo();

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let totalMonthHours = 0;
    let totalHours = 0;

    for (let day = 1; day <= numberOfDaysInMonth; day++) {

      let initHour = "None";
      let finishHour = "None";
      let done = false;
      let counter = 0;

      const filteredShifts = shifts.filter(shift => {
        const shiftDate = new Date(shift.date);
        const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

        return shift.workerName === name && (day ? shiftDay === `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}` : true);
      });

      filteredShifts.forEach((shift) => {
        const shiftDate = new Date(shift.date);
        initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });
      }); 

      filteredShifts.forEach((shift) => {
        if (!done) {
          const shiftDate = new Date(shift.date);
          finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });

          done = true;
        }
      });

      filteredShifts.forEach((shift) => {
        counter++;
      });

      if (counter === 1) {
        finishHour = "None";
      }

      let timeDiff = 0;

      if (initHour !== "None" && finishHour !== "None") {
        // Parse the time strings into Date objects
        const initTime = new Date(`2000-01-01 ${initHour}`);
        const finishTime = new Date(`2000-01-01 ${finishHour}`);

        // Calculate the time difference in milliseconds
        timeDiff = finishTime - initTime;

        // Subtract rest time if rest is provided
        if (rest && typeof rest === 'number') {
          timeDiff -= rest * 60 * 1000; // Convert rest time to milliseconds
        }

      }

      totalHours += timeDiff
    }

    // Convert the time difference to hours and minutes
    const hours = Math.floor(totalHours / (1000 * 60 * 60));
    const totalMinutes = Math.floor((totalHours % (1000 * 60 * 60)) / (1000 * 60));

    // Ensure minutes are always represented by at least two digits
    const minutes = totalMinutes.toString().padStart(2, '0');

    totalMonthHours = `${hours}h${minutes}m`;

    return totalMonthHours;
  }

  renderTotalWeekHours(name, rest) {
    const { shifts, error, loading } = this.props;
    const { currentMonth, currentYear, numberOfDaysInMonth, firstDayOfCurrentWeek } = this.getCurrentMonthInfo();

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let totalMonthHours = 0;
    let totalHours = 0;

    for (let day = firstDayOfCurrentWeek; day <= numberOfDaysInMonth; day++) {

      let initHour = "None";
      let finishHour = "None";
      let done = false;
      let counter = 0;

      const filteredShifts = shifts.filter(shift => {
        const shiftDate = new Date(shift.date);
        const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

        return shift.workerName === name && (day ? shiftDay === `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}` : true);
      });

      filteredShifts.forEach((shift) => {
        const shiftDate = new Date(shift.date);
        initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });
      });

      filteredShifts.forEach((shift) => {
        if (!done) {
          const shiftDate = new Date(shift.date);
          finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });

          done = true;
        }
      });

      filteredShifts.forEach((shift) => {
        counter++;
      });

      if (counter === 1) {
        finishHour = "None";
      }

      let timeDiff = 0;

      if (initHour !== "None" && finishHour !== "None") {
        // Parse the time strings into Date objects
        const initTime = new Date(`2000-01-01 ${initHour}`);
        const finishTime = new Date(`2000-01-01 ${finishHour}`);

        // Calculate the time difference in milliseconds
        timeDiff = finishTime - initTime;

        // Subtract rest time if rest is provided
        if (rest && typeof rest === 'number') {
          timeDiff -= rest * 60 * 1000; // Convert rest time to milliseconds
        }

      }

      totalHours += timeDiff
    }

    // Convert the time difference to hours and minutes
    const hours = Math.floor(totalHours / (1000 * 60 * 60));
    const totalMinutes = Math.floor((totalHours % (1000 * 60 * 60)) / (1000 * 60));

    // Ensure minutes are always represented by at least two digits
    const minutes = totalMinutes.toString().padStart(2, '0');

    totalMonthHours = `${hours}h${minutes}m`;

    return totalMonthHours;
  }

  handleDayClick(year, month, day) {
    const clickedDay = new Date(year, month, day);

    if (
      this.state.selectedDay &&
      clickedDay.getTime() === this.state.selectedDay.getTime()
    ) {
      this.setState({ selectedDay: null }, () => {

      });
    } else {
      // If the clicked day is different, update selectedDay with the new value
      this.setState({ selectedDay: clickedDay }, () => {

      });
    }
  }

  renderRegisterDaysShifts() {
    const { shifts, error, loading, auth, fullname, user } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    const shiftsByYearMonth = {};

    shifts.forEach(shift => {
      const originalDate = new Date(shift.date);

      // Extract date components
      const year = originalDate.getUTCFullYear();
      const month = originalDate.getUTCMonth();
      const day = originalDate.getUTCDate();

      shiftsByYearMonth[year] = shiftsByYearMonth[year] || {};
      shiftsByYearMonth[year][month] = shiftsByYearMonth[year][month] || [];

      if (!shiftsByYearMonth[year][month].includes(day)) {
        shiftsByYearMonth[year][month].push(day);
      }

    });

    const years = Object.keys(shiftsByYearMonth).sort((a, b) => parseInt(b) - parseInt(a));

    return (
      <div className="calendar-container p-3">
        {years.map(year => (
          <div key={year} className="calendar-year">
            <h2>{year}</h2>
            {Array.from({ length: 12 }, (_, month) => {
              const monthDays = new Date(year, month + 1, 0).getDate();
              const shiftDays = shiftsByYearMonth[year] && shiftsByYearMonth[year][month] ? shiftsByYearMonth[year][month] : [];

              // Calculate the first day of the month and the number of days in the week
              const firstDay = new Date(year, month, 1).getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
              const daysInWeek = 7;

              return (
                <div key={month} className="calendar-month">
                  <h3>{new Date(year, month, 1).toLocaleString('default', { month: 'long' })}</h3>
                  <div className="calendar-week">
                    {['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'].map((day, index) => (
                      <div key={day} className="calendar-weekday">{day}</div>
                    ))}
                  </div>
                  <div className="calendar-days">
                    {Array.from({ length: firstDay === 0 ? daysInWeek - 1 : firstDay - 1 }, (_, index) => (
                      <div key={`spacer-${index}`} className="calendar-spacer">n</div>
                    ))}
                    {Array.from({ length: monthDays }, (_, day) => {
                      const dayNumber = day + 1; // Add 1 to get the day number
                      const currentDay = new Date(year, month, dayNumber);
                      const dayOfWeek = currentDay.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

                      return (
                        <div
                          key={day}
                          className={`calendar-day ${(this.renderStartHour(fullname, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`) !== "None" && this.renderFinishHour(fullname, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`) === "None") ? 'mid-shift' : (shiftDays.includes(dayNumber) ? 'complete-shift' : 'no-shift')}`}
                          onClick={() => this.handleDayClick(year, month, dayNumber)}
                          style={{ position: 'relative' }}
                        >
                          {dayNumber}
                          {(this.state.selectedDay && this.state.selectedDay.toDateString() == currentDay.toDateString()) && (
                            <div key={day} className="floating-message">
                              <p>{`${this.state.selectedDay.toLocaleDateString()}`}</p>
                              <span className="text-vw-dark">Entrada: {this.renderStartHour(fullname, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`)}</span><br></br>
                              <span className="text-vw-dark">Salida: {this.renderFinishHour(fullname, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`)}</span><br></br>
                              <span className="text-vw-dark">Total Diario: {this.renderTotalHour(fullname, user.rest, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`)}</span><br></br>
                              <button className="btn bg-vw-light text-white mt-3">
                                <i className="fas fa-times mr-1"></i>Cerrar
                              </button>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  getUserRest(rest) {

    switch (rest) {
      case 30: return "30 min";
      case 60: return "1 hora";
      default: return "No";
    }
  }

  getUserType(type) {

    switch (type) {
      case "GOD": return "Admin";
      case "ROOT": return "Editor";
      case "HHRR": return "RRHH";
      case "LIMITED": return "Trabajador";
      default: return "Sin rol";
    }
  }

  render() {
    const { fullname, user } = this.props

    if (!user) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let today = new Date();
    today = today.toISOString().split('T')[0];

    let date = new Date();

    return (
      <div>
        <div className="row mb-5 justify-content-between mx-auto">
          <div className="col-xs-12 col-sm-4 col-md-4 p-3 rounded box-shadow">
            <h1>
              <span className="text-vw-light">{fullname}</span>
            </h1>
            <div className="row justify-content-center text-center">
              <img className="user-image m-3" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${fullname}.png`} alt="User" />
              <div className="m-3 p-3">
                <h2>
                  <span className="text-vw-dark">Jornada: </span>
                  <span className="text-vw-light">{user.workingday}h</span>
                </h2>
                <h2>
                  <span className="text-vw-dark">Descanso: </span>
                  <span className="text-vw-light">{this.getUserRest(user.rest)}</span>
                </h2>
                <br></br>
                {this.renderStartHour(fullname, today) === "None" ?
                  (<span className="text-vw-dark">Desliza para fichar la entrada</span>) : (this.renderFinishHour(fullname, today) === "None" ? <span className="text-vw-dark">Desliza para fichar la salida</span> : <span className="text-vw-dark">Fichaje del día completo</span>)}
                <SlideToUnlock onUnlock={this.qrModalOpenToggle} />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 p-3 bg-vw-dark rounded box-shadow">
            <h1>
              <span className="text-white">REGISTRO HORARIO</span>
            </h1>
            <div className="row">
              <div className="col">
                <h2>
                  <span className="text-white">Fecha: </span>
                  <span className="text-vw-light">{date.toLocaleDateString('es-ES', { timeZone: 'Europe/Madrid' })}</span>
                </h2>
                <h2>
                  <span className="text-white">Entrada: </span>
                  <span className="text-vw-light">{this.renderStartHour(fullname, today)}</span>
                </h2>
                <h2>
                  <span className="text-white">Salida: </span>
                  <span className="text-vw-light">{this.renderFinishHour(fullname, today)}</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 p-3 bg-vw-light rounded box-shadow">
            <h1>
              <span className="text-vw-dark">CÓMPUTO TOTAL</span>
            </h1>
            <div className="row">
              <div className="col">
                <h2>
                  <span className="text-vw-dark">Total Diario: </span>
                  <span className="text-white">{this.renderTotalHour(fullname, user.rest, today)}</span>
                </h2>
                <h2>
                  <span className="text-vw-dark">Total Semanal: </span>
                  <span className="text-white">{this.renderTotalWeekHours(fullname, user.rest)}</span>
                </h2>
                <h2>
                  <span className="text-vw-dark">Total Mensual: </span>
                  <span className="text-white">{this.renderTotalMonthHour(fullname, user.rest)}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 mt-4 justify-content-center">
          <div className="col-xs-12 col-sm-6 col-md-6 text-center">
            <h1>
              <span className="text-vw-dark">CALENDARIO</span>
            </h1>
            <div className="row mb-2 mt-2 justify-content-center">
              <div className={`complete-shift mr-2 p-3`}>Fichaje Correcto</div>
              <div className={`mid-shift mr-2 p-3`}>Fichaje Incompleto</div>
              <div className={`no-shift mr-2 p-3`}>Sin Fichaje</div>
              <div className={`holiday-shift p-3`}>Vacaciones</div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          {this.renderRegisterDaysShifts()}
        </div>
        {/* QR Modal */}
        <div>
          <Modal isOpen={this.state.qrModalOpen}
            toggle={this.qrModalOpenToggle}
            className="centered-modal">
            <ModalHeader toggle={this.qrModalOpenToggle}>
              Escaneando QR...
            </ModalHeader>
            <ModalBody>
              {this.renderQRScan()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.qrModalOpenToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* Shift Confirm Modal */}
        <div>
          <Modal isOpen={this.state.qrConfirmModalOpen}
            toggle={this.qrConfirmModalOpenToggle}
            className="centered-modal">
            <ModalHeader toggle={this.qrConfirmModalOpenToggle}>
              {fullname}
            </ModalHeader>
            <ModalBody>
              Fichaje correcto!
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.qrConfirmModalOpenToggle}>
                Salir
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* Shift Full Modal */}
        <div>
          <Modal isOpen={this.state.qrFullModalOpen}
            toggle={this.qrFullModalOpenToggle}
            className="centered-modal">
            <ModalHeader toggle={this.qrFullModalOpenToggle}>
              {fullname}
            </ModalHeader>
            <ModalBody>
              Hoy ya has fichado la entrada y la salida!
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.qrFullModalOpenToggle}>
                Salir
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shifts: state.shifts.shifts,
  user: state.users.user,
  fullname: state.auth.fullname,
});

const mapDispatchToProps = dispatch => ({
  fetchShiftsAdmin: bindActionCreators(fetchShiftsAdmin, dispatch),
  fetchUser: bindActionCreators(fetchUser, dispatch),
  postQRAdminShift: bindActionCreators(postQRAdminShift, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserShiftsPage);
