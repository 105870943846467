import {
  PROMOTERS_REQUEST, PROMOTERS_SUCCESS, PROMOTERS_FAILURE,
  PROMOTER_REQUEST, PROMOTER_SUCCESS, PROMOTER_FAILURE,
  PROMOTER_ADD_REQUEST, PROMOTER_ADD_SUCCESS, PROMOTER_ADD_FAILURE,
  PROMOTER_UPDATE_REQUEST, PROMOTER_UPDATE_SUCCESS, PROMOTER_UPDATE_FAILURE,
  PROMOTER_IMAGE_UPDATE_REQUEST, PROMOTER_IMAGE_UPDATE_SUCCESS, PROMOTER_IMAGE_UPDATE_FAILURE,
  UPDATE_FILTERED_PROMOTERS, UPDATE_SHIFTDAYS_PROMOTERS

} from '../actions/promoters'

const INITIAL_STATE = {
  promoters: null, promoter: null, error: null, loading: false, filteredPromoters: [], shiftdaysPromoters: []
}

const promotersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROMOTERS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PROMOTERS_SUCCESS:
      return {
        ...state,
        promoters: action.response, error: null, loading: false
      }
    case PROMOTERS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PROMOTER_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PROMOTER_SUCCESS:
      return {
        ...state,
        promoter: action.response, error: null, loading: false
      }
    case PROMOTER_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PROMOTER_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PROMOTER_ADD_SUCCESS:
      return {
        ...state,
        promoter: action.response, error: null, loading: false
      }
    case PROMOTER_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PROMOTER_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PROMOTER_UPDATE_SUCCESS:
      return {
        ...state,
        promoter: action.response, error: null, loading: false
      }
    case PROMOTER_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PROMOTER_IMAGE_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PROMOTER_IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        promoter: action.response, error: null, loading: false
      }
    case PROMOTER_IMAGE_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case UPDATE_FILTERED_PROMOTERS:
      return {
        ...state,
        filteredPromoters: action.payload,
      }
    case UPDATE_SHIFTDAYS_PROMOTERS:
      return {
        ...state,
        shiftdaysPromoters: action.payload,
      };
    default:
      return state
  }
}

export default promotersReducer

