import React from 'react'
import { history } from '../store'
import * as dateFormat from 'dateformat'

import IncidenceStatusBadge from './IncidenceStatusBadge'

class IncidenceOpenList extends React.Component {
  goTo(number, e) {
    history.push(`/incidences/${number}`)
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fecha</th>
              <th scope="col">Máquina</th>
              <th scope="col">Cliente</th>
              <th scope="col">Descripción</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            {this.props.incidences.map(incidence => {
              const date = (new Date(incidence.createdAt)).toLocaleString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })

              if (incidence.Status == "OPEN") {
                return (
                    <tr key={incidence.number}
                      onClick={e => this.goTo(incidence.number, e)}>
                      <th scope="row">{incidence.number}</th>
                      <td>{date}</td>
                      <td>{incidence.Machine.Serial}</td>
                      <td>{incidence.Customer.Fullname}</td>
                      <td>{incidence.Description}</td>
                      <td>
                        <IncidenceStatusBadge status={incidence.Status} />
                      </td>
                    </tr>
                )
              }
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default IncidenceOpenList

