import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { fetchCustomer, updateCustomer } from '../actions/customers'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

class CustomerEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }
    this.submit = this.submit.bind(this);
    this.renderItems = this.renderItems.bind(this);
  }

  componentDidMount() {
    const { customers, fetchCustomer, match } = this.props

    if (!customers.customer) {
      fetchCustomer(match.params.number)
    } else {
      this.setState({ loaded: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loaded && nextProps.customers.customer) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    const { updateCustomer, customers } = this.props

    props.Executor = this.props.fullname

    return updateCustomer(customers.customer.number, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  renderItems(props) {
    const { fields } = props;

    return (
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Artículo</th>
                <th scope="col">Comisión Cliente (%)</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((member, index) => (
                <tr key={index}>
                  <td>
                    <Field
                      name={`${member}.item`}
                      component={renderSelectField}
                    >
                      <option value="">Selecciona un artículo...</option>
                      <option value="VWPACK" key="VWPACK">VWPACK</option>
                      <option value="VWLAY" key="VWLAY">VWLAY</option>
                      <option value="VWGO" key="VWGO">VWGO</option>
                      <option value="VWCHARGER" key="VWCHARGER">VWCHARGER</option>
                    </Field>
                  </td>
                  <td>
                    <Field
                      name={`${member}.comission`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-danger mr-1"
                      type="button"
                      onClick={() => fields.remove(index)}
                    >
                      <i className="fas fa-trash mr-1"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          <button
            className="btn bg-vw-light text-white"
            type="button"
            onClick={() => fields.push({})}
          >
            <i className="fas fa-plus mr-1"></i>
            Añadir
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { customers, error, pristine, submitting, handleSubmit } = this.props

    if (!this.state.loaded) {
      if (customers.error) {
        return (
          <ErrorMessage message={customers.error.message} />
        )
      }

      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">EDITAR </span>
          <span className="text-vw-light">CLIENTE</span>
        </h1>
        <div className="row">
          <div className="col">
            <Box title="Cliente" icon="user">
              <Field name="Fullname"
                type="text"
                component={renderInputField}
                label="Nombre completo" />
              <Field name="Category"
                component={renderSelectField}
                label="Categoria">
                <option value="">Selecciona una categoria...</option>
                <option value="BIGPLAYER">Big player</option>
                <option value="AWATERPARK">A Waterpark</option>
                <option value="BWATERPARK">B Waterpark</option>
                <option value="CWATERPARK">C Waterpark</option>
              </Field>
              <Field name="Contact_person"
                type="text"
                component={renderInputField}
                label="Persona de contacto" />
              <Field name="Email"
                type="text"
                component={renderInputField}
                label="E-mail" />
              <Field name="Password"
                type="password"
                component={renderInputField}
                label="Password" />
              <Field name="Address"
                type="text"
                component={renderTextAreaField}
                label="Dirección" />
              <Field name="Phone"
                type="text"
                component={renderInputField}
                label="Teléfono 1" />
            </Box>
            <Box title="Modelo de Negocio" icon="briefcase">
              <Field name="BusinessModel"
                component={renderSelectField}
                label="Modelo">
                <option value="">Selecciona un modelo...</option>
                <option value="REVENUE SHARE">REVENUE SHARE</option>
                <option value="SPACE RENTAL">SPACE RENTAL</option>
              </Field>
              <Field name="SpaceRentalAlquiler"
                component={renderInputField}
                type="text"
                label="Alquiler Anual" />
            </Box>
          </div>
          <div className="col">
            <Box title="Cliente" icon="user">
              <Field name="Phone2"
                type="text"
                component={renderInputField}
                label="Teléfono 2" />
              <Field name="Fax"
                type="text"
                component={renderInputField}
                label="Fax" />
              <Field name="Web"
                type="text"
                component={renderInputField}
                label="Web" />
              <Field name="Language"
                type="text"
                component={renderInputField}
                label="Idioma" />
              <Field name="Notes"
                type="text"
                component={renderTextAreaField}
                label="Notas" />
              <Field name="Country"
                component={renderSelectField}
                label="País">
                <option value="">Selecciona un país...</option>
                <option value="España">España</option>
                <option value="Francia">Francia</option>
                <option value="Italia">Italia</option>
                <option value="Chequia">Chequia</option>
              </Field>
              <Field name="PostalCode"
                type="text"
                component={renderInputField}
                label="Código Postal" />
              <Field name="City"
                type="text"
                component={renderInputField}
                label="Ciudad" />
              <Field name="Province"
                type="text"
                component={renderInputField}
                label="Provincia" />
            </Box>
            <Box title="SAP" icon="file-alt">
              <Field
                name="SAP.Model"
                component={renderSelectField}
                label="Modelo">
                <option value="">Selecciona un modelo...</option>
                <option value="VC">VENTAS CONTADO (Punto de Venta)</option>
                <option value="CV">COMISION VENTA (Solicitud Factura)</option>
              </Field>
              <Field
                name="SAP.CompanyID"
                component={renderSelectField}
                label="CompanyID">
                <option value="">Selecciona una empresa...</option>
                <option value="ES1000">ES1000</option>
                <option value="IT1000">IT1000</option>
              </Field>
              <Field
                name="SAP.CountryCode"
                component={renderSelectField}
                label="CountryCode">
                <option value="">Selecciona un código de país...</option>
                <option value="ES">ESPAÑA</option>
                <option value="IT">ITALIA</option>
                <option value="EU">EUROPA</option>
              </Field>
              <Field name="SAP.ClientID"
                type="text"
                component={renderInputField}
                label="ClientID" />
              <Field
                name="SAP.SiteID"
                type="text"
                component={renderInputField}
                label="SiteID" />
              <Field
                name="SAP.TaxCountryCode"
                type="text"
                component={renderInputField}
                label="TaxCountryCode" />
              <Field
                name="SAP.TaxationCharacteristicsCode"
                type="text"
                component={renderInputField}
                label="TaxationCharacteristicsCode" />
              <Field
                name="SAP.InventoryManagedLocationID"
                type="text"
                component={renderInputField}
                label="InventoryManagedLocationID" />
              <Field
                name="SAP.LogisticsAreaID"
                type="text"
                component={renderInputField}
                label="LogisticsAreaID" />
              <Field
                name="SAP.TaxRegionCode"
                type="text"
                component={renderInputField}
                label="TaxRegionCode" />
            </Box>
          </div>
          <div className="col">
            <Box title="Facturación" icon="file-alt">
              <Field name="Invoice_taxid"
                type="text"
                component={renderInputField}
                label="NIF/CIF" />
              <Field name="Invoice_tax"
                type="text"
                component={renderInputField}
                label="IVA aplicable (%)" />
              <Field name="Social_name"
                type="text"
                component={renderInputField}
                label="Razón social" />
              <Field name="Invoice_address"
                type="text"
                component={renderTextAreaField}
                label="Dirección de facturación" />
            </Box>
            <Box title="Artículos" icon="shopping-cart">
              <FieldArray name="Items" component={this.renderItems} />
            </Box>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

CustomerEditForm = reduxForm({
  form: 'CustomerEditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/customers/${result.response.number}`)
  }
})(CustomerEditForm)

const mapStateToProps = state => ({
  customers: state.customers,
  initialValues: state.customers.customer,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch),
  updateCustomer: bindActionCreators(updateCustomer, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEditForm)


