import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { renderInputField } from '../utils/forms'

class PasswordRecoveryForm extends React.Component {
  render() {
    const { error, handleSubmit, pristine, submitting } = this.props

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="email"
          component={renderInputField}
          type="email"
          placeholder="Introduce tu email"
          label="E-mail"/>
      </div>
      <button className="btn bg-vw-light text-white mr-1 mb-1" type="submit"
                disabled={pristine || submitting}>
          Recuperar contraseña
        </button>
        { error && <div className="alert alert-danger">{error}</div> }
    </form>
   )
  }
}

PasswordRecoveryForm = reduxForm({
  form: 'PasswordRecoveryForm',
  enableReinitialize: true
})(PasswordRecoveryForm)

export default PasswordRecoveryForm