import {
  OBJECTIVES_REQUEST, OBJECTIVES_SUCCESS, OBJECTIVES_FAILURE,
  OBJECTIVE_REQUEST, OBJECTIVE_SUCCESS, OBJECTIVE_FAILURE,
  OBJECTIVE_ADD_REQUEST, OBJECTIVE_ADD_SUCCESS, OBJECTIVE_ADD_FAILURE,
  OBJECTIVE_UPDATE_REQUEST, OBJECTIVE_UPDATE_SUCCESS, OBJECTIVE_UPDATE_FAILURE

} from '../actions/objectives'

const INITIAL_STATE = {
  objectives: null, objectivesNeedRefresh: false, objective: null,
  error: null, loading: false
}

const objectivesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case OBJECTIVES_REQUEST:
        return {
          ...state,
          objectives: null, error: null, loading: true
        }
      case OBJECTIVES_SUCCESS:
        return {
          ...state,
          objectives: action.response, error: null, loading: false
        }
      case OBJECTIVES_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case OBJECTIVE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case OBJECTIVE_SUCCESS:
        return {
          ...state,
          objective: action.response, error: null, loading: false
        }
      case OBJECTIVE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case OBJECTIVE_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case OBJECTIVE_ADD_SUCCESS:
        return {
          ...state,
          objectivesNeedRefresh: true, objective: action.response, error: null,
          loading: false
        }
      case OBJECTIVE_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case OBJECTIVE_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case OBJECTIVE_UPDATE_SUCCESS:
        return {
          ...state,
          objectivesNeedRefresh: true, objective: action.response, error: null,
          loading: false
        }
      case OBJECTIVE_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      default:
        return state
    }
  }

export default objectivesReducer
