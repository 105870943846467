import { CALL_API } from '../middleware/api'

export const PROMOTERSALES_REQUEST = 'PROMOTERSALES_REQUEST'
export const PROMOTERSALES_SUCCESS = 'PROMOTERSALES_SUCCESS'
export const PROMOTERSALES_FAILURE = 'PROMOTERSALES_FAILURE'

export function fetchPromoterSaleStatistics(machine, StartDate, EndDate) {
  let params = ''

  if (machine !== undefined) {
    params += `&machine=${machine}`
  }

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `promotersales?${params}`,
      authenticated: true,
      types: [PROMOTERSALES_REQUEST, PROMOTERSALES_SUCCESS, PROMOTERSALES_FAILURE]
    }
  }
}
