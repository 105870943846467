import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchShifts } from '../actions/shifts';
import { fetchPromoter } from '../actions/promoters'
import { fetchObjective } from '../actions/objectives'
import { fetchArqueos } from '../actions/arqueos'

import ErrorMessage from '../components/ErrorMessage'
import Box from '../components/Box'

class PromoterShiftView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedDay: null,
    };

  }

  componentDidMount() {
    const { fullname } = this.props
    const startDate = new Date(new Date().getFullYear() - 1, 0, 1).toISOString().substring(0, 10);
    const endDate = new Date().toISOString().substring(0, 10);

    this.props.fetchPromoter(this.props.match.params.Name)
    this.props.fetchShifts(this.props.match.params.Name, '', startDate, endDate)
      .then((answer) => {

        let shiftCustomer = ''
        const today = new Date();
        const currentYear = today.getFullYear().toString();

        if (answer.response.length > 0) {
          shiftCustomer = answer.response[0].machine.Customer;
        }

        this.props.fetchObjective(shiftCustomer, currentYear)
          .then((answer) => {
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth();

            // Start date is the first day of the current month
            const startDate = new Date(currentYear, currentMonth, 1);
            const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;

            // End date is tomorrow
            const endDate = new Date(today);
            endDate.setDate(today.getDate() + 1);
            const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;

            this.props.fetchArqueos('', shiftCustomer, formattedStartDate, formattedEndDate, '');
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  calculateTotalKPILay() {
    const { arqueos } = this.props

    if (!arqueos) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let kpiLay = 0;
    let totalPack = 0;
    let totalLay = 0;

    {
      this.props.arqueos.map((arqueo) => {
        totalLay += arqueo.Items.VWLAY
        totalPack += arqueo.Items.VWPACK
      }
      )
    }

    kpiLay = (totalLay / totalPack) * 100

    return kpiLay
  }

  calculateTotalKPIGo() {
    const { arqueos } = this.props

    if (!arqueos) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let kpiGo = 0;
    let totalPack = 0;
    let totalGo = 0;

    {
      this.props.arqueos.map((arqueo) => {
        totalGo += arqueo.Items.VWGO
        totalPack += arqueo.Items.VWPACK
      }
      )
    }

    kpiGo = (totalGo / totalPack) * 100

    return kpiGo
  }

  calculateTotalCrossSale() {
    const { arqueos } = this.props

    if (!arqueos) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let kpiCross = 0;
    let totalPack = 0;
    let totalLay = 0;
    let totalGo = 0;

    {
      this.props.arqueos.map((arqueo) => {
        totalLay += arqueo.Items.VWLAY
        totalGo += arqueo.Items.VWGO
        totalPack += arqueo.Items.VWPACK
      }
      )
    }

    kpiCross = ((totalGo + totalLay) / totalPack) * 100

    return kpiCross
  }

  progressBar(actual, ob) {
    const { arqueos } = this.props

    if (!arqueos) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let percentage = Math.min((actual / ob) * 100, 100);

    if(ob === 0){
      percentage = 0;
    }

    let color = "bg-warning"

    if (percentage < 50) {
      color = "bg-danger"
    } else if (percentage === 100) {
      color = "bg-success"
    }

    return (
      <div className="progress-container-promoter">
        <div className={`progress-bar-promoter ${color}`} style={{ width: `${percentage}%` }}></div>
        <div className="progress-percentage-promoter">{percentage.toFixed(2)}%</div>
      </div>
    );
  }

  calculateTotalMonthObjective() {
    const { currentMonth } = this.getCurrentMonthInfo();
    const weeks = ['W1', 'W2', 'W3', 'W4', 'W5', 'W6'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    let totalObjectives = 0;

    if (this.props.objective && currentMonth >= 1 && currentMonth <= 12) {
      weeks.forEach(week => {
        if (this.props.objective[months[currentMonth - 1]] && this.props.objective[months[currentMonth - 1]][week]) {
          totalObjectives += this.props.objective[months[currentMonth - 1]][week];
        }
      });
    } else {
      console.error("Error: Missing or invalid objective data or current month.");
    }

    return totalObjectives;
  }

  calculatePriceWithoutTax(priceWithTax, taxRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    // Calculate price without tax and round to two decimal places
    const priceWithoutTax = (priceWithTax / taxMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTax);
  }

  calculateTotalMonthReal() {

    let totalReal = 0;

    {
      this.props.arqueos.map((arqueo) => {
        totalReal += this.calculatePriceWithoutTax(arqueo.SalesTotal, arqueo.Tax)
      }
      )
    }

    return totalReal
  }

  renderStartHour(name, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let initHour = "None";

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return shift.workerName === name && shiftDay === day;
    });

    filteredShifts.forEach((shift) => {
      if (shift.workerName === name) {
        const shiftDate = new Date(shift.date);
        initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
      }
    });

    return initHour;
  }

  renderFinishHour(name, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let finishHour = "None";
    let done = false;
    let counter = 0;

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return shift.workerName === name && (day ? shiftDay === day : true);
    });

    filteredShifts.forEach((shift) => {
      if (!done) {
        const shiftDate = new Date(shift.date);
        finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
        done = true;
      }
    });

    filteredShifts.forEach((shift) => {
      counter++;
    });

    if (counter === 1) {
      finishHour = "None";
    }

    return finishHour;
  }

  renderTotalHour(name, rest, day) {
    const { shifts, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let initHour = "None";
    let finishHour = "None";
    let done = false;
    let counter = 0;

    const filteredShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

      return shift.workerName === name && (day ? shiftDay === day : true);
    });

    filteredShifts.forEach((shift) => {
      const shiftDate = new Date(shift.date);
      initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
    });

    filteredShifts.forEach((shift) => {
      if (!done) {
        const shiftDate = new Date(shift.date);
        finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });

        done = true;
      }
    });

    filteredShifts.forEach((shift) => {
      counter++;
    });

    if (counter === 1) {
      const shiftDate = new Date();
      finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
    }

    let totalHours = `0h00m`;

    if (initHour !== "None" && finishHour !== "None") {
      // Parse the time strings into Date objects
      const initTime = new Date(`2000-01-01 ${initHour}`);
      const finishTime = new Date(`2000-01-01 ${finishHour}`);

      // Calculate the time difference in milliseconds
      let timeDiff = finishTime - initTime;

      // Subtract rest time if rest is provided
      if (rest && typeof rest === 'number') {
        timeDiff -= rest * 60 * 1000; // Convert rest time to milliseconds
      }

      // Convert the time difference to hours and minutes
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const totalMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

      // Ensure minutes are always represented by at least two digits
      const minutes = totalMinutes.toString().padStart(2, '0');

      totalHours = `${hours}h${minutes}m`;
    }

    return totalHours;
  }

  getCurrentMonthInfo() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-based index
    const currentYear = currentDate.getFullYear();

    // Get the first day of the next month to determine the last day of the current month
    const firstDayOfNextMonth = new Date(currentYear, currentMonth + 1, 1);
    const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth - 1);

    const numberOfDaysInMonth = lastDayOfCurrentMonth.getDate();

    // Determine the first day of the current week
    const firstDayOfCurrentWeek = new Date(currentDate);
    firstDayOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

    return {
      currentMonth: currentMonth + 1, // Adding 1 to convert to 1-based index
      currentYear: currentYear,
      numberOfDaysInMonth: numberOfDaysInMonth,
      firstDayOfCurrentWeek: firstDayOfCurrentWeek.getDate() // Returns the day of the month (1-31)
    };
  }

  renderTotalMonthHour(name, rest) {
    const { shifts, error, loading } = this.props;
    const { currentMonth, currentYear, numberOfDaysInMonth } = this.getCurrentMonthInfo();

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let totalMonthHours = 0;
    let totalHours = 0;

    for (let day = 1; day <= numberOfDaysInMonth; day++) {

      let initHour = "None";
      let finishHour = "None";
      let done = false;
      let counter = 0;

      const filteredShifts = shifts.filter(shift => {
        const shiftDate = new Date(shift.date);
        const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

        return shift.workerName === name && (day ? shiftDay === `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}` : true);
      });

      filteredShifts.forEach((shift) => {
        const shiftDate = new Date(shift.date);
        initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
      });

      filteredShifts.forEach((shift) => {
        if (!done) {
          const shiftDate = new Date(shift.date);
          finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });

          done = true;
        }
      });

      filteredShifts.forEach((shift) => {
        counter++;
      });

      if (counter === 1) {
        finishHour = "None";
      }

      let timeDiff = 0;

      if (initHour !== "None" && finishHour !== "None") {
        // Parse the time strings into Date objects
        const initTime = new Date(`2000-01-01 ${initHour}`);
        const finishTime = new Date(`2000-01-01 ${finishHour}`);

        // Calculate the time difference in milliseconds
        timeDiff = finishTime - initTime;

        // Subtract rest time if rest is provided
        if (rest && typeof rest === 'number') {
          timeDiff -= rest * 60 * 1000; // Convert rest time to milliseconds
        }

      }

      totalHours += timeDiff
    }

    // Convert the time difference to hours and minutes
    const hours = Math.floor(totalHours / (1000 * 60 * 60));
    const totalMinutes = Math.floor((totalHours % (1000 * 60 * 60)) / (1000 * 60));

    // Ensure minutes are always represented by at least two digits
    const minutes = totalMinutes.toString().padStart(2, '0');

    totalMonthHours = `${hours}h${minutes}m`;

    return totalMonthHours;
  }

  renderTotalWeekHours(name, rest) {
    const { shifts, error, loading } = this.props;
    const { currentMonth, currentYear, numberOfDaysInMonth, firstDayOfCurrentWeek } = this.getCurrentMonthInfo();

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    let totalMonthHours = 0;
    let totalHours = 0;

    for (let day = firstDayOfCurrentWeek; day <= numberOfDaysInMonth; day++) {

      let initHour = "None";
      let finishHour = "None";
      let done = false;
      let counter = 0;

      const filteredShifts = shifts.filter(shift => {
        const shiftDate = new Date(shift.date);
        const shiftDay = shiftDate.toISOString().split('T')[0]; // Extracting the date part

        return shift.workerName === name && (day ? shiftDay === `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}` : true);
      });

      filteredShifts.forEach((shift) => {
        const shiftDate = new Date(shift.date);
        initHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });
      });

      filteredShifts.forEach((shift) => {
        if (!done) {
          const shiftDate = new Date(shift.date);
          finishHour = shiftDate.toLocaleTimeString('es-ES', { timeZone: 'UTC', hour12: false });

          done = true;
        }
      });

      filteredShifts.forEach((shift) => {
        counter++;
      });

      if (counter === 1) {
        finishHour = "None";
      }

      let timeDiff = 0;

      if (initHour !== "None" && finishHour !== "None") {
        // Parse the time strings into Date objects
        const initTime = new Date(`2000-01-01 ${initHour}`);
        const finishTime = new Date(`2000-01-01 ${finishHour}`);

        // Calculate the time difference in milliseconds
        timeDiff = finishTime - initTime;

        // Subtract rest time if rest is provided
        if (rest && typeof rest === 'number') {
          timeDiff -= rest * 60 * 1000; // Convert rest time to milliseconds
        }

      }

      totalHours += timeDiff
    }

    // Convert the time difference to hours and minutes
    const hours = Math.floor(totalHours / (1000 * 60 * 60));
    const totalMinutes = Math.floor((totalHours % (1000 * 60 * 60)) / (1000 * 60));

    // Ensure minutes are always represented by at least two digits
    const minutes = totalMinutes.toString().padStart(2, '0');

    totalMonthHours = `${hours}h${minutes}m`;

    return totalMonthHours;
  }

  handleDayClick(year, month, day) {
    const clickedDay = new Date(year, month, day);

    if (
      this.state.selectedDay &&
      clickedDay.getTime() === this.state.selectedDay.getTime()
    ) {
      this.setState({ selectedDay: null }, () => {

      });
    } else {
      // If the clicked day is different, update selectedDay with the new value
      this.setState({ selectedDay: clickedDay }, () => {

      });
    }
  }

  renderRegisterDaysShifts() {
    const { shifts, error, loading, auth, fullname, promoter } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!shifts) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    const shiftsByYearMonth = {};

    shifts.forEach(shift => {
      const originalDate = new Date(shift.date);

      // Extract date components
      const year = originalDate.getUTCFullYear();
      const month = originalDate.getUTCMonth();
      const day = originalDate.getUTCDate();

      shiftsByYearMonth[year] = shiftsByYearMonth[year] || {};
      shiftsByYearMonth[year][month] = shiftsByYearMonth[year][month] || [];

      if (!shiftsByYearMonth[year][month].includes(day)) {
        shiftsByYearMonth[year][month].push(day);
      }

    });

    const years = Object.keys(shiftsByYearMonth).sort((a, b) => parseInt(b) - parseInt(a));

    return (
      <div className="calendar-container p-3">
        {years.map(year => (
          <div key={year} className="calendar-year">
            <h2>{year}</h2>
            {Array.from({ length: 12 }, (_, month) => {
              const monthDays = new Date(year, month + 1, 0).getDate();
              const shiftDays = shiftsByYearMonth[year] && shiftsByYearMonth[year][month] ? shiftsByYearMonth[year][month] : [];

              // Calculate the first day of the month and the number of days in the week
              const firstDay = new Date(year, month, 1).getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
              const daysInWeek = 7;

              return (
                <div key={month} className="calendar-month">
                  <h3>{new Date(year, month, 1).toLocaleString('default', { month: 'long' })}</h3>
                  <div className="calendar-week">
                    {['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'].map((day, index) => (
                      <div key={day} className="calendar-weekday">{day}</div>
                    ))}
                  </div>
                  <div className="calendar-days">
                    {Array.from({ length: firstDay === 0 ? daysInWeek - 1 : firstDay - 1 }, (_, index) => (
                      <div key={`spacer-${index}`} className="calendar-spacer">n</div>
                    ))}
                    {Array.from({ length: monthDays }, (_, day) => {
                      const dayNumber = day + 1; // Add 1 to get the day number
                      const currentDay = new Date(year, month, dayNumber);
                      const dayOfWeek = currentDay.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

                      return (
                        <div
                          key={day}
                          className={`calendar-day ${(this.renderStartHour(this.props.match.params.Name, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`) !== "None" && this.renderFinishHour(this.props.match.params.Name, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`) === "None") ? 'mid-shift' : (shiftDays.includes(dayNumber) ? 'complete-shift' : 'no-shift')}`}
                          onClick={() => this.handleDayClick(year, month, dayNumber)}
                          style={{ position: 'relative' }}
                        >
                          {dayNumber}
                          {(this.state.selectedDay && this.state.selectedDay.toDateString() == currentDay.toDateString()) && (
                            <div key={day} className="floating-message">
                              <p>{`${this.state.selectedDay.toLocaleDateString()}`}</p>
                              <span className="text-vw-dark">Entrada: {this.renderStartHour(this.props.match.params.Name, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`)}</span><br></br>
                              <span className="text-vw-dark">Salida: {this.renderFinishHour(this.props.match.params.Name, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`)}</span><br></br>
                              <span className="text-vw-dark">Total Diario: {this.renderTotalHour(this.props.match.params.Name, promoter.rest, `${year}-${(month + 1).toString().padStart(2, '0')}-${dayNumber.toString().padStart(2, '0')}`)}</span><br></br>
                              <button className="btn bg-vw-light text-white mt-3">
                                <i className="fas fa-times mr-1"></i>Cerrar
                              </button>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  getUserRest(rest) {

    switch (rest) {
      case 30: return "30 min";
      case 60: return "1 hora";
      default: return "No";
    }
  }

  getUserType(type) {

    switch (type) {
      case "GOD": return "Administrador";
      case "ROOT": return "Editor";
      case "HHRR": return "Recursos Humanos";
      case "LIMITED": return "Trabajador";
      default: return "Sin rol";
    }
  }

  render() {
    const { fullname, promoter, objective, arqueos, shifts } = this.props

    if (!promoter || !objective || !arqueos || !shifts) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let today = new Date();
    today = today.toISOString().split('T')[0];

    let date = new Date();

    return (
      <div>
        <div className="row mb-5 justify-content-between mx-auto">
          <div className="col-xs-12 col-sm-4 col-md-4 p-3 rounded box-shadow">
            <h1>
              <span className="text-vw-light">{this.props.match.params.Name}</span>
            </h1>
            <div className="row justify-content-center">
              <img className="user-image m-3" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${this.props.match.params.Name}.png`} alt="User" />
              <div className="m-3 p-3">
                <h2>
                  <span className="text-vw-dark">Jornada: </span>
                  <span className="text-vw-light">{promoter.workingday}h</span>
                </h2>
                <h2>
                  <span className="text-vw-dark">Descanso: </span>
                  <span className="text-vw-light">{this.getUserRest(promoter.rest)}</span>
                </h2>
                <h2>
                  <span className="text-vw-dark">Rol: </span>
                  <span className="text-vw-light">Promotor</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 p-3 bg-vw-dark rounded box-shadow">
            <h1>
              <span className="text-white">REGISTRO HORARIO</span>
            </h1>
            <div className="row">
              <div className="col">
                <h2>
                  <span className="text-white">Fecha: </span>
                  <span className="text-vw-light">{date.toLocaleDateString('es-ES', { timeZone: 'UTC' })}</span>
                </h2>
                <h2>
                  <span className="text-white">Entrada: </span>
                  <span className="text-vw-light">{this.renderStartHour(this.props.match.params.Name, today)}</span>
                </h2>
                <h2>
                  <span className="text-white">Salida: </span>
                  <span className="text-vw-light">{this.renderFinishHour(this.props.match.params.Name, today)}</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 p-3 bg-vw-light rounded box-shadow">
            <h1>
              <span className="text-vw-dark">CÓMPUTO TOTAL</span>
            </h1>
            <div className="row">
              <div className="col">
                <h2>
                  <span className="text-vw-dark">Total Diario: </span>
                  <span className="text-white">{this.renderTotalHour(this.props.match.params.Name, promoter.rest, today)}</span>
                </h2>
                <h2>
                  <span className="text-vw-dark">Total Semanal: </span>
                  <span className="text-white">{this.renderTotalWeekHours(this.props.match.params.Name, promoter.rest)}</span>
                </h2>
                <h2>
                  <span className="text-vw-dark">Total Mensual: </span>
                  <span className="text-white">{this.renderTotalMonthHour(this.props.match.params.Name, promoter.rest)}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5 mt-5 justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-12 text-center p-3 rounded box-shadow">
            <h1>
              <span className="text-vw-dark">OBJETIVOS MES</span>
            </h1>
            <div className="row mb-5 mt-5 justify-content-center">
              <div className="col-xs-12 col-sm-6 col-md-6 text-center mb-5">
                <h2>
                  <span className="text-vw-light">VENTAS</span>
                </h2>
                {this.progressBar(this.calculateTotalMonthReal(), this.calculateTotalMonthObjective())}
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 text-center mb-5">
                <h2>
                  <span className="text-vw-light">KPI LAY</span>
                </h2>
                <h2 className="text-vw-dark">{this.calculateTotalKPILay().toFixed(2)}%</h2>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 text-center mb-5">
                <h2>
                  <span className="text-vw-light">KPI GO</span>
                </h2>
                <h2 className="text-vw-dark">{this.calculateTotalKPIGo().toFixed(2)}%</h2>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 text-center mb-5">
                <h2>
                  <span className="text-vw-light">V. CRUZADA</span>
                </h2>
                <h2 className="text-vw-dark">{this.calculateTotalCrossSale().toFixed(2)}%</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 mt-4 justify-content-center">
          <div className="col-xs-12 col-sm-6 col-md-6 text-center">
            <h1>
              <span className="text-vw-dark">CALENDARIO</span>
            </h1>
            <div className="row mb-2 mt-2 justify-content-center">
              <div className={`complete-shift mr-2 p-3`}>Fichaje Correcto</div>
              <div className={`mid-shift mr-2 p-3`}>Fichaje Incompleto</div>
              <div className={`no-shift mr-2 p-3`}>Sin Fichaje</div>
              <div className={`holiday-shift p-3`}>Vacaciones</div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          {this.renderRegisterDaysShifts()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shifts: state.shifts.shifts,
  promoter: state.promoters.promoter,
  objective: state.objectives.objective,
  arqueos: state.arqueos.arqueos,
  fullname: state.auth.fullname,
});

const mapDispatchToProps = dispatch => ({
  fetchShifts: bindActionCreators(fetchShifts, dispatch),
  fetchPromoter: bindActionCreators(fetchPromoter, dispatch),
  fetchObjective: bindActionCreators(fetchObjective, dispatch),
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoterShiftView);
