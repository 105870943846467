import {
  SHIFTS_REQUEST, SHIFTS_SUCCESS, SHIFTS_FAILURE,
  SHIFTS_RESET,
  SHIFTS_ADMIN_POST_REQUEST, SHIFTS_ADMIN_POST_SUCCESS, SHIFTS_ADMIN_POST_FAILURE,
  SHIFTS_ADMIN_GET_REQUEST, SHIFTS_ADMIN_GET_SUCCESS, SHIFTS_ADMIN_GET_FAILURE,
  SHIFTS_ADMIN_ADD_REQUEST, SHIFTS_ADMIN_ADD_SUCCESS, SHIFTS_ADMIN_ADD_FAILURE,
  SHIFTS_QRCODE_PUT_REQUEST, SHIFTS_QRCODE_PUT_SUCCESS, SHIFTS_QRCODE_PUT_FAILURE
} from '../actions/shifts'

const INITIAL_STATE = {
  shifts: null, error: null, loading: false, shift: null, shiftsdownload: null, qrcode: null
}

const shiftsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHIFTS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case SHIFTS_SUCCESS:
      return {
        ...state,
        shifts: action.response, error: null, loading: false
      }
    case SHIFTS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case SHIFTS_ADMIN_POST_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case SHIFTS_ADMIN_POST_SUCCESS:
      return {
        ...state,
        shift: action.response, error: null, loading: false
      }
    case SHIFTS_ADMIN_POST_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case SHIFTS_ADMIN_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case SHIFTS_ADMIN_ADD_SUCCESS:
      return {
        ...state,
        shift: action.response, error: null, loading: false
      }
    case SHIFTS_ADMIN_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case SHIFTS_ADMIN_GET_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case SHIFTS_ADMIN_GET_SUCCESS:
      return {
        ...state,
        shifts: action.response, error: null, loading: false
      }
    case SHIFTS_ADMIN_GET_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case SHIFTS_QRCODE_PUT_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case SHIFTS_QRCODE_PUT_SUCCESS:
      return {
        ...state,
        qrcode: action.response, error: null, loading: false
      }
    case SHIFTS_QRCODE_PUT_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case SHIFTS_RESET:
      return {
        ...state,
        shifts: null, error: null, loading: false
      }
    default:
      return state
  }
}

export default shiftsReducer


