import React from 'react'

class AssignedPaymentDevicesList extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Tipo</th>
              <th scope="col">Dispositivo</th>
            </tr>
          </thead>
          <tbody>
            {this.props.Devices.map(device => (
              <tr>
                <th scope="row">{device.Type}</th>
                <th scope="row">{device.Device}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AssignedPaymentDevicesList
