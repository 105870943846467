import { CALL_API } from '../middleware/api'

export const MINIS_REQUEST = 'MINIS_REQUEST'
export const MINIS_SUCCESS = 'MINIS_SUCCESS'
export const MINIS_FAILURE = 'MINIS_FAILURE'

export function fetchMinis(customer) {
  let params = ''

  if (customer !== undefined) {
    params += `&customer=${customer}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `minis?${params}`,
      authenticated: true,
      types: [MINIS_REQUEST, MINIS_SUCCESS, MINIS_FAILURE]
    }
  }
}

export const MINI_REQUEST = 'MINI_REQUEST'
export const MINI_SUCCESS = 'MINI_SUCCESS'
export const MINI_FAILURE = 'MINI_FAILURE'

export function fetchMini(Serial) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `minis/${Serial}`,
      authenticated: true,
      types: [MINI_REQUEST, MINI_SUCCESS, MINI_FAILURE]
    }
  }
}

export const MINI_ADD_REQUEST = 'MINI_ADD_REQUEST'
export const MINI_ADD_SUCCESS = 'MINI_ADD_SUCCESS'
export const MINI_ADD_FAILURE = 'MINI_ADD_FAILURE'

export function addMini(mini) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'minis',
      authenticated: true,
      body: JSON.stringify(mini),
      types: [MINI_ADD_REQUEST, MINI_ADD_SUCCESS, MINI_ADD_FAILURE]
    }
  }
}

export const MINI_UPDATE_REQUEST = 'MINI_UPDATE_REQUEST'
export const MINI_UPDATE_SUCCESS = 'MINI_UPDATE_SUCCESS'
export const MINI_UPDATE_FAILURE = 'MINI_UPDATE_FAILURE'

export function updateMini(Serial, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `minis/${Serial}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [MINI_UPDATE_REQUEST, MINI_UPDATE_SUCCESS,
              MINI_UPDATE_FAILURE]
    }
  }
}

export const MINIS_RESET = 'MINIS_RESET'

export function resetMinis() {
  return { type: MINIS_RESET }
}

export const MINI_DOWNLOAD_REQUEST = 'MINI_DOWNLOAD_REQUEST'
export const MINI_DOWNLOAD_SUCCESS = 'MINI_DOWNLOAD_SUCCESS'
export const MINI_DOWNLOAD_FAILURE = 'MINI_DOWNLOAD_FAILURE'

export function fetchMiniDownloadCSV() {
  let params = ''
  
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `minis/csv?${params}`,
      authenticated: true,
      types: [MINI_DOWNLOAD_REQUEST, MINI_DOWNLOAD_SUCCESS, MINI_DOWNLOAD_FAILURE]
    }
  }
}
