import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { cancelRemoteAction } from '../actions/remoteactions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { history } from '../store'

import RemoteActionStatusBadge from './RemoteActionStatusBadge'

class RemoteActionsList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deleting: false,
      deleteConfirmShown: false,
      deleteId: null,
      result: '',
      resultShown: false
    }

    this.resultToggle = this.resultToggle.bind(this)
    this.resultShow = this.resultShow.bind(this)
    this.delete = this.delete.bind(this)
    this.deleteConfirmShow = this.deleteConfirmShow.bind(this)
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this)
  }

  delete() {
    const { cancelRemoteAction } = this.props

    this.setState({ deleting: true, deleteConfirmShown: false })
    cancelRemoteAction(this.state.deleteId)
  }

  deleteConfirmShow(id) {
    this.setState({
      deleteConfirmShown: true,
      deleteId: id
    })
  }

  deleteConfirmToggle() {
    this.setState({ deleteConfirmShown: !this.state.deleteConfirmShown })
  }

  resultToggle() {
    this.setState({ resultShown: !this.state.resultShown })
  }

  resultShow(result, e) {
    this.setState({ result: result, resultShown: true })
  }

  render() {

    if (this.props.remoteActions.loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    if (this.props.remoteActions.error) {
      return (<h2>Error al cargar las acciones remotas</h2>)
    }

    return (
      <div>
        <div className="table-responsive">
          <table className="table table-hover table-clickable">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Fecha/Hora</th>
                <th scope="col">Código</th>
                <th scope="col">Argumentos</th>
                <th scope="col">Estado</th>
                <th scope="col">Ejecutor</th>
                <th scope="col">Resultado</th>
                <th scope="col">Cancelar</th>
              </tr>
            </thead>
            <tbody>
              {this.props.remoteActions.remoteActions.map(remoteAction => (
                <tr key={remoteAction._id}>
                  <td>
                    {(new Date(remoteAction.date)).toLocaleString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })}
                  </td>
                  <td>{remoteAction.code}</td>
                  <td>
                    <tt>{remoteAction.arguments}</tt>
                  </td>
                  <td>
                    <RemoteActionStatusBadge status={remoteAction.status} />
                  </td>
                  <td>{remoteAction.executor}</td>
                  <td>
                    <button className="btn btn-info"
                      onClick={e => this.resultShow(remoteAction.result, e)}>
                      <i className="fas fa-eye"></i>
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-danger" onClick={() => this.deleteConfirmShow(remoteAction._id)}>
                      <i className="fas fa-cross"></i>
                    </button>
                  </td>
                </tr>
              )
              )}
            </tbody>
          </table>
        </div>
        <Modal isOpen={this.state.resultShown}>
          <ModalHeader toggle={this.resultToggle}>Resultado</ModalHeader>
          <ModalBody>
            <p className="text-break text-monospace">
              {this.state.result}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.resultToggle}>Cerrar</Button>
          </ModalFooter>
        </Modal>
        {/* Confirm deletion */}
        <div>
          <Modal isOpen={this.state.deleteConfirmShown}
            toggle={this.deleteConfirmToggle}>
            <ModalHeader toggle={this.deleteConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de cancelar la acción remota?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.delete}>
                Si
              </Button>
              <Button color="secondary" onClick={this.deleteConfirmToggle}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  remoteActions: state.remoteactions
})

const mapDispatchToProps = dispatch => ({
  cancelRemoteAction: bindActionCreators(cancelRemoteAction, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionsList)

