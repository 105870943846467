import React from 'react'
import * as dateFormat from 'dateformat'

class ShiftAdminEntryList extends React.Component {

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Trabajador</th>
              <th scope="col">Fecha/Hora</th>
            </tr>
          </thead>
          <tbody>
            {this.props.shifts.map(shift => (
              <tr key={shift._id}>
                <td>{shift.workerName}</td>
                <td>
                  {(() => {
                    const shiftDate = new Date(shift.date);

                    return shiftDate.toLocaleString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });
                  })()}
                </td>
              </tr>
            )
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ShiftAdminEntryList

