import {
  MODULES_REQUEST, MODULES_SUCCESS, MODULES_FAILURE,
  MODULE_REQUEST, MODULE_SUCCESS, MODULE_FAILURE,
  MODULE_ADD_REQUEST, MODULE_ADD_SUCCESS, MODULE_ADD_FAILURE,
  MODULE_UPDATE_REQUEST, MODULE_UPDATE_SUCCESS, MODULE_UPDATE_FAILURE,
  MODULE_RESET
} from '../actions/modules'

const INITIAL_STATE = {
  modules: null, modulesNeedRefresh: false, module: null, error: null,
  loading: false
}

const modulesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case MODULES_REQUEST:
        return {
          ...state,
          modules: null, error: null, loading: true
        }
      case MODULES_SUCCESS:
        return {
          ...state,
          modules: action.response, error: null, loading: false
        }
      case MODULES_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MODULE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MODULE_SUCCESS:
        return {
          ...state,
          module: action.response, error: null, loading: false
        }
      case MODULE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MODULE_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MODULE_ADD_SUCCESS:
        return {
          ...state,
          module: action.response, modulesNeedRefresh: true, error: null,
          loading: false
        }
      case MODULE_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MODULE_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MODULE_UPDATE_SUCCESS:
        return {
          ...state,
          module: action.response, modulesNeedRefresh: true, error: null,
          loading: false
        }
      case MODULE_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MODULE_RESET:
        return {
          ...state,
          module: null, error: null, loading: false
        }
      default:
        return state
    }
  }

export default modulesReducer

