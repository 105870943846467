import React from 'react'

class AssignedPairingList extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Index</th>
              <th scope="col">Número de serie</th>
              <th scope="col">PIN</th>
              <th scope="col">MAC</th>
            </tr>
          </thead>
          <tbody>
            {this.props.RegisterLockers.map(registerlocker => (
              <tr>
                <th scope="row">{registerlocker.Position}</th>
                <th scope="row">{registerlocker.SN}</th>
                <td scope="row">{registerlocker.Pin}</td>
                <td scope="row">{registerlocker.MAC}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AssignedPairingList
