import {
  MATRIXS_REQUEST, MATRIXS_SUCCESS, MATRIXS_FAILURE
} from '../actions/matrixs'

const INITIAL_STATE = {
  matrixs: null, matrix: null, error: null, loading: false
}

const matrixsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case MATRIXS_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MATRIXS_SUCCESS:
        return {
          ...state,
          matrixs: action.response, error: null, loading: false
        }
      case MATRIXS_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      default:
        return state
    }
  }

export default matrixsReducer

