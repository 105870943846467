import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { addShiftsAdmin } from '../actions/shifts'
import { fetchUsers } from '../actions/users'
import { renderInputField, renderSelectField } from '../utils/forms'

class ShiftsAdminAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {

    this.props.fetchUsers();
  }

  submit(props) {
    return this.props.addShiftsAdmin(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { users, error, pristine, submitting, handleSubmit, auth } = this.props

    if (!users) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">NUEVO </span>
          <span className="text-vw-light">FICHAJE</span>
        </h1>
        <div className="row justify-content-center">
          <div className="col col-xs-12 col-sm-6 col-md-6">
            <Box title="Datos del fichaje" icon="user">
              <Field name="workerName"
                component={renderSelectField}
                label="Trabajador">
                <option value="">Selecciona un trabajador...</option>
                {users.map(user => {
                  return (
                    <option value={user.fullname} key={user.fullname}>
                      {user.fullname}
                    </option>
                  )
                })}
              </Field>
              <Field name="type"
                component={renderSelectField}
                label="Tipo">
                <option value="office">Fichaje</option>
                <option value="holiday">Vacaciones</option>
                <option value="sick">Enfermo</option>
              </Field>
              <Field name="date"
                component={renderInputField}
                type="datetime-local"
                label="Fecha y hora" />
            </Box>
            {error && <div className="alert alert-danger">{error}</div>}
          </div>
        </div>
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Añadir
        </button>
      </form>
    )
  }
}

ShiftsAdminAddForm = reduxForm({
  form: 'ShiftsAdminAddForm',
  enableReinitialize: true,
  initialValues: { 
    type: 'office'
  },
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/shiftsadmin`)
  }
})(ShiftsAdminAddForm)

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.users
})

const mapDispatchToProps = dispatch => ({
  addShiftsAdmin: bindActionCreators(addShiftsAdmin, dispatch),
  fetchUsers: bindActionCreators(fetchUsers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsAdminAddForm)

