import React from 'react'
import { history } from '../store'
import * as dateFormat from 'dateformat'

class MatrixList extends React.Component {
  goTo(number, e) {
    history.push(`/matrixs/${number}`)
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Máquina</th>
              <th scope="col">Inicialización</th>
              <th scope="col">Actualización</th>
              <th scope="col">#</th>
            </tr>
          </thead>
          <tbody>
            {this.props.matrixs.map(matrix => {
              const date = (new Date(matrix.createdAt)).toLocaleString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })
              const dateUpdated = (new Date(matrix.updatedAt)).toLocaleString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })

              return (
                <tr key={matrix.number}
                  onClick={e => this.goTo(matrix.number, e)}>
                  <td>{matrix.Machine.Serial}</td>
                  <td>{date}</td>
                  <td>{dateUpdated}</td>
                  <th scope="row">{matrix.number}</th>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default MatrixList

