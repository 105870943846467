import React from 'react'
import { history } from '../store'

class Kiosc04List extends React.Component {
  goTo(Code, e) {
    history.push(`/modules/${Code}`)
  }

  render() {
    const { modules } = this.props;

    if (!modules) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Módulos Kiosco VLK</th>
            </tr>
          </thead>
          <tbody>
            {this.props.modules.map(module => (

              (module.Code).substring(0, 5) == 'VLK04' ? (

                <tr key={module.Code}
                  onClick={e => this.goTo(module.Code, e)}>
                  <th scope="row">{module.Code}</th>
                </tr>
                
              ) : ("")
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Kiosc04List
