export function getMachineStatus(machine) {
  if (!machine.Last_signal) {
    return 'UNKNOWN'
  }

  let now = new Date().getTime()
  let diff = (now - Date.parse(machine.Last_signal)) / 1000

  if (diff < 60*5) {
    if (machine.Status === 'OPERATIVE') {
      return 'ONLINE'
    } else if (machine.Status === 'SLEEP') {
      return 'SLEEP'
    } else if (machine.Status === 'NIGHT_SCAN') {
      return 'NIGHTSCAN'
    } else if (machine.Status === 'MAINTENANCE') {
      return 'MANTENIMIENTO'
    } else if (machine.Status === 'LOCKERS_FULL') {
      return 'LOCKERS LLENOS'
    } else if (machine.Status === 'TICKET_EMPTY') {
      return 'SIN TICKET'
    } else if (machine.Status === 'OPERATIVE_SLEEP') {
      return 'SLEEP ONLINE'
    } else if (machine.Status === 'MAINTENANCE_SLEEP') {
      return 'SLEEP MAINTENANCE'
    } else {
      return 'UNKNOWN'
    }
  }

  machine.Status = 'OFFLINE'

  return 'OFFLINE'
}

export function getMachineCash(machine) {

  let totalBills = (machine.Bills_in_box.five * 5.00 + machine.Bills_in_box.ten * 10.00 + machine.Bills_in_box.twenty * 20.00)

  if (totalBills <= 1500) {
    return 'OK'
  } else if (totalBills > 1500) {
    return 'FULL'
  }

}

export function getMachineStatusColor(machine) {
  let status = getMachineStatus(machine)

  if (status === 'ONLINE') {
    return '#28a745'
  } else if (status === 'SLEEP') {
    return '#0066ff'
  } else if (status === 'NIGHTSCAN') {
    return '#26c4f6'
  } else if (status === 'MANTENIMIENTO') {
    return '#ff7b25'
  } else if (status === 'LOCKERS LLENOS') {
    return '#ff0000'
  } else if (status === 'SIN TICKET') {
    return '#ff0000'
  } else if (status === 'SLEEP ONLINE') {
    return '#28a745'
  } else if (status === 'SLEEP MAINTENANCE') {
    return '#ff7b25'
  } else {
    return '#727370'
  }
}

export function getMachineCashColor(machine) {
  let status = getMachineCash(machine)

  if (status === 'OK') {
    return '#28a745'
  } else if (status === 'FULL') {
    return '#ff0000'
  } else {
    return '#28a745'
  }
}

export function getMachinePicture(type) {

  switch (type) {
    case 'VACWAYone' : return '/images/machines/big.png'
    case 'VACWAYmini' : return '/images/machines/small.png'
    default : return '/images/machines/big.png'
  }
}

export function getMachineCirclePicture(type) {
  switch (type) {
    case 'VACWAYone' : return '/images/machines/big_circle.png'
    case 'VACWAYmini' : return '/images/machines/small_circle.png'
    default : return '/images/machines/big_circle.png'
  }
}

export function getMachineThumbnail(type) {

  switch (type) {
    case 'VACWAYone' : return '/images/machines/big_thumb.png'
    case 'VACWAYmini' : return '/images/machines/small_thumb.png'
    default : return '/images/machines/big_thumb.png'
  }
}

export function getMachineTypeInitial(type) {
  return type.toUpperCase()[0]
}

export function getMachineCoordinates(Location) {
  const latlng = Location.split(',')

  if (latlng.length !== 2) {
    return null
  }

  const coordinates = {
    lat: parseFloat(latlng[0]),
    lng: parseFloat(latlng[1])
  }

  if (isNaN(coordinates.lat) || isNaN(coordinates.lng)) {
    return null
  }

  return coordinates
}
