import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchArqueos } from '../actions/arqueos'
import { fetchMachines } from '../actions/machines'
import { fetchCustomers } from '../actions/customers'

import { renderInputField, renderSelectField } from '../utils/forms'

class ArqueosSalesFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      loading: false
    }

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { role, fetchMachines, fetchCustomers } = this.props

    fetchMachines()

    if (role === 'ADMIN') {
      fetchCustomers()
    }

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { role, machines, customers, arqueos } = props

    if (!arqueos.loading && arqueos.arqueosNeedRefresh) {
      this.submit(this.props.formValues)
    }

    if (!this.state.loaded && machines && (role !== 'ADMIN' || customers)) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    this.setState({ loading: true }); // Start loading

    const selectedYear = parseInt(props.Year, 10);
    const yearStart = new Date(selectedYear, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(selectedYear + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    return this.props.fetchArqueos(props.Machine, props.Customer, formattedStartDate, formattedEndDate, props.Country)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        this.setState({ loading: false }); // Stop loading
        return response
      })
      .catch(error => {
        this.setState({ loading: false }); // Stop loading on error as well
        throw error;
      });
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, machines, customers, role } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        {submitting ? (<div className="row"></div>) : (
          <div className="row">
            <div className="col">
              {role === 'ADMIN' &&
                <Field
                  name="Customer"
                  component={renderSelectField}
                  label="Cliente"
                >
                  <option value="">Todos</option>
                  {customers &&
                    customers
                      .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                      .map((customer) => (
                        <option value={customer._id} key={customer._id}>
                          {customer.Fullname}
                        </option>
                      ))}
                </Field>
              }
            </div>
            <div className="col">
              {role === 'ADMIN' &&
                <Field name="Country"
                  component={renderSelectField}
                  label="País">
                  <option value="">Todos</option>
                  <option value="España">
                    España
                  </option>
                  <option value="Italia">
                    Italia
                  </option>
                </Field>
              }
            </div>
            <div className="col">
              <Field
                name="Year"
                component={renderSelectField}
                label="Año"
              >
                {Array.from({ length: 13 }, (_, i) => 2018 + i).map((year) => (
                  <option value={year} key={year}>
                    {year}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        )}
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row">
          {submitting ? (<div class="loading-wheel"></div>) : (
            <button className="btn bg-vw-dark text-white mr-1 button-center"
              type="submit"
              disabled={submitting}>
              <i className="fas fa-sync mr-1"></i>
              Aplicar filtros
            </button>
          )}
        </div>
      </form>
    )
  }
}

ArqueosSalesFilterForm = reduxForm({
  form: 'ArqueosFilterForm',
})(ArqueosSalesFilterForm)

const selector = formValueSelector('ArqueosFilterForm')

const mapStateToProps = state => ({
  machines: state.machines.machines,
  customers: state.customers.customers,
  arqueos: state.arqueos,
  role: state.auth.role,
  initialValues: {
    Machine: '',
    Customer: '',
    Country: '',
    Year: new Date().getFullYear().toString(),
  },
  formValues: {
    Machine: selector(state, 'Machine'),
    Customer: selector(state, 'Customer'),
    Country: selector(state, 'Country'),
    Year: selector(state, 'Year'),
  }
})

const mapDispatchToProps = dispatch => ({
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ArqueosSalesFilterForm)
