import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { history } from '../store'
import * as dateFormat from 'dateformat'

import { deleteSale } from '../actions/sales'

class OnlineSaleList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deleting: false,
      deleteConfirmShown: false,
      deleteId: null
    };

    this.delete = this.delete.bind(this)
    this.deleteConfirmShow = this.deleteConfirmShow.bind(this)
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this)
  }

  goTo(number, e) {
    history.push(`/online-sales/${number}`)
  }

  delete() {
    const { deleteSale } = this.props

    this.setState({ deleting: true })
    deleteSale(this.state.deleteId)
  }

  deleteConfirmShow(id) {
    this.setState({
      deleteConfirmShown: true,
      deleteId: id
    })
  }

  deleteConfirmToggle() {
    this.setState({ deleteConfirmShown: !this.state.deleteConfirmShown })
  }

  render() {
    const { auth, sales } = this.props

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Cliente</th>
              <th scope="col">Fecha/Hora</th>
              <th scope="col">Importe de compra</th>
              <th scope="col">Importe de swap</th>
              <th scope="col">Código</th>
              <th scope="col">Usado</th>
            </tr>
          </thead>
          <tbody>
            {sales.sales.map(sale => (
              <tr key={sale._id}>
                <th scope="row">{sale.number}</th>
                <td>{sale.Customer.Fullname}</td>
                <td>
                  {(new Date(sale.TimeStamp)).toLocaleString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })}
                </td>
                <td>{`${parseFloat(sale.SalePrice || 0).toFixed(2)}`} EUR</td>
                {auth.type !== 'LIMITED' &&
                  <td>{`${parseFloat(sale.SwapPrice || 0).toFixed(2)}`} EUR</td>
                }
                <td> { sale.RedeemCode }</td>
                <td> { sale.Used ? "Si" : "No" }</td>
              </tr>
              )
            )}
          </tbody>
        </table>
        {/* Confirm deletion */}
        <div>
          <Modal isOpen={this.state.deleteConfirmShown}
                 toggle={this.deleteConfirmToggle}>
            <ModalHeader toggle={this.deleteConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
                <p>¿Estás seguro de borrar la venta?</p>
            </ModalBody>
            <ModalFooter>
               <Button color="primary mr-1" onClick={this.delete}>
                 Borrar
               </Button>
              <Button color="secondary" onClick={this.deleteConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sales: state.sales,
})

const mapDispatchToProps = dispatch => ({
  deleteSale: bindActionCreators(deleteSale, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnlineSaleList)

