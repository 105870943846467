import React from 'react'
import { history } from '../store'
import * as dateFormat from 'dateformat'

class SlideToUnlock extends React.Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.containerRef = React.createRef();
    this.state = { unlocked: false, startPosition: 0 };
  }

  componentDidMount() {
    this.sliderRef.current.addEventListener('touchstart', this.handleTouchStart);
    this.sliderRef.current.addEventListener('touchmove', this.handleTouchMove);
    this.sliderRef.current.addEventListener('touchend', this.handleTouchEnd);
  }

  componentWillUnmount() {
    this.sliderRef.current.removeEventListener('touchstart', this.handleTouchStart);
    this.sliderRef.current.removeEventListener('touchmove', this.handleTouchMove);
    this.sliderRef.current.removeEventListener('touchend', this.handleTouchEnd);
  }

  handleTouchStart = (event) => {
    this.setState({ startPosition: event.touches[0].clientX });
  };

  handleTouchMove = (event) => {
    const slider = this.sliderRef.current;
    const container = this.containerRef.current;
    const touch = event.touches[0];
    const maxSlide = container.offsetWidth - slider.offsetWidth;

    let newLeft = touch.clientX - container.getBoundingClientRect().left - slider.offsetWidth / 2;

    if (newLeft < 0) {
      newLeft = 0;
    }
    if (newLeft > maxSlide) {
      newLeft = maxSlide;
      this.setState({ unlocked: true });
      this.props.onUnlock();
      setTimeout(() => {
        this.resetSlider();
      }, 500); // Adjust the delay as needed
    }

    slider.style.left = newLeft + 'px';
  };

  handleTouchEnd = () => {
    const slider = this.sliderRef.current;
    const maxSlide = this.containerRef.current.offsetWidth - slider.offsetWidth;

    if (slider.offsetLeft < maxSlide) {
      this.resetSlider();
    }
  };

  resetSlider = () => {
    this.sliderRef.current.style.left = '0px';
    this.setState({ unlocked: false });
  };

  render() {
    return (
      <div ref={this.containerRef} className="slider-container" >
        <div ref={this.sliderRef} className="slider" >
          {this.state.unlocked ? '✓' : <img src="/images/imagotip.png" alt="Logo" className="w-75" />}
        </div>
      </div>
    );
  }
}

export default SlideToUnlock;
