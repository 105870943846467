import React from 'react';
import { history } from '../store';

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        customer: '',
        country: '',
      },
    };
  }

  goTo(number, e) {
    history.push(`/customers/${number}`);
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  render() {
    const { filters } = this.state;
    const { customers } = this.props;

    // Apply the filters to filter the customers array
    const filteredCustomers = customers.filter(customer => {
      const { customer: customerFilter, country: countryFilter } = filters;

      if (
        customerFilter &&
        (!customer.Fullname ||
          !customer.Fullname.toLowerCase().includes(customerFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        countryFilter &&
        (!customer.Country || // Check if the Country property exists
          !customer.Country.toLowerCase().includes(countryFilter.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the customer in the filtered list
      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nombre completo</th>
              <th scope="col">País</th>
              <th scope="col">Teléfono</th>
              <th scope="col">E-Mail</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Cliente"
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="País"
                  value={filters.country}
                  onChange={e => this.handleFilterChange('country', e.target.value)}
                />
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers
            .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
            .map(customer => (
              <tr key={customer.number} onClick={e => this.goTo(customer.number, e)}>
                <td>{customer.Fullname}</td>
                <td>{customer.Country}</td>
                <td>{customer.Phone}</td>
                <td>{customer.Email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CustomerList;