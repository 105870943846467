import React from 'react'
import { history } from '../store'

class MiniList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        serial: '',
        customer: '',
      },
    };
  }

  goTo(Serial, e) {
    history.push(`/minis/${Serial}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  render() {
    const { filters } = this.state;
    const { minis } = this.props;

    if (!minis) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    // Apply the filters to filter the minis array
    const filteredMinis = minis.filter(mini => {
      const { serial, customer } = filters;

      // Apply the Serial filter
      if (serial && !mini.Serial.includes(serial)) {
        return false;
      }

      // Apply the Customer filter
      if (
        customer &&
        (!mini.Customer ||
          !mini.Customer.Fullname.toLowerCase().includes(customer.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the mini in the filtered list
      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Identificador</th>
              <th scope="col">Cliente</th>
              <th scope="col">Fecha de fabricación</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Serial"
                  value={filters.serial}
                  onChange={e => this.handleFilterChange('serial', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Cliente"
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredMinis.map(mini => (
              <tr key={mini.Serial}
                onClick={e => this.goTo(mini.Serial, e)}>
                <th scope="row">{mini.Serial}</th>
                <td>
                  {(mini.Customer && mini.Customer.Fullname) || 'None'}
                </td>
                <td>
                  {(new Date(mini.Build_date)).toLocaleString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default MiniList
