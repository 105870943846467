import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { renderInputField } from '../utils/forms';

const validatePasswordAssignment = (values) => {
  const errors = {};
  const { password, confirmPassword } = values;

  if (!password) {
    errors.password = 'Campo obligatorio';
  } else {
    if (password.length < 8) {
      errors.password = 'La contraseña debe tener al menos 8 caracteres';
    }
    if (!/[A-Z]/.test(password)) {
      errors.password = 'La contraseña debe contener al menos una letra mayúscula';
    }
    if (!/\d/.test(password)) {
      errors.password = 'La contraseña debe contener al menos un número';
    }
    if (!/[!@#$%^&*()_+]/.test(password)) {
      errors.password = 'La contraseña debe contener al menos un símbolo';
    }
  }

  if (confirmPassword !== password) {
    errors.confirmPassword = 'Las contraseñas no coinciden';
  }

  return errors;
};

class PasswordAssignForm extends React.Component {
  state = {
    showPassword: false,
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { error, handleSubmit, pristine, submitting } = this.props;
    const { showPassword } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <Field
            name="password"
            type={showPassword ? 'text' : 'password'}
            component={renderInputField}
            placeholder="Introduce tu nueva contraseña"
            label="Contraseña"
          />
        </div>
        <div>
          <Field
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            component={renderInputField}
            placeholder="Confirma tu nueva contraseña"
            label="Confirmar Contraseña"
          />
        </div>
        <div className="row">
          <button
            type="button"
            className="btn bg-vw-dark text-white ml-3 mr-3 mb-1"
            onClick={this.togglePasswordVisibility}
          >
            {showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
          </button>
          <button
            className="btn bg-vw-light text-white mr-1 mb-1"
            type="submit"
            disabled={pristine || submitting}
          >
            Asignar contraseña
          </button>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </form>
    );
  }
}

PasswordAssignForm = reduxForm({
  form: 'PasswordAssignForm',
  validate: validatePasswordAssignment,
  enableReinitialize: true,
})(PasswordAssignForm);

export default connect()(PasswordAssignForm);
