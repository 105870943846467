import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'
import * as cc from 'currency-codes'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'

import { addContract } from '../actions/contracts'
import { fetchMachines, resetMachines } from '../actions/machines'
import { fetchCustomers } from '../actions/customers'

import { renderInputField, renderSelectField,
         renderTextAreaField } from '../utils/forms'

class ContractCreateForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.renderItems = this.renderItems.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchMachines, fetchCustomers} = this.props

    fetchMachines()
    fetchCustomers()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { machines, customers } = props

    if (!this.state.loaded &&
        machines && customers) {

      this.setState({
        loaded: true,
        fMachines: machines.filter(machine => machine.Customer === undefined)
      })
    }
  }

  submit(props) {

    props.Executor = this.props.fullname

    return this.props.addContract(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  renderItems(props) {
    const { fields } = props

    return (
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Artículo</th>
                <th scope="col">Precio (PVP)</th>
                <th scope="col">Comisión Cliente (%)</th>
                <th scope="col">Stock</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((member, index) => (
                <tr key={index}>
                  <td>
                    <Field name={`${member}.item`}
                           component={renderSelectField}>
                      <option value="">Selecciona un artículo...</option>
                      <option value="VWPACK" key="VWPACK">PACK</option>
                      <option value="VWLAY" key="VWLAY">LAY</option>
                      <option value="VWGO" key="VWGO">GO</option>
                      <option value="LOCKERBASIC" key="LOCKERBASIC">LOCKERBASIC</option>
                      <option value="LOCKERBASICXL" key="LOCKERBASICXL">LOCKERBASICXL</option>
                      <option value="LOCKERCHARGER" key="LOCKERCHARGER">LOCKERCHARGER</option>
                      <option value="LOCKERCHARGERXL" key="LOCKERCHARGERXL">LOCKERCHARGERXL</option>
                      <option value="TUMBONAS" key="TUMBONAS">TUMBONAS</option>
                      <option value="FLOTADORES" key="FLOTADORES">FLOTADORES</option>
                      <option value="FLOTADORESHALFDAY" key="FLOTADORESHALFDAY">FLOTADORES MEDIO DIA</option>
                      <option value="FASTPASS" key="FASTPASS">FASTPASS</option>
                    </Field>
                  </td>
                  <td>
                    <Field
                      name={`${member}.price`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${member}.comission`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${member}.stock`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <button className="btn btn-danger mr-1" type="button"
                             onClick={() => fields.remove(index)}>
                      <i className="fas fa-trash mr-1"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          <button className="btn bg-vw-light text-white" type="button"
                  onClick={() => fields.push({})}>
            <i className="fas fa-plus mr-1"></i>
            Añadir
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { loaded, fMachines } = this.state
    const { customers, error, pristine, submitting, handleSubmit } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    if (!customers || !fMachines) {
      return (
        <ErrorMessage
          message="No hay clientes o máquinas disponibles para nuevos contratos"/>
      )
    }

    return (
      <div>
        <h1>
          <span className="text-vw-dark">CONTRATO </span>
          <span className="text-vw-light">NUEVO</span>
        </h1>

        <form onSubmit={handleSubmit(this.submit)}>
          <div className="row">
            <div className="col col-12 col-lg-6">
              <Box title="Asignación" icon="arrows-alt-h">
                <Field name="Customer"
                      component={renderSelectField}
                      label="Cliente">
                  <option value="">Selecciona un cliente...</option>
                  {customers.map(customer => {
                    return (
                      <option value={customer._id} key={customer._id}>
                        {customer.Fullname}
                      </option>
                    )
                  })}
                </Field>
                <Field name="Machine"
                      component={renderSelectField}
                      label="Máquina">
                  <option value="">Selecciona una máquina...</option>
                  {fMachines.map(machine => {
                    return (
                      <option value={machine._id} key={machine._id}>
                        {machine.Serial}
                      </option>
                    )
                  })}
                </Field>
                <Field name="Location"
                      component={renderInputField}
                      type="text"
                      label="Localización (Lat, Lon)"/>
                <Field name="WorkingMode"
                       component={renderSelectField}
                       label="Working Mode">
                      <option value="">Selecciona un modo...</option>
                      <option value="0">WATERPROOF</option>
                      <option value="1">WATERPROOF + LOCKERS</option>
                </Field>
              </Box>
            </div>

            <div className="col col-12 col-lg-6">
              <Box title="Condiciones" icon="certificate">
                <Field name="StartDate"
                       component={renderInputField}
                       type="date"
                       label="Fecha inicio"/>
                <Field name="EndDate"
                       component={renderInputField}
                       type="date"
                       label="Fecha fin"/>
                <Field name="Currency"
                       component={renderSelectField}
                       value="EUR"
                       label="Moneda">
                  <option value="">Selecciona una moneda...</option>
                  {cc.codes().map(code => {
                    const name = cc.code(code).currency

                    return (
                      <option value={code} key={code}>
                        {`${code} - ${name}`}
                      </option>
                    )
                  })}
                </Field>
                <Field name="ConversionRate"
                       component={renderInputField}
                       type="text"
                       label="Tasa de conversión"/>
                <Field name="ReportAE"
                      component={renderInputField}
                      type="checkbox"
                      label="Rerport AE Italia"/>
              </Box>
            </div>
          </div>              
          <Box title="Ticket" icon="receipt">
                <Field name="TicketHeader"
                       component={renderInputField}
                       type="text"
                       label="Cabecera"/>
                <Field name="TicketIVA"
                       component={renderInputField}
                       type="text"
                       label="IVA (%)"/>
                <Field name="TicketFooter"
                       component={renderInputField}
                       type="text"
                       label="Footer"/>
          </Box>
          <Box title="Artículos" icon="shopping-cart">
            <FieldArray name="Items" component={this.renderItems} />
          </Box>
          { error && <div className="alert alert-danger">{error}</div> }
          <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                  disabled={pristine || submitting}>
            Guardar
          </button>
        </form>
      </div>
    )
  }
}

ContractCreateForm = reduxForm({
  form: 'ContractCreateForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/contracts`)
    props.resetMachines()
  },
  enableReinitialize: true
})(ContractCreateForm)

const mapStateToProps = state => ({
  machines: state.machines.machines,
  customers: state.customers.customers,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  addContract: bindActionCreators(addContract, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  resetMachines: bindActionCreators(resetMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractCreateForm)
