import {
  UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_REQUEST, UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_SUCCESS, UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_FAILURE,
  UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_REQUEST, UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_SUCCESS, UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_FAILURE,
} from '../actions/universalupdates'

const INITIAL_STATE = {
  universalupdatesinglereboot: null, universalupdatesingleparameters: null, error: null, loading: false
}

const remoteActionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_SUCCESS:
      return {
        ...state,
        universalupdatesingleparameters: action.response, error: null, loading: false
      }
    case UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_SUCCESS:
      return {
        ...state,
        universalupdatesinglereboot: action.response, error: null, loading: false
      }
    case UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default remoteActionsReducer

