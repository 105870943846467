import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { history } from '../store'
import * as dateFormat from 'dateformat'

import { deleteSale } from '../actions/sales'

class SaleList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deleting: false,
      deleteConfirmShown: false,
      deleteId: null,
      currentPage: 1,
      todosPerPage: 100,
      maxPageLimit: 10,
      minPageLimit: 0,
      pageNumbers: []

    };

    this.delete = this.delete.bind(this)
    this.deleteConfirmShow = this.deleteConfirmShow.bind(this)
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.onPrevClick = this.onPrevClick.bind(this)
    this.onNextClick = this.onNextClick.bind(this)
    this.renderPageNumbersFunc = this.renderPageNumbersFunc.bind(this)
  }

  handleClick(event) {
    const { currentPage, maxPageLimit, minPageLimit, pageNumbers } = this.state;

    this.setState({
      currentPage: Number(event.target.id)
    });

    this.renderPageNumbersFunc();

  }

  onPrevClick(event) {
    const { currentPage, maxPageLimit, minPageLimit, pageNumbers } = this.state;

    if (minPageLimit === 0) {
      this.setState({
        currentPage: currentPage - 1
      });
    } else {
      this.setState({
        currentPage: currentPage - 1,
        maxPageLimit: maxPageLimit - 1,
        minPageLimit: minPageLimit - 1
      });
    }

    this.renderPageNumbersFunc();

  }

  onNextClick(event) {
    const { currentPage, maxPageLimit, minPageLimit, pageNumbers } = this.state;

    if (maxPageLimit === pageNumbers[pageNumbers.length - 1]) {
      this.setState({
        currentPage: currentPage + 1
      });
    } else {
      this.setState({
        currentPage: currentPage + 1,
        maxPageLimit: maxPageLimit + 1,
        minPageLimit: minPageLimit + 1
      });
    }


    this.renderPageNumbersFunc();
  }

  goTo(id, e) {
    history.push(`/sales/${id}`)
  }

  delete() {
    const { deleteSale } = this.props

    this.setState({ deleting: true })
    deleteSale(this.state.deleteId)
  }

  deleteConfirmShow(id) {
    this.setState({
      deleteConfirmShown: true,
      deleteId: id
    })
  }

  deleteConfirmToggle() {
    this.setState({ deleteConfirmShown: !this.state.deleteConfirmShown })
  }

  componentDidMount() {
    const { currentPage, maxPageLimit, minPageLimit, pageNumbers } = this.state;

    let realMaxPageLimit = 0

    realMaxPageLimit = pageNumbers[pageNumbers.length - 1]

    if (realMaxPageLimit < maxPageLimit) {

      this.setState({
        maxPageLimit: realMaxPageLimit
      });
    }
  }

  renderPageNumbersFunc() {
    const { maxPageLimit, minPageLimit, todosPerPage, currentPage, pageNumbers } = this.state

    const { auth, sales } = this.props

    // Logic for displaying page numbers

    for (let i = 1; i <= Math.ceil(sales.sales.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.slice(Number(minPageLimit), Number(maxPageLimit)).map(number => {
      return (
        <li
          key={number}
          id={number}
          onClick={this.handleClick}
          className={(this.state.currentPage === number ? 'active ' : '') + 'controls'}
        >
          {number}
        </li>
      );
    });

    return (renderPageNumbers);
  }

  render() {
    const { auth, sales, maxPageLimit, minPageLimit } = this.props

    const { currentPage, todosPerPage, pageNumbers } = this.state

    // Logic for displaying page numbers

    for (let i = 1; i <= Math.ceil(sales.sales.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    // Logic for displaying current sales
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = sales.sales.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((sale, index) => {
      return (
        <tr key={index}>
          <th scope="row">{sale.Code}</th>
          <td>{sale.Customer.Fullname}</td>
          {auth.type !== 'LIMITED' &&
            <td>{sale.Customer.Category}</td>
          }
          <td>
            {(new Date(sale.TimeStamp)).toLocaleString('es-ES', { timeZone: 'UTC', hour12: false })}
          </td>
          {auth.type !== 'LIMITED' &&
            <td>{`${parseFloat(sale.Amount).toFixed(2)} €`}</td>
          }
          <td>{sale.Language}</td>
          <td>{sale.WorkerSession}</td>
          <td>{sale.Details == "" ? "TPV" : sale.Details}</td>
          <td>{sale.OperationTime}</td>
          <td>
            <button className="btn btn-primary mr-1" onClick={() => openInNewTab(`/sales/${sale._id}`)}>
              <i className="fas fa-info mr-1"></i>Detalles
            </button>
          </td>
          {/* {auth.type !== 'LIMITED' &&
            <td>
              <button className="btn btn-danger mr-1" onClick={() => this.deleteConfirmShow(sale._id)}>
                <i className="fas fa-trash mr-1"></i>
              </button>
            </td>
          } */}
        </tr>
      );
    });

    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
      <div className="table-responsive">
        <h4 style={{ textAlign: 'center' }}>Página {currentPage} de {pageNumbers[pageNumbers.length - 1]}</h4>
        <ul id="page-numbers">
          <button className="btn btn-primary mr-1" onClick={this.onPrevClick} disabled={currentPage === pageNumbers[0]}>Prev</button>

          {this.renderPageNumbersFunc()}

          <button className="btn btn-primary mr-1" onClick={this.onNextClick} disabled={currentPage === pageNumbers[pageNumbers.length - 1]}>Next</button>
        </ul>
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Factura</th>
              <th scope="col">Cliente</th>
              {auth.type !== 'LIMITED' &&
                <th scope="col">Categoria</th>
              }
              <th scope="col">Fecha/Hora</th>
              <th scope="col">Importe</th>
              <th scope="col">Idioma</th>
              <th scope="col">Promotor</th>
              <th scope="col">Detalles</th>
              <th scope="col">Tiempo</th>
              <th scope="col"></th>
              {/* {auth.type !== 'LIMITED' &&
                <th scope="col"></th>
              } */}
            </tr>
          </thead>
          <tbody>
            {renderTodos}
          </tbody>
        </table>
        <ul id="page-numbers">
          <button className="btn btn-primary mr-1" onClick={this.onPrevClick} disabled={currentPage === pageNumbers[0]}>Prev</button>

          {this.renderPageNumbersFunc()}

          <button className="btn btn-primary mr-1" onClick={this.onNextClick} disabled={currentPage === pageNumbers[pageNumbers.length - 1]}>Next</button>
        </ul>
        <h4 style={{ textAlign: 'center' }}>Página {currentPage} de {pageNumbers[pageNumbers.length - 1]}</h4>
        {/* Confirm deletion */}
        <div>
          <Modal isOpen={this.state.deleteConfirmShown}
            toggle={this.deleteConfirmToggle}>
            <ModalHeader toggle={this.deleteConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de borrar la venta?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.delete}>
                Borrar
              </Button>
              <Button color="secondary" onClick={this.deleteConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sales: state.sales,
})

const mapDispatchToProps = dispatch => ({
  deleteSale: bindActionCreators(deleteSale, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaleList)


