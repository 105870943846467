import React from 'react'

class AssignedItemList extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Código</th>
              <th scope="col">Precio (EUR)</th>
              <th scope="col">{`Precio (${this.props.currency})`}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.items.map(item => (
              <tr key={item.item.code}>
                <th scope="row">{item.item}</th>
                <td>{`${parseFloat(item.price).toFixed(2)} €`}</td>
                <td>{`${parseFloat(item.price/this.props.conversionrate).toFixed(2)} ${this.props.currency}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AssignedItemList
