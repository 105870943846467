import React from 'react'

class StockTable extends React.Component {
  render() {
    const { machine } = this.props

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Pack</th>
              <th scope="col">Lay</th>
              <th scope="col">Go!</th>
            </tr>
          </thead>
          <tbody>
            <tr key={machine.Serial}>
              <td>{machine.Stock_machine['VWPACK']}</td>
              <td>{machine.Stock_machine['VWLAY']}</td>
              <td>{machine.Stock_machine['VWGO']}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default StockTable


