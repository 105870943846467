import { CALL_API } from '../middleware/api'

export const SHIFTS_REQUEST = 'SHIFTS_REQUEST'
export const SHIFTS_SUCCESS = 'SHIFTS_SUCCESS'
export const SHIFTS_FAILURE = 'SHIFTS_FAILURE'

export function fetchShifts(name, machine, startDate, endDate) {
  let params = ''

  if (name !== undefined) {
    params += `&name=${name}`
  }

  if (machine !== undefined) {
    params += `&machine=${machine}`
  }

  if (startDate !== undefined) {
    params += `&startDate=${startDate}`
  }

  if (endDate !== undefined) {
    params += `&endDate=${endDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `shift?${params}`,
      authenticated: true,
      types: [SHIFTS_REQUEST, SHIFTS_SUCCESS, SHIFTS_FAILURE]
    }
  }
}

export const SHIFTS_RESET = 'SHIFTS_RESET'

export function resetShifts() {
  return { type: SHIFTS_RESET }
}

export const SHIFTS_ADMIN_POST_REQUEST = 'SHIFTS_ADMIN_POST_REQUEST'
export const SHIFTS_ADMIN_POST_SUCCESS = 'SHIFTS_ADMIN_POST_SUCCESS'
export const SHIFTS_ADMIN_POST_FAILURE = 'SHIFTS_ADMIN_POST_FAILURE'

export function postAdminShift(code, forced) {
  let params = ''

  if (code !== undefined) {
    params += `&code=${code}`
  }

  if (forced !== undefined) {
    params += `&forced=${forced}`
  }

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `shift/admin?${params}`,
      authenticated: false,
      types: [SHIFTS_ADMIN_POST_REQUEST, SHIFTS_ADMIN_POST_SUCCESS, SHIFTS_ADMIN_POST_FAILURE]
    }
  }
}

export function postQRAdminShift(shiftCode, code, forced) {
  let params = ''

  if (code !== undefined) {
    params += `&code=${code}`
  }

  if (shiftCode !== undefined) {
    params += `&shiftCode=${shiftCode}`
  }

  if (forced !== undefined) {
    params += `&forced=${forced}`
  }

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `shift/qradmin?${params}`,
      authenticated: false,
      types: [SHIFTS_ADMIN_POST_REQUEST, SHIFTS_ADMIN_POST_SUCCESS, SHIFTS_ADMIN_POST_FAILURE]
    }
  }
}

export const SHIFTS_ADMIN_ADD_REQUEST = 'SHIFTS_ADMIN_ADD_REQUEST'
export const SHIFTS_ADMIN_ADD_SUCCESS = 'SHIFTS_ADMIN_ADD_SUCCESS'
export const SHIFTS_ADMIN_ADD_FAILURE = 'SHIFTS_ADMIN_ADD_FAILURE'

export function addShiftsAdmin(shift) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `shift/add`,
      authenticated: false,
      body: JSON.stringify(shift),
      types: [SHIFTS_ADMIN_ADD_REQUEST, SHIFTS_ADMIN_ADD_SUCCESS, SHIFTS_ADMIN_ADD_FAILURE]
    }
  }
}

export const SHIFTS_ADMIN_GET_REQUEST = 'SHIFTS_ADMIN_GET_REQUEST'
export const SHIFTS_ADMIN_GET_SUCCESS = 'SHIFTS_ADMIN_GET_SUCCESS'
export const SHIFTS_ADMIN_GET_FAILURE = 'SHIFTS_ADMIN_GET_FAILURE'

export function fetchShiftsAdmin(name, startDate, endDate) {
  let params = ''

  if (name !== undefined) {
    params += `&name=${name}`
  }

  if (startDate !== undefined) {
    params += `&startDate=${startDate}`
  }

  if (endDate !== undefined) {
    params += `&endDate=${endDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `shift/admin?${params}`,
      authenticated: true,
      types: [SHIFTS_ADMIN_GET_REQUEST, SHIFTS_ADMIN_GET_SUCCESS, SHIFTS_ADMIN_GET_FAILURE]
    }
  }
}

export const SHIFTS_DOWNLOAD_REQUEST = 'SHIFTS_DOWNLOAD_REQUEST'
export const SHIFTS_DOWNLOAD_SUCCESS = 'SHIFTS_DOWNLOAD_SUCCESS'
export const SHIFTS_DOWNLOAD_FAILURE = 'SHIFTS_DOWNLOAD_FAILURE'

export function downloadShiftsAdmin(name, startDate, endDate) {
  let params = ''

  if (name !== undefined) {
    params += `&name=${name}`
  }

  if (startDate !== undefined) {
    params += `&startDate=${startDate}`
  }

  if (endDate !== undefined) {
    params += `&endDate=${endDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `shift/admin/csv?${params}`,
      authenticated: true,
      types: [SHIFTS_DOWNLOAD_REQUEST, SHIFTS_DOWNLOAD_SUCCESS, SHIFTS_DOWNLOAD_FAILURE]
    }
  }
}

export function downloadShiftsPromoter(name, startDate, endDate) {
  let params = ''

  if (name !== undefined) {
    params += `&name=${name}`
  }

  if (startDate !== undefined) {
    params += `&startDate=${startDate}`
  }

  if (endDate !== undefined) {
    params += `&endDate=${endDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `shift/promoter/csv?${params}`,
      authenticated: true,
      types: [SHIFTS_DOWNLOAD_REQUEST, SHIFTS_DOWNLOAD_SUCCESS, SHIFTS_DOWNLOAD_FAILURE]
    }
  }
}

export const SHIFTS_QRCODE_PUT_REQUEST = 'SHIFTS_QRCODE_PUT_REQUEST'
export const SHIFTS_QRCODE_PUT_SUCCESS = 'SHIFTS_QRCODE_PUT_SUCCESS'
export const SHIFTS_QRCODE_PUT_FAILURE = 'SHIFTS_QRCODE_PUT_FAILURE'

export function updateShiftCode(code) {
  let params = ''

  if (code !== undefined) {
    params += `&code=${code}`
  }

  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `shift/qrcode?${params}`,
      authenticated: false,
      types: [SHIFTS_QRCODE_PUT_REQUEST, SHIFTS_QRCODE_PUT_SUCCESS, SHIFTS_QRCODE_PUT_FAILURE]
    }
  }
}
