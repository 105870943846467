import React, { useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'
import { QrReader } from 'react-qr-reader';

import Box from '../components/Box'
import { addModule } from '../actions/modules'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

class ModuleAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      legacyMode: true,
      result: 'Escaneando...',
    }

    this.submit = this.submit.bind(this)
    this.handleError = this.handleError.bind(this);
    this.handleScan = this.handleScan.bind(this);
  }


  handleScan = data => {
    if (data) {
      // Assuming the QR code data is the serial number (SN)
      const newFieldValue = data;

      // Update the Redux form values for the corresponding field
      const { fields, form } = this.props;
      const lastIndex = fields.length - 1;

      // Set the SN value for the last field in RegisterLockers array
      form.change(`${fields.get(lastIndex)}.SN`, newFieldValue);

      // Clear the result
      this.setState({ result: 'Escaneando...' });

      // Add a new field to the RegisterLockers array
      fields.push({});
    }
  }

  handleError = err => {
    console.error(err)
    this.setState({ result: err });
  }

  submit(props) {
    return this.props.addModule(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  openImageDialog() {
    this.refs.qrReader1.openImageDialog()
  }

  renderQRScan() {

    return (
      <div>
        <QrReader ref="qrReader1"
          onResult={(result, error) => {
            if (!!result) {
              this.setState({ result: result?.text });
            }

            if (!!error) {
              console.info(error);
            }
          }}
          style={{ width: '100%' }}
          constraints={{ facingMode: 'environment' }}
        />
        <p>{this.state.result}</p>
        <input type="button" value="Seleccionar imagen" onClick={this.openImageDialog.bind(this)} />
      </div>
    )

  }

  renderPairing(props) {
    const { fields } = props

    return (
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Index</th>
                <th scope="col">Número de serie</th>
                <th scope="col">PIN</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((registerlocker, index) => (
                <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    <Field
                      name={`${registerlocker}.SN`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${registerlocker}.Pin`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <button className="btn btn-danger mr-1" type="button"
                      onClick={() => fields.remove(index)}>
                      <i className="fas fa-trash mr-1"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          <button className="btn bg-vw-light text-white" type="button"
            onClick={() => fields.push({})}>
            <i className="fas fa-plus mr-1"></i>
            Añadir
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { error, pristine, submitting, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">NUEVO </span>
          <span className="text-vw-light">MÓDULO</span>
        </h1>
        <hr className="bg-vw-light" />
        <div className="row">
          <div className="col">
            <Box title="QR SCAN" icon="cubes">
              {this.renderQRScan()}
            </Box>
            <Box title="Configuración" icon="cubes">
              <Field name="Code"
                type="text"
                component={renderInputField}
                label="Número de Serie" />
              <FieldArray name="RegisterLockers" component={this.renderPairing} />
            </Box>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

ModuleAddForm = reduxForm({
  form: 'ModuleAddForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/modules/${result.response.Code}`)
  }
})(ModuleAddForm)

const mapDispatchToProps = dispatch => ({
  addModule: bindActionCreators(addModule, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(() => ({}), mapDispatchToProps)(ModuleAddForm)

