import React from 'react'

class AssignedComissionList extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Código</th>
              <th scope="col">Comisión Cliente (%)</th>
            </tr>
          </thead>
          <tbody>
            {this.props.items.map(item => (
              <tr key={item.item}>
                <th scope="row">{item.item}</th>
                <td>{item.comission}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AssignedComissionList
