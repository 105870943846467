import React from 'react'

import { getMachineCash, getMachineCashColor } from '../utils/machines'

class MachineCashBadge extends React.Component {
  render() {
    return (
      <div className="badge"
           style={{backgroundColor: getMachineCashColor(this.props.machine)}}>
        { getMachineCash(this.props.machine) }
      </div>
    )
  }
}

export default MachineCashBadge
