import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as dateFormat from 'dateformat'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import UploadImageToS3 from '../components/UploadImageToS3'
import { fetchPromoter, updatePromoter, updatePromoterImage } from '../actions/promoters'

class PromoterDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      closing: false,
      closeConfirmShown: false,
      opening: false,
      openConfirmShown: false,
      newImage: null
    }

    this.closePromoter = this.closePromoter.bind(this)
    this.closeConfirmShow = this.closeConfirmShow.bind(this)
    this.closeConfirmToggle = this.closeConfirmToggle.bind(this)
    this.openPromoter = this.openPromoter.bind(this)
    this.openConfirmShow = this.openConfirmShow.bind(this)
    this.openConfirmToggle = this.openConfirmToggle.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this);
    this.submitNewImage = this.submitNewImage.bind(this);
  }

  componentDidMount() {
    this.props.fetchPromoter(this.props.match.params.fullname)
  }

  componentWillReceiveProps(nextProps) {
    if ((this.state.opening || this.state.closing) && !nextProps.loading) {
      this.props.push('/promoters')
    }
  }

  handleImageChange(e) {
    this.setState({ newImage: e.target.files[0] });
  }

  submitNewImage() {
    const { updatePromoterImage, promoter } = this.props
    const formData = new FormData();
    formData.append('image', this.state.newImage);

    // Replace with your API endpoint to handle image upload
    // Also handle the response accordingly
    updatePromoterImage(promoter.fullname, formData)
      .then(response => response.json())
      .then(data => {
        // Handle success, like updating the state to reflect the new image
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  closePromoter() {
    const { updatePromoter, promoter } = this.props

    this.setState({ closing: true })
    updatePromoter(promoter.fullname, { status: 'BAJA' })
  }

  closeConfirmShow() {
    this.setState({ closeConfirmShown: true })
  }

  closeConfirmToggle() {
    this.setState({ closeConfirmShown: !this.state.closeConfirmShown })
  }

  openPromoter() {
    const { updatePromoter, promoter } = this.props

    this.setState({ opening: true })
    updatePromoter(promoter.fullname, { status: 'ALTA' })
  }

  openConfirmShow() {
    this.setState({ openConfirmShown: true })
  }

  openConfirmToggle() {
    this.setState({ openConfirmShown: !this.state.openConfirmShown })
  }

  render() {
    const { role, promoter, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!promoter || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">DETALLES DEL </span>
              <span className="text-vw-light">PROMOTOR</span>
            </h1>
          </div>
          {role === 'ADMIN' &&
            <div className="col-xs-12 col-sm-6 col-md-6 text-right">
              <Link to={`/promoters/${promoter.fullname}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
              {promoter.status === 'ALTA' &&
                <button className="btn btn-warning mr-1" onClick={this.closeConfirmShow}>
                  <i className="fas fa-times-circle mr-1"></i>Dar de baja
                </button>
              }
              {promoter.status === 'BAJA' &&
                <button className="btn btn-success mr-1" onClick={this.openConfirmShow}>
                  <i className="fa mr-1"></i>Dar de alta
                </button>
              }
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Foto" icon="user">
              <img className="user-image m-3" src={`https://vacway-images.s3.eu-central-1.amazonaws.com/${promoter.fullname}.png`} alt="User" />
              <UploadImageToS3 user={promoter.fullname} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Detalles" icon="user-tie">
              <PropertyLabel name="Número" value={promoter.number} />
              <PropertyLabel name="Nombre" value={promoter.fullname} />
              {
                (promoter.birthday) &&
                <PropertyLabel name="Cumpleaños" value={(new Date(promoter.birthday)).toLocaleDateString('es-ES', { timeZone: 'UTC' })} />
              }
              <PropertyLabel name="E-Mail" value={promoter.email} />
              <PropertyLabel name="Teléfono" value={promoter.phone} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Condiciones" icon="user-tie">
              <PropertyLabel name="Código Fichaje" value={promoter.password} />
              <PropertyLabel name="Jornada" value={promoter.workingday} />
              <PropertyLabel name="Descanso" value={promoter.rest} />
            </Box>
          </div>
        </div>
        {/* Confirm close */}
        <div>
          <Modal isOpen={this.state.closeConfirmShown}
            toggle={this.closeConfirmToggle}>
            <ModalHeader toggle={this.closeConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de dar de baja al promotor?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.closePromoter}>
                Dar de baja
              </Button>
              <Button color="secondary" onClick={this.closeConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* Confirm open */}
        <div>
          <Modal isOpen={this.state.openConfirmShown}
            toggle={this.openConfirmToggle}>
            <ModalHeader toggle={this.openConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de dar de alta al promotor?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.openPromoter}>
                Dar de alta
              </Button>
              <Button color="secondary" onClick={this.openConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  promoter: state.promoters.promoter,
  loading: state.promoters.loading,
  error: state.promoters.error,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchPromoter: bindActionCreators(fetchPromoter, dispatch),
  updatePromoter: bindActionCreators(updatePromoter, dispatch),
  updatePromoterImage: bindActionCreators(updatePromoterImage, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PromoterDetailsView)