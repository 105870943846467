import React from 'react'

class PromoterStatusBadge extends React.Component {
  getColor(status) {
    if (status === 'BAJA') {
      return "danger"
    }

    return "success"
  }

  getText(status) {
    if (status === 'BAJA') {
      return 'BAJA'
    }

    return 'ALTA'
  }

  render() {
    return (
      <div className={`badge badge-${this.getColor(this.props.status)}`}>
        { this.getText(this.props.status) }
      </div>
    )
  }
}

export default PromoterStatusBadge

