import React from 'react'

class StockTable extends React.Component {
  renderQty(qty) {
    if (qty < 25) {
      return (<span className="text-danger">{qty}</span>)
    }

    return qty
  }

  render() {
    const { customers, selectedCustomer, machines } = this.props

    return (
      <div>
        {customers.map(customer => {
          if (selectedCustomer === "" || selectedCustomer === customer._id) {
            return (
              <div key={customer._id} className="table-responsive">
                <h6>{customer.Fullname}</h6>
                <table className="table table-hover table-clickable">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Locker Basic</th>
                      <th scope="col">Locker Basic XL</th>
                      <th scope="col">Locker Charger</th>
                      <th scope="col">Locker Charger XL</th>
                      <th scope="col">Tumbonas</th>
                      <th scope="col">Flotadores día</th>
                      <th scope="col">Flotadores medio día</th>
                      <th scope="col">Fastpass</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={customer._id}>
                      <th scope="row">Reserva</th>
                      <td>{this.renderQty(customer.Stock['LOCKERBASIC'])}</td>
                      <td>{this.renderQty(customer.Stock['LOCKERBASICXL'])}</td>
                      <td>{this.renderQty(customer.Stock['LOCKERCHARGER'])}</td>
                      <td>{this.renderQty(customer.Stock['LOCKERCHARGERXL'])}</td>
                      <td>{this.renderQty(customer.Stock['TUMBONAS'])}</td>
                      <td>{this.renderQty(customer.Stock['FLOTADORES'])}</td>
                      <td>{this.renderQty(customer.Stock['FLOTADORESHALFDAY'])}</td>
                      <td>{this.renderQty(customer.Stock['FASTPASS'])}</td>
                      <td>{this.renderQty(customer.Stock['VWPACK'])}</td>
                      <td>{this.renderQty(customer.Stock['VWLAY'])}</td>
                      <td>{this.renderQty(customer.Stock['VWGO'])}</td>
                    </tr>
                    {machines.map(machine => {
                      if (machine.Customer && machine.Customer._id === customer._id) {
                        return (
                          <tr key={machine.Serial}>
                            <th scope="row">{machine.Serial}</th>
                            <td>{this.renderQty(machine.Stock_machine['LOCKERBASIC'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['LOCKERBASICXL'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['LOCKERCHARGER'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['LOCKERCHARGERXL'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['TUMBONAS'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['FLOTADORES'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['FLOTADORESHALFDAY'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['FASTPASS'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['VWPACK'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['VWLAY'])}</td>
                            <td>{this.renderQty(machine.Stock_machine['VWGO'])}</td>
                          </tr>
                        )
                      } else {
                        return null
                      }
                    })}
                  </tbody>
                </table>
                <hr/>
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
    )
  }
}

export default StockTable

