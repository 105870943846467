import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchArqueos } from '../actions/arqueos'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { push } from 'react-router-redux'
import ArqueosSalesFilterForm from '../forms/ArqueosSalesFilterForm'

class ComissionsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      VWPACKComission: 0.05,
      VWLAYComission: 0.03,
      VWGOComission: 0.15,
    }

  }

  goTo(client, year, e) {

    this.props.push('/comissions/details', { client: client, year: year })
  }

  componentDidMount() {
    const today = new Date();
    const currentYear = today.getFullYear();

    const yearStart = new Date(currentYear, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(currentYear + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    this.props.fetchArqueos('', '', formattedStartDate, formattedEndDate, '');
  }

  calculatePriceWithoutTaxAndComission(priceWithTax, taxRate, comissionRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    let comissionMultiplier = 1;

    comissionRate != 0 ? comissionMultiplier = comissionRate / 100 : comissionMultiplier = 1

    // Calculate price without tax and round to two decimal places
    const priceWithoutTaxAndComission = ((priceWithTax / taxMultiplier) * comissionMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTaxAndComission);
  }

  calculateUnitsCustomerTotals(customer) {
    const { arqueos } = this.props;

    // Initialize an object to store monthly totals
    const monthlyTotals = {
      productTotal: 0,
      VWPACKTotal: 0,
      VWGOTotal: 0,
      VWLAYTotal: 0,
    };

    let year = 0;
    let customerID = "";

    // Iterate through arqueos to find the ones belonging to the current customer
    arqueos.forEach(arqueo => {
      if (arqueo.Customer && arqueo.Customer.Fullname === customer && arqueo.Customer._id !== "627e3b97db8dc91eb9cc3ac2") {

        // Extract month from the timestamp
        const month = new Date(arqueo.TimeStamp).getUTCMonth();

        monthlyTotals.productTotal += (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWPACK, arqueo.Tax, arqueo.ItemsComissions.VWPACK) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWGO, arqueo.Tax, arqueo.ItemsComissions.VWGO) || 0) + (this.calculatePriceWithoutTaxAndComission(arqueo.ItemsPrices.VWLAY, arqueo.Tax, arqueo.ItemsComissions.VWLAY) || 0);
        monthlyTotals.VWPACKTotal += arqueo.Items.VWPACK || 0;
        monthlyTotals.VWGOTotal += arqueo.Items.VWGO || 0;
        monthlyTotals.VWLAYTotal += arqueo.Items.VWLAY || 0;

        year == 0 ? year = new Date(arqueo.TimeStamp).getFullYear() : ''
        customerID = arqueo.Customer._id

      }
    });

    return {
      customerName: customer,
      monthlyTotals,
      year: year,
      customerID: customerID
    };
  }

  createUnitsClientsArray() {
    const { arqueos } = this.props;

    if (!arqueos) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const customers = Array.from(new Set(arqueos.map(arqueo => arqueo.Customer && arqueo.Customer.Fullname)));
    const clientsArray = [];

    customers.forEach(customer => {
      if (customer && customer !== "VACWAY WATERPROOF SL") {
        const customerTotals = this.calculateUnitsCustomerTotals(customer);
        clientsArray.push(customerTotals);
      }
    });

    // Sort clientsArray in descending order based on productTotal
    clientsArray.sort((a, b) => b.monthlyTotals.productTotal - a.monthlyTotals.productTotal);

    return clientsArray;
  }

  renderUnitsTableBody() {
    const { arqueos, loading, error } = this.props;
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || loading) {
      return <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>;
    }

    const clientsArray = this.createUnitsClientsArray();

    const totalRow = {
      customerName: 'Todos',
      monthlyTotals: {
        productTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.productTotal, 0),
        VWPACKTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWPACKTotal, 0),
        VWLAYTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWLAYTotal, 0),
        VWGOTotal: clientsArray.reduce((sum, client) => sum + client.monthlyTotals.VWGOTotal, 0),
      },
    };

    return (
      <tbody>
        <tr className="thead-totals">
          <td scope="row">{totalRow.customerName}</td>
          <td>{(totalRow.monthlyTotals.productTotal).toFixed(2)}€</td>
          <td>{((totalRow.monthlyTotals.VWPACKTotal * this.state.VWPACKComission) + (totalRow.monthlyTotals.VWLAYTotal * this.state.VWLAYComission) + (totalRow.monthlyTotals.VWGOTotal * this.state.VWGOComission)).toFixed(2)}€</td>
          <td>{(totalRow.monthlyTotals.VWPACKTotal * this.state.VWPACKComission).toFixed(2)}€</td>
          <td>{(totalRow.monthlyTotals.VWLAYTotal * this.state.VWLAYComission).toFixed(2)}€</td>
          <td>{(totalRow.monthlyTotals.VWGOTotal * this.state.VWGOComission).toFixed(2)}€</td>
        </tr>
        {clientsArray.map((client, index) => (
          <React.Fragment key={index}>
            <tr className="thead-dark" onClick={e => this.goTo(client.customerID, client.year, e)}>
              <td scope="row">{client.customerName}</td>
              <td>{(client.monthlyTotals.productTotal).toFixed(2)}€</td>
              <td>{((client.monthlyTotals.VWPACKTotal * this.state.VWPACKComission) + (client.monthlyTotals.VWLAYTotal * this.state.VWLAYComission) + (client.monthlyTotals.VWGOTotal * this.state.VWGOComission)).toFixed(2)}€</td>
              <td>{(client.monthlyTotals.VWPACKTotal * this.state.VWPACKComission).toFixed(2)}€</td>
              <td>{(client.monthlyTotals.VWLAYTotal * this.state.VWLAYComission).toFixed(2)}€</td>
              <td>{(client.monthlyTotals.VWGOTotal * this.state.VWGOComission).toFixed(2)}€</td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    );
  }

  render() {
    const { role } = this.props

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">COMISIONES DE </span>
              <span className="text-vw-light">VENTA</span>
            </h1>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Filtro" icon="filter">
              <ArqueosSalesFilterForm />
            </Box>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8">
            <div className="table-responsive">
              <table className="table table-hover table-clickable">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Cliente</th>
                    <th scope="col">Total Generado</th>
                    <th scope="col">Total Comisiones</th>
                    <th scope="col">VWPACK</th>
                    <th scope="col">VWLAY</th>
                    <th scope="col">VWGO</th>
                  </tr>
                </thead>
                {this.renderUnitsTableBody()}
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  arqueos: state.arqueos.arqueos,
  error: state.arqueos.error,
  loading: state.arqueos.loading,
  role: state.auth.role,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ComissionsView)

