import {
  SHIPMENTS_REQUEST, SHIPMENTS_SUCCESS, SHIPMENTS_FAILURE,
  SHIPMENT_REQUEST, SHIPMENT_SUCCESS, SHIPMENT_FAILURE,
  SHIPMENT_ADD_REQUEST, SHIPMENT_ADD_SUCCESS, SHIPMENT_ADD_FAILURE,
  SHIPMENT_UPDATE_REQUEST, SHIPMENT_UPDATE_SUCCESS, SHIPMENT_UPDATE_FAILURE,
  SHIPMENT_RESET
} from '../actions/shipments'

const INITIAL_STATE = {
  shipments: null, shipment: null, error: null, loading: false
}

const shipmentsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SHIPMENTS_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SHIPMENTS_SUCCESS:
        return {
          ...state,
          shipments: action.response, error: null, loading: false
        }
      case SHIPMENTS_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SHIPMENT_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SHIPMENT_SUCCESS:
        return {
          ...state,
          shipment: action.response, error: null, loading: false
        }
      case SHIPMENT_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SHIPMENT_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SHIPMENT_ADD_SUCCESS:
        return {
          ...state,
          shipment: action.response, error: null, loading: false
        }
      case SHIPMENT_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SHIPMENT_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SHIPMENT_UPDATE_SUCCESS:
        return {
          ...state,
          shipment: action.response, error: null, loading: false
        }
      case SHIPMENT_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SHIPMENT_RESET:
        return {
          ...state,
          shipment: null, error: null, loading: false
        }
      default:
        return state
    }
  }

export default shipmentsReducer

