import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Box from '../components/Box'

import { fetchMatrixs } from '../actions/matrixs'

import MatrixList from '../components/MatrixList'
import ErrorMessage from '../components/ErrorMessage'

class MatrixsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchMatrixs('')
  }

  renderMatrixs() {
    const { matrixs, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message}/>)
    }

    if (!matrixs || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <MatrixList matrixs={matrixs} />
    )
  }

  render() {  

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              MATRICES
            </h1>
          </div>
        </div>
        <Box title="Matrixs">
          { this.renderMatrixs() }
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  matrixs: state.matrixs.matrixs,
  error: state.matrixs.error,
  loading: state.matrixs.loading,
  role: state.auth.role,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MatrixsView)
