import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

import { fetchOnlineSales, fetchOnlineSalesCSV } from '../actions/sales'

class QuicksightDashboardView extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
      const dashboardOptions = {
          url: "https://eu-central-1.quicksight.aws.amazon.com/sn/embed/share/accounts/150173740990/dashboards/60e4f668-9d0b-4926-a891-a6eb22275052?directory_alias=quicksightvacway",
          container: document.getElementById("quicksight-dashboard-container"),
          parameters: {
          },
          scrolling: "no",
          height: 'AutoFit',
          width: '100%',
          iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
          locale: "en-US",
          footerPaddingEnabled: false,
          parametersChangeCallback: () => {},
          // sheetId: sheetId, // use this option to specify initial sheet id to load for the embedded dashboard
          // sheetTabsDisabled: sheetTabsDisabled, // use this option to enable or disable sheet tab controls in dashboard embedding
          printEnabled: false, // use this option to enable or disable print option for dashboard embedding
          undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
          resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
      }

      embedDashboard(dashboardOptions);
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              QUICKSIGHT DASHBOARD
            </h1>
          </div>
        </div>
        <div className="row mb-2 justify-content-center">
          <div className="col-xs-12 col-sm-6 col-md-10 align-items-center" id="quicksight-dashboard-container">
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  sales: state.sales.sales,
  error: state.sales.error,
  loading: state.sales.loading,
  auth: state.auth,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchOnlineSales: bindActionCreators(fetchOnlineSales, dispatch),
  fetchOnlineSalesCSV: bindActionCreators(fetchOnlineSalesCSV, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(QuicksightDashboardView)


