import { CALL_API } from '../middleware/api'

export const OBJECTIVES_REQUEST = 'OBJECTIVES_REQUEST'
export const OBJECTIVES_SUCCESS = 'OBJECTIVES_SUCCESS'
export const OBJECTIVES_FAILURE = 'OBJECTIVES_FAILURE'

export function fetchObjectives(customer, year) {
  let params = ''

  if (customer !== undefined) {
    params += `&Customer=${customer}`
  }

  if (year !== undefined) {

    year = new Date(year);
    year = year.getFullYear();

    const yearStart = new Date(year, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(year + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    params += `&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `objectives?${params}`,
      authenticated: true,
      types: [OBJECTIVES_REQUEST, OBJECTIVES_SUCCESS, OBJECTIVES_FAILURE]
    }
  }
}

export const OBJECTIVE_REQUEST = 'OBJECTIVE_REQUEST'
export const OBJECTIVE_SUCCESS = 'OBJECTIVE_SUCCESS'
export const OBJECTIVE_FAILURE = 'OBJECTIVE_FAILURE'

export function fetchObjective(customer, year) {
  let params = ''

  if (year !== undefined) {

    year = new Date(year);
    year = year.getFullYear();

    const yearStart = new Date(year, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(year + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    params += `&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`
  }

  if (customer !== undefined) {
    params += `&Customer=${customer}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `objectives/${customer}?${params}`,
      authenticated: true,
      types: [OBJECTIVE_REQUEST, OBJECTIVE_SUCCESS, OBJECTIVE_FAILURE]
    }
  }
}

export const OBJECTIVE_ADD_REQUEST = 'OBJECTIVE_ADD_REQUEST'
export const OBJECTIVE_ADD_SUCCESS = 'OBJECTIVE_ADD_SUCCESS'
export const OBJECTIVE_ADD_FAILURE = 'OBJECTIVE_ADD_FAILURE'

export function addObjective(objective) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'objectives',
      authenticated: true,
      body: JSON.stringify(objective),
      types: [OBJECTIVE_ADD_REQUEST, OBJECTIVE_ADD_SUCCESS,
        OBJECTIVE_ADD_FAILURE]
    }
  }
}

export const OBJECTIVE_UPDATE_REQUEST = 'OBJECTIVE_UPDATE_REQUEST'
export const OBJECTIVE_UPDATE_SUCCESS = 'OBJECTIVE_UPDATE_SUCCESS'
export const OBJECTIVE_UPDATE_FAILURE = 'OBJECTIVE_UPDATE_FAILURE'

export function updateObjective(customer, year, props) {
  let params = ''

  if (year !== undefined) {

    year = new Date(year);
    year = year.getFullYear();

    const yearStart = new Date(year, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(year + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    params += `&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`
  }

  if (customer !== undefined) {
    params += `&Customer=${customer}`
  }

  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `objectives/${customer}?${params}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [OBJECTIVE_UPDATE_REQUEST, OBJECTIVE_UPDATE_SUCCESS,
        OBJECTIVE_UPDATE_FAILURE]
    }
  }
}
