import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { addUser } from '../actions/users'
import { renderInputField, renderSelectField } from '../utils/forms'

class UserAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  submit(props) {
    return this.props.addUser(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { error, pristine, submitting, handleSubmit, auth } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">NUEVO </span>
          <span className="text-vw-light">TRABAJADOR</span>
        </h1>
        <div className="row">
          <div className="col">
            <Box title="Datos personales" icon="user">
              <Field name="fullname"
                type="text"
                component={renderInputField}
                label="Nombre" />
              <Field name="shiftcode"
                type="number"
                component={renderInputField}
                label="Código Fichaje" />
              <Field name="workingday"
                type="number"
                component={renderInputField}
                label="Jornada" />
              <Field name="rest"
                component={renderSelectField}
                label="Descanso">
                <option value="">Selecciona un descanso...</option>
                <option value="0">Ninguno</option>
                <option value="30">30 min</option>
                <option value="60">1 hora</option>
              </Field>
            </Box>
          </div>
          <div className="col">
            <Box title="Acceso" icon="user">
              <Field name="email"
                type="text"
                component={renderInputField}
                label="E-mail" />
              <Field name="password"
                type="password"
                component={renderInputField}
                label="Password" />
            </Box>
          </div>
          {auth.type === 'GOD' &&
            <div className="col">
              <Box title="Plataforma" icon="user">
                <Field name="type"
                  component={renderSelectField}
                  label="Tipo">
                  <option value="">Selecciona un tipo...</option>
                  <option value="GOD">Administrador</option>
                  <option value="ROOT">Editor</option>
                  <option value="HHRR">Recursos Humanos</option>
                  <option value="LIMITED">Trabajador</option>
                </Field>
              </Box>
            </div>
          }
        </div>

        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

UserAddForm = reduxForm({
  form: 'UserAddForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/users/${result.response.fullname}`)
  }
})(UserAddForm)

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  addUser: bindActionCreators(addUser, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserAddForm)

