import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropertyLabel from './PropertyLabel'
import Box from './Box'

class MatrixConstructorBLEOff extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      matrixModalOpen: false,
      matrixMatricula: ''
    }

    this.openMatrixDialog = this.openMatrixDialog.bind(this)
    this.closeMatrixDialog = this.closeMatrixDialog.bind(this)
  }

  openMatrixDialog(matricula, e) {
    this.setState({ matrixModalOpen: true, matrixMatricula: matricula })
  }

  closeMatrixDialog() {
    this.setState({ matrixModalOpen: false })
  }

  getErrorCode(error) {

    switch (error) {
      case '0x10': return 'Correct Operation'
      case '0x11': return 'Wrong Operation'
      case '0x12': return 'Timeout'
      case '0x13': return 'Unkown command'
      case '0x16': return 'Fail checksum'
      case '0x17': return 'Unauthenticated authority'
      case '0x18': return 'Unauthorized or failed account'
      case '0x19': return 'Wrong password'
      case '0x1A': return 'Invalid OTP'
      case '0x1B': return 'Manager code and user code cannot be the same'
      case '0x1C': return 'Invalid application of password time'
      case '0x1D': return 'Fail user information saving'
      case '0x1E': return 'It is not the time to start user code'
      case '0x1F': return 'Upper limit of user application quantity'
      case '0x22': return 'No user information in lock system'
      case '0x23': return 'New password format error'
      case '0x24': return 'Information in loading'
      case '0x25': return 'No log in system'
      case '0x26': return 'Pairing password is not verified'
      case '0x27': return 'Failed pairing password verification'
      case '0xFF': return 'Scan Error'
      default: return 'None'
    }
  }

  getColorClass(rssi) {
    if (rssi >= -60 && rssi !== 0) {
      return 'HIGHTOP';
    } else if (rssi < -60 && rssi >= -65) {
      return 'HIGH';
    } else if (rssi < -65 && rssi >= -70) {
      return 'HIGHLOW';
    } else if (rssi < -70 && rssi >= -75) {
      return 'MIDHIGH';
    } else if (rssi < -75 && rssi >= -80) {
      return 'MID';
    } else if (rssi < -80 && rssi >= -85) {
      return 'MIDLOW';
    } else if (rssi < -85 && rssi >= -90) {
      return 'LOWHIGH';
    } else if (rssi < -90 && rssi >= -95) {
      return 'LOW';
    } else if (rssi < -95 && rssi >= -100) {
      return 'LOWTOP';
    } else {
      return 'Null';
    }
  }

  infoMatrix() {

    let checkMatricula = "";
    let checkBLEName = "";
    let checkHobbit = false;
    let checkBateria = "";
    let checkPin = "";
    let checkPhone = "";
    let checkModule = "";
    let checkPass = "";
    let checkAdmin = "";
    let checkType = "";
    let checkPosition = "";
    let checkStatus = "";
    let checkWide = "";
    let checkMode = "";
    let checkConfigured = false;
    let checkLastNightscan = "";
    let checkRssi = 0;
    let checkGender = '';
    let checkFaultCode = '';
    let checkErrorCode = '';
    let kerongVersion = '';

    {
      this.props.matrixs.map(matrix => {
        {
          (matrix.Serial === this.props.machine.Serial) && matrix.Locker.map((lock, index) => {

            if (index + 1 == this.state.matrixMatricula) {
              checkMatricula = lock.Matricula || checkMatricula;
              checkType = lock.Type || checkType;
              checkBLEName = lock.BLEName || checkBLEName;
              checkBateria = lock.Bateria || checkBateria;
              checkPin = lock.Pin || checkPin;
              checkPhone = lock.Phone || checkPhone;
              checkModule = lock.ModuleSN || checkModule;
              checkPass = lock.Pass || checkPass;
              checkAdmin = lock.PasswordCash || checkAdmin;
              checkPosition = lock.Position || checkPosition;
              checkStatus = lock.Status || checkStatus;
              checkWide = lock.wide || checkWide;
              checkMode = lock.Mode || checkMode;
              checkConfigured = lock.Configured || checkConfigured;
              checkLastNightscan = lock.LastNightScanCompleted || checkLastNightscan;
              checkRssi = lock.Rssi || checkRssi;
              checkGender = lock.Gender || checkGender;
              checkFaultCode = lock.FaultCode || checkFaultCode;
              checkErrorCode = lock.ErrorBle || checkErrorCode;
              kerongVersion = lock.KerongVersion || kerongVersion;
            }
          })
        }
      })
    }

    const year = parseInt(checkLastNightscan.slice(0, 4));
    const month = parseInt(checkLastNightscan.slice(4, 6)) - 1; // month is zero-indexed
    const day = parseInt(checkLastNightscan.slice(6, 8));
    const hour = parseInt(checkLastNightscan.slice(8, 10));
    const minute = parseInt(checkLastNightscan.slice(10, 12));
    const second = parseInt(checkLastNightscan.slice(12, 14));

    const nightscanDate = new Date(year, month, day, hour, minute, second);

    nightscanDate.setHours(nightscanDate.getHours());
    const formattedDate = `${nightscanDate.getDate()}/${nightscanDate.getMonth() + 1}/${nightscanDate.getFullYear()}`;
    const formattedTime = `${nightscanDate.getHours().toString().padStart(2, '0')}:${nightscanDate.getMinutes().toString().padStart(2, '0')}`;

    const totalBattery = 6200; // Change this to the maximum battery value

    // Calculate the battery percentage
    const batteryPercentage = (checkBateria / totalBattery) * 100;

    // Determine the number of squares based on battery level
    let squaresCount;
    let batteryColor;

    if (batteryPercentage >= 83.3) {
      squaresCount = 6;
      batteryColor = 'green';
    } else if (batteryPercentage >= 66.7) {
      squaresCount = 5;
      batteryColor = 'green';
    } else if (batteryPercentage >= 50) {
      squaresCount = 4;
      batteryColor = 'orange';
    } else if (batteryPercentage >= 33.3) {
      squaresCount = 3;
      batteryColor = 'orange';
    } else if (batteryPercentage >= 16.7) {
      squaresCount = 2;
      batteryColor = 'red';
    } else {
      squaresCount = 1;
      batteryColor = 'red';
    }

    return (

      <p className="text-break text-monospace">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Box title="Cliente" icon="user" bgcolor="info" textcolor="white">
              <PropertyLabel name="Teléfono"
                value={checkPhone} textcolor="white" />
              <PropertyLabel name="Pin Cliente"
                value={checkPass} textcolor="white" />
              <PropertyLabel name="Pin Universal"
                value={checkAdmin} textcolor="white" />
            </Box>
            <Box title="Taquilla" icon="door-closed">
              <PropertyLabel name="Matrícula"
                value={checkMatricula} />
              <PropertyLabel name="BLEName"
                value={checkBLEName} />
              <PropertyLabel name="Pin de Pairing"
                value={checkPin} />
              <PropertyLabel name="Estado"
                value={checkStatus} />
              <PropertyLabel name="Tipo"
                value={checkType} />
              <PropertyLabel name="Módulo"
                value={checkModule} />
              <PropertyLabel name="Género"
                value={checkGender} />
              <PropertyLabel name="Versión Kerong"
                value={kerongVersion} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-6">
            <Box title="Potencia (Mejor a Peor)" icon="wifi">
              <div className="rssi-bar">
                {['HIGHTOP', 'HIGH', 'HIGHLOW', 'MIDHIGH', 'MID', 'MIDLOW', 'LOWHIGH', 'LOW', 'LOWTOP'].map((color) => (
                  <div key={color} id={`${color}`} className={`rssi-square ${color === this.getColorClass(checkRssi) ? 'highlighted' : ''}`}>
                    {color === this.getColorClass(checkRssi) && <h5>{checkRssi}</h5>}
                  </div>
                ))}
              </div>
            </Box>
            <Box title="Bateria" icon="battery-full">
              <div className="battery-indicator">
                <div className="battery-info">
                  <div className="battery-percentage">{batteryPercentage.toFixed(1)} %</div>
                </div>
                <div className={`battery-shape`}>
                  {Array.from({ length: squaresCount }, (_, index) => (
                    <div key={index} className={`battery-square ${batteryColor}`}></div>
                  ))}
                </div>
                <div className="battery-info">
                  <div className="battery-number">{(checkBateria / 1000).toFixed(1)} V</div>
                </div>
              </div>
            </Box>
            <Box title="Nightscan" icon="cubes">
              <PropertyLabel name="Last Night Scan Date"
                value={formattedDate} />
              <PropertyLabel name="Last Night Scan Hour"
                value={formattedTime} />
            </Box>
            <Box title="Errores" icon="exclamation-triangle" bgcolor="danger" textcolor="white">
              <PropertyLabel name="Avería"
                value={checkFaultCode} textcolor="white" />
              <PropertyLabel name="Código Error"
                value={this.getErrorCode(checkErrorCode)} textcolor="white" />
            </Box>
          </div>
        </div>
      </p>
    )
  }

  render() {

    const matrixRender = [];
    let lockerSelected = "";
    let lockerStatus = "";
    let matrixIndex = 0;
    let checkKiosc = 0;

    let columnHeight = 0;
    let matrixWidth = 0;

    {
      this.props.matrixs.map(matrix => {
        {
          (matrix.Serial === this.props.machine.Serial) && matrix.Locker.map((lock, index) => {

            if (matrixIndex < this.props.machine.LastIndex) {

              lockerSelected = lock.Mode + lock.Wide + lock.Type

              if (columnHeight >= 270) {

                columnHeight = 0;

                if (lock.Wide === 'N') {
                  matrixWidth += 45;
                } else {
                  matrixWidth += 60;
                }
              }

              switch (lock.Type) {

                case "S":
                  columnHeight += 45
                  break;
                case "M":
                  columnHeight += 90
                  break;
                case "L":
                  columnHeight += 135
                  break;
                case "XL":
                  columnHeight += 180
                  break;
                case "2XL":
                  columnHeight += 225
                  break;
                case "3XL":
                  columnHeight += 270
                  break;

              }

              if(lock.BLEStatus){
                lockerStatus = "FB";
              }else{
                lockerStatus = "Null";
              }

              matrixRender.push(

                <div key={index} id={`locker${lockerSelected}`} onClick={e => this.openMatrixDialog(index + 1, e)}>
                  <p>{index + this.props.machine.FirstIndex}</p>
                  <div id={lockerStatus}></div>
                </div>

              );

              matrixIndex++

              if (lock.Position === "C" && checkKiosc === 0) {

                columnHeight += 135

                matrixRender.push(

                  <div key={matrixIndex} id="lockerKioskLeft">
                  </div>

                );

                checkKiosc = 1
              } else if (lock.Position === "C" && checkKiosc === 1) {

                columnHeight += 135

                matrixRender.push(

                  <div key={matrixIndex} id="lockerKioskRight">
                  </div>

                );

                checkKiosc = 0
              }
            }
          })
        }
      })
    }

    return (
      <div id="matrixContainer" style={{ width: matrixWidth }}>
        <div class="row" id="lockerMatrix">
          {matrixRender}
        </div>
        <div>
          <Modal isOpen={this.state.matrixModalOpen} className="locker-modal" onClick={(event) => this.closeMatrixDialog()}>
            <ModalHeader toggle={this.terminateConfirmToggle}>
              Información de taquilla {Number(this.state.matrixMatricula) + this.props.machine.FirstIndex - 1}
            </ModalHeader>
            <ModalBody>
              {this.infoMatrix()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary"
                onClick={(event) => this.closeMatrixDialog()}
                className="button-center">
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );

  }
}

export default MatrixConstructorBLEOff

