import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { fetchMini, updateMini } from '../actions/minis'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'

class MiniEditForm extends React.Component {
       constructor(props) {
              super(props)

              this.state = { loaded: false }
              this.submit = this.submit.bind(this)
       }

       componentDidMount() {
              const { minis, fetchMini, match } = this.props

              if (!minis.mini) {
                     fetchMini(match.params.Serial)

              } else {
                     this.setState({ loaded: true })
              }

       }

       componentWillReceiveProps(nextProps) {
              if (!this.state.loaded && nextProps.minis.mini) {
                     this.setState({ loaded: true })
              }
       }

       submit(props) {
              const { updateMini, minis } = this.props

              props.Executor = this.props.fullname

              return updateMini(minis.mini.Serial, props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       render() {
              const { minis, error, pristine, submitting, handleSubmit } = this.props

              if (!this.state.loaded || !minis) {
                     if (minis.error) {
                            return (
                                   <ErrorMessage message={minis.error.message} />
                            )
                     }

                     return (
                            <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
                     )
              }

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">EDITAR </span>
                                   <span className="text-vw-light">MINI</span>
                            </h1>

                            <div className="row">
                                   <div className="col-xs-12 col-md-3">
                                          <Box title="Mini" icon="cubes">
                                                 <Field name="Build_date"
                                                        component={renderInputField}
                                                        type="date"
                                                        label="Fecha de fabricación" />
                                                 <Field name="Comments"
                                                        type="text"
                                                        component={renderTextAreaField}
                                                        label="Comentarios" />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={pristine || submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

MiniEditForm = reduxForm({
       form: 'MiniEditForm',
       enableReinitialize: true,
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/minis`)
       }
})(MiniEditForm)

const mapStateToProps = state => {
       let Build_date = null

       if (state.minis.mini) {
              Build_date = state.minis.mini.Build_date
       }

       return {
              minis: state.minis,
              fullname: state.auth.fullname,
              initialValues: {
                     ...state.minis.mini,
                     Build_date: new Date(Build_date).toISOString().substring(0, 10)
              }
       }
}

const mapDispatchToProps = dispatch => ({
       fetchMini: bindActionCreators(fetchMini, dispatch),
       updateMini: bindActionCreators(updateMini, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MiniEditForm)

