import React from 'react';
import { history } from '../store'
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import { postAdminShift, updateShiftCode } from '../actions/shifts'
import QRCode from 'react-qr-code';
import Box from '../components/Box';

class ShiftAdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enteredCode: '',
      successMessage: null,
      countdown: 5,
      endShift: false,
      successShift: false,
      qrShiftCode: '',
      showQR: false,
      authentication: true,
      isAuthorizedDevice: false,
      code: '87654321',
    };

    this.submit = this.submit.bind(this)
    this.showQRtoggle = this.showQRtoggle.bind(this)
  }

  componentDidMount() {
    this.checkDeviceAuthorization();

    const searchParams = new URLSearchParams(this.props.location.search);
    const auth = searchParams.get('auth');

    // if(auth === this.state.auth){
    //   this.setState({ authentication: true });

    //   this.generateHexCode();
    //   this.props.updateShiftCode(this.state.qrShiftCode);

    //   // Set up the interval
    //   this.refreshInterval = setInterval(() => {
    //     this.refreshData();
    //   }, 30000);
    // }else{
    //   this.setState({ authentication: false });
    // }

    this.generateHexCode();
    this.props.updateShiftCode(this.state.qrShiftCode);

    // Set up the interval
    this.refreshInterval = setInterval(() => {
      this.refreshData();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  showQRtoggle() {
    this.setState({ showQR: !this.state.showQR })
  }

  refreshData = () => {
    if (this.state.showQR) {

      this.generateHexCode();
      this.props.updateShiftCode(this.state.qrShiftCode);
    }
  }

  checkDeviceAuthorization() {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const screenResolution = `${window.screen.width}x${window.screen.height}`;

    // Known device characteristics for the authorized iPad Mini
    const authorizedDevice = {
      userAgentIncludes: "iPad", // 'iPad' keyword in userAgent string
      platform: "iPad",          // platform for iPads
      screenResolution: "768x1024" // Resolution for iPad Mini
    };

    if (
      userAgent.includes(authorizedDevice.userAgentIncludes) &&
      platform === authorizedDevice.platform &&
      screenResolution === authorizedDevice.screenResolution
    ) {
      this.setState({ isAuthorizedDevice: true });
    } else {
      this.setState({ isAuthorizedDevice: false });
    }
  }

  goTo = (e) => {
    history.push(`/shiftregisteradmin`)
  }

  generateHexCode() {
    let result = '';
    const characters = '0123456789abcdef';
    const charactersLength = characters.length;
    for (let i = 0; i < 16; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    this.setState({ qrShiftCode: result })
  }

  // Function to start the countdown
  startCountdown = () => {
    this.countdownInterval = setInterval(() => {
      this.setState((prevState) => ({
        countdown: prevState.countdown - 1,
      }));

      if (this.state.countdown === 0) {
        // Stop the countdown when it reaches 0
        clearInterval(this.countdownInterval);

        this.setState({
          enteredCode: '',
          successMessage: null,
          countdown: 5,
        });

        this.goTo(0);
      }
    }, 1000); // Update the countdown every second
  };

  submit = (code, forced) => {
    return this.props.postAdminShift(code, forced)
      .then(answer => {
        if (answer.error) {
          throw new SubmissionError({
            _error: answer.error.message,
            ...answer.error.fields
          })
        }

        // Stop the countdown when it reaches 0
        clearInterval(this.countdownInterval);

        let shiftAnswer = answer.response.shiftAnswer

        if (shiftAnswer.firstshift) {
          const currentDate = new Date();

          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          };

          const formattedDate = currentDate.toLocaleString(undefined, options);
          const formattedTime = currentDate.toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false });

          this.setState({
            enteredCode: '',
            successShift: true,
            endShift: false,
            successMessage: `Fichaje correcto!\n\n

            ${shiftAnswer.fullname}\n\n

            ${formattedTime}\n\n
            
            ${formattedDate}\n\n`,
          });
        } else {
          if (shiftAnswer.allshift) {

            this.setState({
              enteredCode: '',
              countdown: 10,
              successShift: false,
              endShift: false,
              successMessage: `Hoy ya has fichado la entrada\n\n
              y la salida ${shiftAnswer.fullname}!\n\n`,
            });
          } else {

            this.setState({
              countdown: 10,
              successShift: false,
              endShift: true,
              successMessage: `Seguro que quieres fichar\n\n
              la salida ${shiftAnswer.fullname}?\n\n`,
            });
          }
        }

        this.startCountdown();

        return answer;
      })
      .catch(error => {

        this.setState({
          enteredCode: '',
          successMessage: 'ERROR: Código no válido!',
        });

        this.startCountdown();

        console.error('Error de fichaje:', error);
      });
  };

  handleSubmit = (forced) => {
    if (this.state.enteredCode.length === 6) {
      this.submit(this.state.enteredCode, forced);
    }
  };

  handleButtonClick = (value) => {
    this.setState((prevState) => {
      const newCode = prevState.enteredCode + value;

      // Check if the new code exceeds 6 digits
      if (newCode.length <= 6) {
        return {
          enteredCode: newCode,
        };
      }

      return null; // Do not update the state if the limit is reached
    });
  };

  handleButtonDeleteClick = (value) => {
    // Update the entered code when a button is clicked
    this.setState((prevState) => ({
      enteredCode: '',
    }));
  };

  handleButtonBackClick = (value) => {
    // Update the entered code when a button is clicked
    this.setState((prevState) => ({
      enteredCode: prevState.enteredCode.slice(0, -1),
    }));
  };

  render() {
    const isSubmitDisabled = this.state.enteredCode.length !== 6;
    const { successMessage, countdown, showQR, isAuthorizedDevice } = this.state;

    // Block access if the device is not authorized
    if (!isAuthorizedDevice) {
      return <div className="text-center flex justify-content-center align-items-center mt-5 mb-5 w-100">
        <h1>Access denied. You are not using the authorized device. You will be reported to administrators due to shift fraud attempt. Have a happy day!</h1>
      </div>;
    }

    return (
      <div className="h-100 bg-login">
        {this.state.authentication ? (
          <div className="container h-100">
            <div className="pt-5 flex justify-content-center" onClick={() => this.showQRtoggle()}><i class="fas fa-qrcode"></i></div>
            <div className="row justify-content-center mt-2">
              {showQR ? (
                <Box title="Fichaje QR" icon="key" bgcolor="white">
                  <QRCode className="m-2" value={this.state.qrShiftCode} id="qrimage" renderAs="canvas" size={256} level={"H"} includeMargin={true} />
                </Box>
              ) : (
                <div>
                  {!successMessage ? (
                    <div className="keypad-wrapper mb-5">
                      <div className="entered-code">
                        {/* Display the entered code */}
                        {this.state.enteredCode}
                      </div>
                      <div className="keypad-container">
                        {/* Render keypad buttons */}
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
                          <button
                            key={value}
                            className="keypad-button"
                            onClick={() => this.handleButtonClick(value)}
                          >
                            {value}
                          </button>
                        ))}
                        <button
                          key={11}
                          className="keypad-button-delete"
                          onClick={() => this.handleButtonDeleteClick()}
                        >
                          C
                        </button>
                        <button
                          key={0}
                          className="keypad-button"
                          onClick={() => this.handleButtonClick(0)}
                        >
                          {0}
                        </button>
                        {/*<button
                      key={12}
                      className="keypad-button-back"
                      onClick={() => this.handleButtonBackClick()}
                    >
                      &lt;
                    </button>*/}
                      </div>
                      <button
                        className="submit-button"
                        onClick={() => this.handleSubmit(false)}
                        disabled={isSubmitDisabled}
                      >
                        Fichar
                      </button>
                    </div>
                  ) : (
                    <div className="keypad-wrapper">
                      <div className="row">
                        {this.state.successShift ? (<img className="keypad-success-animation" src="/images/check.png" alt="Dragon GIF" />) : (<div></div>)}
                        <div className="success-message mb-3 text-white">{successMessage}</div>
                      </div>
                      {this.state.endShift ? (<button
                        className="submit-end-button"
                        onClick={() => this.handleSubmit(true)}
                      >
                        Fichar Salida
                      </button>) : (<div></div>)}
                      <div className="text-white text-center mt-3">
                        <h2>{countdown > 0 && `Cerrando...${countdown}s`}</h2>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (<div>NO AUTORIZADO</div>)}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  postAdminShift: bindActionCreators(postAdminShift, dispatch),
  updateShiftCode: bindActionCreators(updateShiftCode, dispatch),
  push: bindActionCreators(push, dispatch)
});

export default connect(() => ({}), mapDispatchToProps)(ShiftAdminView);
