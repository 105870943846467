import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AppHeader from './AppHeader'
import TopButton from '../components/TopButton'

import HomeView from '../views/HomeView'
import MachinesView from '../views/MachinesView'
import MachineDetailsView from '../views/MachineDetailsView'
import MachineAddForm from '../forms/MachineAddForm'
import MachineEditForm from '../forms/MachineEditForm'
import ContractsView from '../views/ContractsView'
import ContractCreateForm from '../forms/ContractCreateForm'
import ContractEditForm from '../forms/ContractEditForm'
import ContractDetailsView from '../views/ContractDetailsView'
import CustomersView from '../views/CustomersView'
import CustomerAddForm from '../forms/CustomerAddForm'
import CustomerEditForm from '../forms/CustomerEditForm'
import CustomerDetailsView from '../views/CustomerDetailsView'
import IncidencesView from '../views/IncidencesView'
import IncidenceCreateForm from '../forms/IncidenceCreateForm'
import IncidenceDetailsView from '../views/IncidenceDetailsView'
import LogisticsView from '../views/LogisticsView'
import NewShipmentForm from '../forms/NewShipmentForm'
import LogisticsShipmentView from '../views/LogisticsShipmentView'
import SalesView from '../views/SalesView'
import OnlineSalesView from '../views/OnlineSalesView'
import SaleDetailsView from '../views/SaleDetailsView'
import UsersView from '../views/UsersView'
import UserAddForm from '../forms/UserAddForm'
import UserEditForm from '../forms/UserEditForm'
import UserDetailsView from '../views/UserDetailsView'
import UpdatesView from '../views/UpdatesView'
import UpdateAddForm from '../forms/UpdateAddForm'
import UpdateEditForm from '../forms/UpdateEditForm'
import UpdateDetailsView from '../views/UpdateDetailsView'
import NotFoundView from '../views/NotFoundView'
import AuditView from '../views/AuditView'
import ShiftsView from '../views/ShiftsView'
import ShiftsAdminView from '../views/ShiftsAdminView'
import ShiftsAdminAddForm from '../forms/ShiftsAdminAddForm'
import MatrixsView from '../views/MatrixsView'
import ArqueosView from '../views/ArqueosView'
import ArqueosSalesView from '../views/ArqueosSalesView'
import PromotersView from '../views/PromotersView'
import PromoterEditForm from '../forms/PromoterEditForm'
import PromoterCreateForm from '../forms/PromoterCreateForm'
import PromoterDetailsView from '../views/PromoterDetailsView'
import ObjectivesView from '../views/ObjectivesView'
import ObjectiveEditForm from '../forms/ObjectiveEditForm'
import ObjectiveCreateForm from '../forms/ObjectiveCreateForm'
import ObjectiveDetailsView from '../views/ObjectiveDetailsView'
import QuicksightDashboardView from "../views/QuicksightDashboardView";
import ModulesView from '../views/ModulesView'
import ModuleDetailsView from '../views/ModuleDetailsView'
import ModuleAddForm from '../forms/ModuleAddForm'
import ModuleEditForm from '../forms/ModuleEditForm'
import PasswordRecoveryView from '../views/PasswordRecoveryView'
import PasswordAssignView from '../views/PasswordAssignView'
import MinisView from '../views/MinisView'
import MiniDetailsView from '../views/MiniDetailsView'
import MiniAddForm from '../forms/MiniAddForm'
import MiniEditForm from '../forms/MiniEditForm'
import UserShiftView from '../views/UserShiftView'
import PromoterShiftView from '../views/PromoterShiftView'
import TPVsView from '../views/TPVsView'
import ComissionsView from '../views/ComissionsView'
import ComissionDetailsView from '../views/ComissionDetailsView'

class App extends React.Component {
       render() {
              return (
                     <div>
                            <AppHeader />
                            <div className="container pt-3">
                                   <Switch>
                                          <Route path="/" exact
                                                 component={HomeView} />

                                          <Route path="/machines" exact
                                                 component={MachinesView} />
                                          <Route path="/machines/add" exact
                                                 component={MachineAddForm} />
                                          <Route path="/machines/:Serial/edit"
                                                 component={MachineEditForm} />
                                          <Route path="/machines/:Serial"
                                                 component={MachineDetailsView} />

                                          <Route path="/contracts" exact
                                                 component={ContractsView} />
                                          <Route path="/contracts/create" exact
                                                 component={ContractCreateForm} />
                                          <Route path="/contracts/:number/edit"
                                                 component={ContractEditForm} />
                                          <Route path="/contracts/:number"
                                                 component={ContractDetailsView} />

                                          <Route path="/customers" exact
                                                 component={CustomersView} />
                                          <Route path="/customers/add" exact
                                                 component={CustomerAddForm} />
                                          <Route path="/customers/:number/edit"
                                                 component={CustomerEditForm} />
                                          <Route path="/customers/:number"
                                                 component={CustomerDetailsView} />

                                          <Route path="/incidences" exact
                                                 component={IncidencesView} />
                                          <Route path="/incidences/create" exact
                                                 component={IncidenceCreateForm} />
                                          <Route path="/incidences/:number"
                                                 component={IncidenceDetailsView} />

                                          <Route path="/logistics" exact
                                                 component={LogisticsView} />
                                          <Route path="/logistics/newshipping" exact
                                                 component={NewShipmentForm} />
                                          <Route path="/logistics/:number"
                                                 component={LogisticsShipmentView} />

                                          <Route path="/sales" exact
                                                 component={SalesView} />

                                          <Route path="/quicksight" exact
                                                 component={QuicksightDashboardView} />

                                          <Route path="/online-sales" exact
                                                 component={OnlineSalesView} />

                                          <Route path="/sales/:id"
                                                 component={SaleDetailsView} />

                                          <Route path="/users" exact
                                                 component={UsersView} />
                                          <Route path="/users/add" exact
                                                 component={UserAddForm} />
                                          <Route path="/users/:fullname/edit"
                                                 component={UserEditForm} />
                                          <Route path="/users/:fullname"
                                                 component={UserDetailsView} />

                                          <Route path="/updates" exact
                                                 component={UpdatesView} />
                                          <Route path="/updates/add" exact
                                                 component={UpdateAddForm} />
                                          <Route path="/updates/:id/edit"
                                                 component={UpdateEditForm} />
                                          <Route path="/updates/:id"
                                                 component={UpdateDetailsView} />

                                          <Route path="/audit" exact
                                                 component={AuditView} />

                                          <Route path="/shifts" exact
                                                 component={ShiftsView} />
                                          <Route path="/shiftsadmin" exact
                                                 component={ShiftsAdminView} />
                                          <Route path="/shiftsadmin/add"
                                                 component={ShiftsAdminAddForm} />

                                          <Route path="/matrixs" exact
                                                 component={MatrixsView} />

                                          <Route path="/arqueos" exact
                                                 component={ArqueosView} />
                                          <Route path="/arqueossales" exact
                                                 component={ArqueosSalesView} />

                                          <Route path="/promoters" exact
                                                 component={PromotersView} />
                                          <Route path="/promoters/:fullname/edit"
                                                 component={PromoterEditForm} />
                                          <Route path="/promoters/create" exact
                                                 component={PromoterCreateForm} />
                                          <Route path="/promoters/:fullname"
                                                 component={PromoterDetailsView} />
                                          <Route path="/promoter/:Name"
                                                 component={PromoterShiftView} />

                                          <Route path="/objectives" exact
                                                 component={ObjectivesView} />
                                          <Route path="/objectives/:customer/edit"
                                                 component={ObjectiveEditForm} />
                                          <Route path="/objectives/create" exact
                                                 component={ObjectiveCreateForm} />
                                          <Route path="/objectives/:customer"
                                                 component={ObjectiveDetailsView} />

                                          <Route path="/modules" exact
                                                 component={ModulesView} />
                                          <Route path="/modules/add" exact
                                                 component={ModuleAddForm} />
                                          <Route path="/modules/:Code/edit"
                                                 component={ModuleEditForm} />
                                          <Route path="/modules/:Code"
                                                 component={ModuleDetailsView} />

                                          <Route path="/passwordrecovery" exact
                                                 component={PasswordRecoveryView} />
                                          <Route path="/passwordassign"
                                                 component={PasswordAssignView} />

                                          <Route path="/minis" exact
                                                 component={MinisView} />
                                          <Route path="/minis/add" exact
                                                 component={MiniAddForm} />
                                          <Route path="/minis/:Serial/edit"
                                                 component={MiniEditForm} />
                                          <Route path="/minis/:Serial"
                                                 component={MiniDetailsView} />

                                          <Route path="/user/:Name"
                                                 component={UserShiftView} />

                                          <Route path="/tpvs" exact
                                                 component={TPVsView} />

                                          <Route path="/comissions" exact
                                                 component={ComissionsView} />
                                          <Route path="/comissions/details"
                                                 component={ComissionDetailsView} />

                                          <Route path="*"
                                                 component={NotFoundView} />

                                   </Switch>
                            </div>
                     </div>
              )
       }
}

export default App
