import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { bindActionCreators } from 'redux'

import { fetchShiftsAdmin, downloadShiftsAdmin } from '../actions/shifts'
import { fetchUsers, updateFilteredUsers, updateShiftdaysUsers } from '../actions/users'

import { renderInputField, renderSelectField } from '../utils/forms'

class ShiftsAdminFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
    this.downloadShiftSheet = this.downloadShiftSheet.bind(this)
  }

  componentDidMount() {
    const { fetchUsers } = this.props

    fetchUsers()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { users } = props

    if (!this.state.loaded && users) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    this.props.fetchShiftsAdmin(props.name, props.startDate, props.endDate);
    const filteredUsers = this.props.users.filter((user) => user.fullname === props.name);
    this.props.updateFilteredUsers(filteredUsers);

    // Create an array of days between startDate and endDate
    const startDate = new Date(props.startDate);
    const endDate = new Date(props.endDate);
    const daysInRange = [];

    // Loop through each day in the range
    for (let currentDay = startDate; currentDay <= endDate; currentDay.setDate(currentDay.getDate() + 1)) {
      daysInRange.push(currentDay.toISOString().substring(0, 10));
    }

    // Update shiftdaysUsers in the Redux state
    this.props.updateShiftdaysUsers(daysInRange);
  }

  downloadShiftSheet() {
    const { formValues } = this.props;
    this.props.downloadShiftsAdmin(formValues.name, formValues.startDate, formValues.endDate)
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, users } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        <div className="row">
          <div className="col">
            <Field name="name"
              component={renderSelectField}
              label="Trabajador/a">
              <option value="">Todes</option>
              {users.map(user => {
                return (
                  <option value={user.fullname} key={user._id}>
                    {user.fullname}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className="col">
            <Field name="startDate"
              component={renderInputField}
              type="date"
              label="Fecha inicio" />
          </div>
          <div className="col">
            <Field name="endDate"
              component={renderInputField}
              type="date"
              label="Fecha fin" />
          </div>
        </div>
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row justify-content-center">
          <button className="btn bg-vw-dark text-white mr-2"
            type="submit"
            disabled={submitting}>
            <i className="fas fa-sync mr-1"></i>Actualizar
          </button>
          <button className="btn bg-vw-excel text-white ml-2" onClick={() => this.downloadShiftSheet()}>
            <i className="fas fa-file-excel mr-1"></i>Descargar
          </button>
        </div>
      </form>
    )
  }
}

ShiftsAdminFilterForm = reduxForm({
  form: 'ShiftsAdminFilterForm',
  getFormValues: state => getFormValues('ShiftsAdminFilterForm')(state),
})(ShiftsAdminFilterForm)

const mapStateToProps = state => ({
  users: state.users.users,
  initialValues: {
    name: '',
    startDate: new Date().toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10)
  },
  formValues: getFormValues('ShiftsAdminFilterForm')(state),
  filteredUsers: state.users.filteredUsers,
  shiftdaysUsers: state.users.shiftdaysUsers
})

const mapDispatchToProps = dispatch => ({
  fetchShiftsAdmin: bindActionCreators(fetchShiftsAdmin, dispatch),
  downloadShiftsAdmin: bindActionCreators(downloadShiftsAdmin, dispatch),
  fetchUsers: bindActionCreators(fetchUsers, dispatch),
  updateFilteredUsers: bindActionCreators(updateFilteredUsers, dispatch),
  updateShiftdaysUsers: bindActionCreators(updateShiftdaysUsers, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsAdminFilterForm)

