import React from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import GoogleMapReact from 'google-map-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as dateFormat from 'dateformat'
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'
import { history } from '../store'
import MachineMarker from '../components/MachineMarker'

import { fetchMini } from '../actions/minis'
import { getMachinePicture, getMachineCoordinates } from '../utils/machines'
import { GMAPS_KEY } from '../config'

class MiniDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    };

  }

  componentDidMount() {
    const { fetchMini, match } = this.props

    fetchMini(match.params.Serial)
  }

  render() {
    const { role, minis, minis: { mini } } = this.props

    if (minis.error) {
      return (
        <ErrorMessage message={minis.error.message} />
      )
    }

    if (!mini || minis.loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    const coordinates = getMachineCoordinates(mini.Location)

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">MINI </span><span className="text-vw-dark">{mini.Serial}</span>
            </h1>
          </div>
          {role === 'ADMIN' &&
            <div className="col-xs-12 col-sm-6 col-md-6 text-right">
              <Link to={`/minis/${mini.Serial}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Estado" icon="cubes">
              <img src={getMachinePicture(mini.Type)} alt="Mini" width="35%" style={{ marginLeft: '20%' }} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Detalles" icon="cubes">
              <PropertyLabel name="Número de serie"
                value={mini.Serial} />
              <PropertyLabel name="Fecha de fabricación"
                value={(new Date(mini.Build_date)).toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid', hour12: false })} />
              <PropertyLabel name="Tipo"
                value={mini.Type} />
              <PropertyLabel name="Comentarios"
                value={mini.Comments} />
              <PropertyLabel name="Última edición"
                value={mini.Executor} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Asignación" icon="arrows-alt-h">
              {!mini.Customer ? (
                <h6>mini is not allocated</h6>
              ) : (
                <div>
                  <PropertyLabel name="Cliente"
                    value={mini.Customer.Fullname} />
                  <PropertyLabel name="Contrato #"
                    value={mini.Contract.number} />
                  {coordinates &&
                    <div className="mb-3" style={{ width: '100%', height: '210px' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: GMAPS_KEY }}
                        defaultCenter={coordinates}
                        defaultZoom={10}
                      >
                        <MachineMarker
                          lat={coordinates.lat}
                          lng={coordinates.lng}
                          mini={mini}
                        />
                      </GoogleMapReact>
                    </div>
                  }
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  minis: state.minis,
  error: state.minis.error,
  loading: state.minis.loading,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchMini: bindActionCreators(fetchMini, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MiniDetailsView)
