import React, { useState } from 'react';
import AWS from 'aws-sdk'

const S3_BUCKET = 'vacway-images';
const REGION = 'eu-central-1';


AWS.config.update({
    accessKeyId: 'AKIASF5YNN67JDRU5L42',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

const UploadImageToS3 = (props) => {

    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = (file) => {

        const params = {
            Body: file,
            Bucket: S3_BUCKET,
            Key: props.user + '.png'
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) console.log(err)
            })
    }


    return <div>
        <input className="btn bg-vw-dark text-white mt-2" type="file" onChange={handleFileInput} />
        <div className="mt-2">Progreso: {progress}%</div>
        <button className="btn bg-vw-light text-white mt-2" onClick={() => uploadFile(selectedFile)}>
            <i className="fas fa-edit mr-1"></i>Subir imagen
        </button>
    </div>
}

export default UploadImageToS3;