import {
  USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE,
  USER_REQUEST, USER_SUCCESS, USER_FAILURE,
  USER_ADD_REQUEST, USER_ADD_SUCCESS, USER_ADD_FAILURE,
  USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE,
  USER_RESET, UPDATE_FILTERED_USERS, UPDATE_SHIFTDAYS_USERS
} from '../actions/users'

const INITIAL_STATE = {
  users: null, usersNeedRefresh: false, user: null, error: null, loading: false, filteredUsers: [], shiftdaysUsers: []
}

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USERS_REQUEST:
      return {
        ...state,
        users: null, error: null, loading: true
      }
    case USERS_SUCCESS:
      return {
        ...state,
        users: action.response, error: null, loading: false
      }
    case USERS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case USER_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case USER_SUCCESS:
      return {
        ...state,
        user: action.response, error: null, loading: false
      }
    case USER_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case USER_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case USER_ADD_SUCCESS:
      return {
        ...state,
        user: action.response, usersNeedRefresh: true, error: null,
        loading: false
      }
    case USER_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.response, usersNeedRefresh: true, error: null,
        loading: false
      }
    case USER_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case USER_RESET:
      return {
        ...state,
        user: null, error: null, loading: false
      }
    case UPDATE_FILTERED_USERS:
      return {
        ...state,
        filteredUsers: action.payload,
      }
    case UPDATE_SHIFTDAYS_USERS:
      return {
        ...state,
        shiftdaysUsers: action.payload,
      };
    default:
      return state
  }
}

export default usersReducer

