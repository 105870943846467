import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchMachines } from '../actions/machines'

import TPVList from '../components/TPVList'
import ErrorMessage from '../components/ErrorMessage'

class TPVsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchMachines()
  }

  render() {
    const { machines, error, role } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!machines) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>

        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">LISTADO DE </span>
              <span className="text-vw-light">TPVs</span>             
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <TPVList machines={machines} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machines: state.machines.machines,
  error: state.machines.error,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchMachines: bindActionCreators(fetchMachines, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TPVsView)
