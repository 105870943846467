import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'

import { addPromoter } from '../actions/promoters'

import {
  renderInputField, renderSelectField,
  renderTextAreaField
} from '../utils/forms'

class PromoterCreateForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {

    if (!this.state.loaded) {

      this.setState({
        loaded: true
      })
    }
  }

  submit(props) {
    return this.props.addPromoter(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { loaded } = this.state
    const { error, pristine, submitting, handleSubmit } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <h1>
          <span className="text-vw-dark">NUEVO </span>
          <span className="text-vw-light">PROMOTOR</span>
        </h1>

        <form onSubmit={handleSubmit(this.submit)}>
          <div className="row">
            <div className="col col-12 col-lg-4">
              <Box title="Personal" icon="user">
                <Field name="fullname"
                  component={renderInputField}
                  type="text"
                  label="Nombre Completo" />
                <Field name="birthday"
                  component={renderInputField}
                  type="date"
                  label="Cumpleaños" />
                <Field name="phone"
                  component={renderInputField}
                  type="text"
                  label="Teléfono" />
              </Box>
            </div>
            <div className="col col-12 col-lg-4">
              <Box title="Acceso" icon="user">
                <Field name="email"
                  type="text"
                  component={renderInputField}
                  label="E-mail" />
                <Field name="pass"
                  type="password"
                  component={renderInputField}
                  label="Password" />
              </Box>
            </div>
            <div className="col col-12 col-lg-4">
              <Box title="Condiciones" icon="user">
                <Field name="password"
                  component={renderInputField}
                  type="text"
                  label="Código Fichaje" />
                <Field name="workingday"
                  type="number"
                  component={renderInputField}
                  label="Jornada" />
                <Field name="rest"
                  component={renderSelectField}
                  label="Descanso">
                  <option value="">Selecciona un descanso...</option>
                  <option value="0">Ninguno</option>
                  <option value="30">30 min</option>
                  <option value="60">1 hora</option>
                </Field>
              </Box>
            </div>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <button className="btn bg-vw-light text-white mr-1 save" type="submit"
            disabled={pristine || submitting}>
            Guardar
          </button>
        </form>
      </div>
    )
  }
}

PromoterCreateForm = reduxForm({
  form: 'PromoterCreateForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/promoters/${result.response.number}`)
  },
  enableReinitialize: true
})(PromoterCreateForm)

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  addPromoter: bindActionCreators(addPromoter, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PromoterCreateForm)
