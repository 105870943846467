import { CALL_API } from '../middleware/api'

export const MATRIXS_REQUEST = 'MATRIXS_REQUEST'
export const MATRIXS_SUCCESS = 'MATRIXS_SUCCESS'
export const MATRIXS_FAILURE = 'MATRIXS_FAILURE'

export function fetchMatrixs(machine, phone, customer, zone) {
  let params = ''

  if (machine !== undefined) {
    params += `&machine=${machine}`
  }

  if (phone !== undefined) {
    params += `&phone=${phone}`
  }

  if (customer !== undefined) {
    params += `&customer=${customer}`
  }

  if (zone !== undefined) {
    params += `&zone=${zone}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `matrixs?${params}`,
      authenticated: true,
      types: [MATRIXS_REQUEST, MATRIXS_SUCCESS, MATRIXS_FAILURE]
    }
  }
}

export const MATRIX_REQUEST = 'MATRIX_REQUEST'
export const MATRIX_SUCCESS = 'MATRIX_SUCCESS'
export const MATRIX_FAILURE = 'MATRIX_FAILURE'

export function fetchMatrix(Serial) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `matrixs/${Serial}`,
      authenticated: true,
      types: [MATRIX_REQUEST, MATRIX_SUCCESS, MATRIX_FAILURE]
    }
  }
}

export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE'

export function fetchFaultsCSV(Customer) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `matrixs/pdf/${Customer}`,
      authenticated: true,
      types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE]
    }
  }
}