import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, reset } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import ErrorMessage from '../components/ErrorMessage'
import Box from '../components/Box'
import { push } from 'react-router-redux'

import { fetchObjective, updateObjective } from '../actions/objectives'
import { fetchCustomers } from '../actions/customers'

import { renderInputField, renderSelectField } from '../utils/forms'

class ObjectiveEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { role, fetchObjective, fetchCustomers, objectives, match } = this.props

    const searchParams = new URLSearchParams(this.props.location.search);
    const year = searchParams.get('year');

    fetchCustomers()

    if (!objectives.objective) {
      fetchObjective(match.params.customer, year)
    } else {
      this.setState({ loaded: true })
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loaded && nextProps.objectives.objective) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    const searchParams = new URLSearchParams(this.props.location.search);
    const year = searchParams.get('year');

    return this.props.updateObjective(this.props.match.params.customer, year, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  weekFields() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const weeks = ['W1', 'W2', 'W3', 'W4', 'W5', 'W6'];
  
    return months.map((month) => (
      <div key={month} className="mb-5 mr-5 ml-5">
        <h5>{month}</h5>
        <div className="week-fields">
          {weeks.map(week => (
            <div key={`${month}.${week}`} className="row">
              <span className="mr-3">{week}</span>
              <Field
                name={`${month}.${week}`}
                component={renderInputField}
                type="number"
              />
            </div>
          ))}
        </div>
      </div>
    ));
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, customers, role, objectives, pristine, initialValues } = this.props

    if (!loaded || !initialValues) {
      if (objectives.error) {
        return (
          <ErrorMessage message={objectives.error.message} />
        )
      }

      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <h1>
          <span className="text-vw-dark">EDITAR OBJETIVOS </span>
          <span className="text-vw-light">{objectives.objective.Customer.Fullname} {(new Date(objectives.objective.TimeStamp)).getFullYear()}</span>
        </h1>
        <form onSubmit={handleSubmit(this.submit)} className="mb-3 complete-width">
          <div className="row mt-5">
            <div className="col col-12 col-lg-12">
              <Box>
                <div className="month-fields flex">
                  {this.weekFields()}
                </div>
              </Box>
            </div>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <button className="btn bg-vw-light text-white mr-1 save" type="submit"
            disabled={pristine || submitting}>
            Guardar
          </button>
        </form>
      </div>
    )
  }
}

ObjectiveEditForm = reduxForm({
  form: 'ObjectiveEditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/objectives`)
  }
})(ObjectiveEditForm)

const mapStateToProps = state => ({
  customers: state.customers.customers,
  objectives: state.objectives,
  initialValues: state.objectives.objective,
  role: state.auth.role,
})

const mapDispatchToProps = dispatch => ({
  fetchObjective: bindActionCreators(fetchObjective, dispatch),
  updateObjective: bindActionCreators(updateObjective, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch),
  resetForm: () => dispatch(reset('ObjectiveEditForm')),
})

export default connect(mapStateToProps, mapDispatchToProps)(ObjectiveEditForm)
