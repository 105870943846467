import React from 'react'
import { history } from '../store'
import * as dateFormat from 'dateformat'

class ObjectiveList extends React.Component {
  goTo(customer, year, e) {
    history.push(`/objectives/${customer}?year=${year}`)
  }

  calculateTotalKPILay(objective) {

    const filteredArqueos = this.props.arqueos.filter(arqueo => arqueo.Customer._id === objective.Customer._id);

    let kpiLay = 0;
    let totalPack = 0;
    let totalLay = 0;

    {
      filteredArqueos.map((arqueo) => {
        totalLay += arqueo.Items.VWLAY
        totalPack += arqueo.Items.VWPACK
      }
      )
    }

    kpiLay = (totalLay / totalPack) * 100

    return kpiLay
  }

  calculateTotalKPIGo(objective) {

    const filteredArqueos = this.props.arqueos.filter(arqueo => arqueo.Customer._id === objective.Customer._id);

    let kpiGo = 0;
    let totalPack = 0;
    let totalGo = 0;

    {
      filteredArqueos.map((arqueo) => {
        totalGo += arqueo.Items.VWGO
        totalPack += arqueo.Items.VWPACK
      }
      )
    }

    kpiGo = (totalGo / totalPack) * 100

    return kpiGo
  }

  calculateTotalCrossSale(objective) {

    const filteredArqueos = this.props.arqueos.filter(arqueo => arqueo.Customer._id === objective.Customer._id);

    let kpiCross = 0;
    let totalPack = 0;
    let totalLay = 0;
    let totalGo = 0;

    {
      filteredArqueos.map((arqueo) => {
        totalLay += arqueo.Items.VWLAY
        totalGo += arqueo.Items.VWGO
        totalPack += arqueo.Items.VWPACK
      }
      )
    }

    kpiCross = ((totalGo + totalLay) / totalPack) * 100

    return kpiCross
  }

  progressBar(actual, objective) {

    const percentage = Math.min((actual / objective) * 100, 100);

    let color = "bg-warning"

    if (percentage < 50) {
      color = "bg-danger"
    } else if (percentage === 100) {
      color = "bg-success"
    }

    return (
      <div className="progress-container">
        <div className={`progress-bar ${color}`} style={{ width: `${percentage}%` }}></div>
        <div className="progress-percentage">{percentage.toFixed(2)}%</div>
      </div>
    );
  }

  calculateTotalObjective(objective) {

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const weeks = ['W1', 'W2', 'W3', 'W4', 'W5', 'W6'];

    let totalObjectives = 0;

    {
      months.map((month) => {
        weeks.map(week => (
          totalObjectives += objective[month][week]

        ))
      }
      )
    }

    return totalObjectives
  }

  calculateTotalReal(objective) {

    const filteredArqueos = this.props.arqueos.filter(arqueo => arqueo.Customer._id === objective.Customer._id);

    let totalReal = 0;

    {
      filteredArqueos.map((arqueo) => {
        totalReal += this.calculatePriceWithoutTax(arqueo.SalesTotal, arqueo.Tax)
      }
      )
    }

    return totalReal
  }

  calculatePriceWithoutTax(priceWithTax, taxRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    // Calculate price without tax and round to two decimal places
    const priceWithoutTax = (priceWithTax / taxMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTax);
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Año</th>
              <th scope="col">Real</th>
              <th scope="col">Objetivo</th>
              <th scope="col">Progreso</th>
              <th scope="col">KPI Lay</th>
              <th scope="col">KPI Go</th>
              <th scope="col">Venta Cruzada</th>
            </tr>
          </thead>
          <tbody>
            {this.props.objectives.map(objective => {
              const date = (new Date(objective.TimeStamp)).getFullYear()

              return (
                <tr key={objective.number}
                  onClick={e => this.goTo(objective.Customer._id, date, e)}>
                  <td>{objective.Customer.Fullname}</td>
                  <td>{date}</td>
                  <td>{this.calculateTotalReal(objective).toFixed(2)} €</td>
                  <td>{this.calculateTotalObjective(objective).toFixed(2)} €</td>
                  <td>{this.progressBar(this.calculateTotalReal(objective), this.calculateTotalObjective(objective))}</td>
                  <td>{this.calculateTotalKPILay(objective).toFixed(2)}%</td>
                  <td>{this.calculateTotalKPIGo(objective).toFixed(2)}%</td>
                  <td>{this.progressBar(this.calculateTotalCrossSale(objective), 100)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ObjectiveList

