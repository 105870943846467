import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchModules } from '../actions/modules'
import { fetchMachines } from '../actions/machines'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import Box from '../components/Box'
import classnames from 'classnames';

import Module02List from '../components/Module02List'
import Module04List from '../components/Module04List'
import Module06List from '../components/Module06List'
import Module08List from '../components/Module08List'
import Module10List from '../components/Module10List'
import Module12List from '../components/Module12List'
import Kiosc04List from '../components/Kiosc04List'
import Kiosc06List from '../components/Kiosc06List'
import ModuleErrorList from '../components/ModuleErrorList'
import ErrorMessage from '../components/ErrorMessage'

class ModulesView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: '1'
    }

  }

  componentDidMount() {
    this.props.fetchModules()
    this.props.fetchMachines()
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  printDuplicatedSN() {

    const { modules, machines } = this.props;

    if (!modules || !machines) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    // Create an object to store the counts of each SN
    const snCounts = {};

    let assignedMachine = "";

    // Iterate through modules and count the occurrences of each SN
    modules.forEach((module) => {

      machines.forEach((machine) => {

        machine.Modules.forEach((mod) => {

          if (mod.serialNumber === module.Code){
            assignedMachine = machine.Serial
          }
        });
      });

      module.RegisterLockers.forEach((lock) => {
        const { SN } = lock;
        if (snCounts[SN]) {
          snCounts[SN].count += 1;
          snCounts[SN].modules.push(`${module.Code} - ${assignedMachine}`);
        } else {
          snCounts[SN] = {
            count: 1,
            modules: [`${module.Code} - ${assignedMachine}`],
          };
        }
      });
    });

    // Filter the SNs that have duplicates
    const duplicates = Object.entries(snCounts).filter(([SN, data]) => data.count > 1);

    return (
      <div className="table-responsive">
        {duplicates.length > 0 ? (
          <table className="table table-hover table-clickable">
            <thead className="thead-dark">
              <tr>
                <th>SN</th>
                <th>Module Codes</th>
              </tr>
            </thead>
            <tbody>
              {duplicates.map(([SN, data]) => (
                <tr key={SN}>
                  <td>{SN}</td>
                  <td>{data.modules.join(', ')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No duplicate SNs found.</p>
        )}
      </div>
    );
  }

  render() {
    const { role, type, modules, error } = this.props

    return (
      <div>

        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">PARQUE DE </span>
              <span className="text-vw-light">MÓDULOS</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-6 col-md-6 text-right">
              <Link to="/modules/add">
                <button type="button" className="btn bg-vw-light text-white">
                  <i className="fas fa-plus mr-1"></i>Nuevo
                </button>
              </Link>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <Box title="Tipos de módulo" icon="arrows-alt-h">
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  VLK04
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  VLK06
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  VLM02
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  VLM04
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}
                >
                  VLM06
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '6' })}
                  onClick={() => { this.toggle('6'); }}
                >
                  VLM08
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '7' })}
                  onClick={() => { this.toggle('7'); }}
                >
                  VLM10
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '8' })}
                  onClick={() => { this.toggle('8'); }}
                >
                  VLM12
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '9' })}
                  onClick={() => { this.toggle('9'); }}
                >
                  ERROR
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '10' })}
                  onClick={() => { this.toggle('10'); }}
                >
                  DUPLICATED SN
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Kiosc04List modules={modules} />
              </TabPane>
              <TabPane tabId="2">
                <Kiosc06List modules={modules} />
              </TabPane>
              <TabPane tabId="3">
                <Module02List modules={modules} />
              </TabPane>
              <TabPane tabId="4">
                <Module04List modules={modules} />
              </TabPane>
              <TabPane tabId="5">
                <Module06List modules={modules} />
              </TabPane>
              <TabPane tabId="6">
                <Module08List modules={modules} />
              </TabPane>
              <TabPane tabId="7">
                <Module10List modules={modules} />
              </TabPane>
              <TabPane tabId="8">
                <Module12List modules={modules} />
              </TabPane>
              <TabPane tabId="9">
                <ModuleErrorList modules={modules} />
              </TabPane>
              <TabPane tabId="10">
                {this.printDuplicatedSN()}
              </TabPane>
            </TabContent>
          </div>
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  modules: state.modules.modules,
  machines: state.machines.machines,
  error: state.modules.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchModules: bindActionCreators(fetchModules, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ModulesView)
