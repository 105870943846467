import {
  MACHINES_REQUEST, MACHINES_SUCCESS, MACHINES_FAILURE,
  MACHINE_REQUEST, MACHINE_SUCCESS, MACHINE_FAILURE,
  MACHINE_ADD_REQUEST, MACHINE_ADD_SUCCESS, MACHINE_ADD_FAILURE,
  MACHINE_UPDATE_REQUEST, MACHINE_UPDATE_SUCCESS, MACHINE_UPDATE_FAILURE,
  MACHINE_DELETE_REQUEST, MACHINE_DELETE_SUCCESS, MACHINE_DELETE_FAILURE,
  MACHINES_RESET
} from '../actions/machines'

const INITIAL_STATE = {
  machines: null, machine: null, error: null, loading: false
}

const MachinesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case MACHINES_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MACHINES_SUCCESS:
        return {
          ...state,
          machines: action.response, error: null, loading: false
        }
      case MACHINES_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MACHINE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MACHINE_SUCCESS:
        return {
          ...state,
          machine: action.response, error: null, loading: false
        }
      case MACHINE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MACHINE_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MACHINE_ADD_SUCCESS:
        return {
          ...state,
          machine: action.response, error: null, loading: false
        }
      case MACHINE_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MACHINE_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MACHINE_UPDATE_SUCCESS:
        return {
          ...state,
          machine: action.response, error: null, loading: false
        }
      case MACHINE_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MACHINE_DELETE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case MACHINE_DELETE_SUCCESS:
        return {
          ...state,
          machine: null, error: null, loading: false
        }
      case MACHINE_DELETE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case MACHINES_RESET:
        return INITIAL_STATE;
      default:
        return state
    }
  }

export default MachinesReducer
